@keyframes tooltip-enter {
  0% {
    box-shadow: 0;
    opacity: 0;
  }
  100% {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    opacity: 1;
  }
}

.tooltip {
  &-open {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    min-width: 85px;
    width: max-content;
    margin-left: 40px;
    padding: 10px;
    border-radius: 5px;
    z-index: 1;
    color: white;
    background-color: $second-background;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    animation: fade-in-tooltip 0.1s cubic-bezier(0.42, 0, 1, 1);
    pointer-events: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    animation: tooltip-enter 0.15s ease-in-out;

    &::before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      right: 100%;
      border-top: 12px solid transparent;
      border-right: 6px solid $second-background;
      border-bottom: 12px solid transparent;
    }
  }

  &-closed {
    display: none;
  }
}
.opposite__tooltip {
  &-open {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 100px;
    padding: 5px;
    border-radius: 5px;
    margin-left: -150px;
    color: white;
    background-color: $second-background;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    pointer-events: none;
    font-size: 12px;
    text-transform: none;
    line-height: 130%;
    font-weight: 500;
    animation: tooltip-enter 0.15s ease-in-out;

    &::before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      left: 100%;
      border-top: 12px solid transparent;
      border-left: 6px solid $second-background;
      border-bottom: 12px solid transparent;
    }
  }

  &-closed {
    display: none;
  }
}
