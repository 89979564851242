.sound-wave-form {
    display: flex;
    gap: 48px;

    &__fieldset {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 100%;
        gap: 16px;

        &:not(:last-child)::after {
            content: '';
            position: absolute;
            top: 0;
            right: -24px;
            height: 100%;
            width: 1px;
            background-color: #E3E7ED;
        }
    }

    &__legend {
        margin-bottom: 16px;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: $gray-300;
    }

    &__fields-group {
        display: flex;
        gap: 8px;
    }
}
