.event-header-search {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    width: 32px;
    height: 32px;

    &__label {
        background-color: #F8FAFC;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        position: relative;
        right: 0;
        padding: 8px;
        border-radius: 8px;
        cursor: pointer;
    }

    &__icon {
        width: 16px;
        min-width: 16px;
        height: 16px;
    }

    &__input {
        appearance: none;
        border: none;
        padding-left: 0;
        width: 0;
        height: 100%;
        background-color: transparent;
        transition: padding 300ms ease-in-out, width 300ms ease-in-out;
    }

    &--expanded {
        & .event-header-search__input {
            padding-left: 8px;
            width: 240px;
        }
    }
}