.header-account {
    display: flex;
    position: relative;
    color: $black-new-design;

    &__avatar-wrapper {
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        padding: 4px;
        border: none;
        border-radius: 50%;
        background-color: transparent;
        cursor: pointer;
        transition: background-color 150ms ease-in-out;

        &:hover {
            background-color: #F8FAFC;
        }

        &:active {
            background-color: $gray-100;
        }
    }
    
    &__popup {
        visibility: hidden;
        opacity: 0;
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 8px 0px rgba(71, 107, 132, 0.15);
        transition: opacity 150ms ease-in-out, visibility 0s ease-in-out 150ms;
        position: absolute;
        top: 100%;
        right: 0;
        width: 264px;
    }

    &__popup-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 4px;
        padding: 16px 32px;
        border-bottom: 1px solid #E3E7ED;
    }

    &__popup-header-name {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
    }

    &__popup-header-title {
        font-size: 10px;
        line-height: 16px;
        color: $gray-300;
    }

    &__options-group {
        display: flex;
        flex-direction: column;
        padding: 8px 0;
        gap: 8px;

        &--bordered {
            border-bottom: 1px solid #E3E7ED;
        }
    }

    &__option {
        appearance: none;
        display: inline-flex;
        align-items: center;
        text-align: left;
        border: none;
        padding: 8px 32px;
        background-color: transparent;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: $black-new-design;
        transition: background-color 150ms ease-in-out;
        cursor: pointer;

        &:hover {
            background-color: #F8FAFC;
        }

        &:active {
            background-color: $gray-100;
        }
    }

    &--opened {
        .header-account__avatar-wrapper {
            background-color: $gray-100;

            &:hover {
                background-color: $gray-100;
            }
        }

        .header-account__popup {
            visibility: visible;
            opacity: 1;
            transition: opacity 150ms ease-in-out;
        }
    }
}
