.subj-row {
  position: relative;
  width: 100%;
  max-width: 100%;

  &-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    width: max-content;
    max-width: calc(100% - 20px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }

  &__toltip {
    display: none;

    &.open {
      font-family: 'Open Sans';
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: absolute;
      min-height: 25px;
      height: max-content;
      width: max-content;
      max-width: 400px;
      white-space: normal;
      left: 0%;
      top: 50%;
      transform: translate(0%, calc(-100% - 20px));
      border-radius: 4px;
      padding: 6px 10px 6px 10px;
      color: rgba(255, 255, 255, 0.9);
      background: $second-background;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      z-index: 15;
    }
  }
}
