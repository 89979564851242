@import './components/dateAndTeamSelector/date-and-team-selector';
@import './components/Overview/overview';
@import './components/Activity/activity';
@import './components/Analytics/team-analytics';
@import './components/teamQGraph/team-q-graph';
@import './components/skeletons/team-q-skeleton';

.team-q {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
    animation: $fadein;
  }

  &__header-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    color: $black-new-design;
  }
}
