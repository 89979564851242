@import './components/team/teamsTable/teams-table';
@import './components/createTeam/create-team';
@import './elements/roleSelect/role-select';
@import './elements/avatarUploadClip/avatar-upload-clip';

.teams {
  display: flex;
  flex-direction: column;

  &__team-wrapper {
    padding: 30px 1px 27px;
    border-bottom: 1px solid #f0f2f9;

    &__info {
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      &-name {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        color: black;
      }

      &__overview {
        margin-top: 22px;

        &-label {
          color: #777679;
          font-size: 14px;
          line-height: 20px;
        }

        &-value {
          color: #464646;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          margin-left: 3px;
        }
      }
    }

    &__team-block {
      height: 138px;
      padding: 15px;
      background: #fff;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-shadow: 0px 2px 8px rgba(36, 45, 81, 0.2);
      border-radius: 12px;
      cursor: default;

      &__avatar {
        position: relative;
        border-radius: 10px;
        height: 210px;
        width: 400px;
        overflow: hidden;

        &-upload {
          display: none;
        }

        &:hover {
          background-color: $text-med-emp;

          .teams__team-wrapper__team-block__avatar-upload {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            color: $text-high-emp;
            background-color: $white;
            border-radius: 8px;
            padding: 4px 8px 4px 8px;
            cursor: pointer;
          }

          .teams__team-wrapper__team-block__avatar-img {
            display: none;
          }
        }

        &-img {
          border-radius: 10px;
          height: 210px;
          width: 400px;
        }
      }

      &__second-column {
        margin-left: 13px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        &__team-name {
          font-size: 24px;
          font-weight: 500;
          line-height: 31px;
          letter-spacing: 0em;
          text-align: left;
          color: black;
        }

        &__team-info {
          height: 50px;

          &__label {
            color: #777679;
            font-size: 14px;
            line-height: 20px;

            &:first-child {
              margin-bottom: 10px;
            }
          }

          &__value {
            color: #464646;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            margin-left: 3px;
          }
        }
      }

      &__third-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 15px 5px;
        min-width: 100px;

        &__icons {
          display: flex;
          justify-content: space-evenly;
          width: 80px;
          margin: -11px 0px 0px 30px;

          &-edit {
            cursor: pointer;
          }

          &-del {
            cursor: pointer;
          }
        }

        &__members {
          display: flex;
          justify-content: space-evenly;
          color: #394577;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;

          &__show-hide-users {
            width: 10px;
            transition: 0.2s linear;
            cursor: pointer;
            &.open {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    &__users-block {
      animation: $fadein;
    }
  }

  &__welcome {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 8px;
    width: 50%;

    &-img {
      margin-right: 15px;
    }

    &-text {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  &-add__members {
    width: 700px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 4px;

    &-input {
      width: 364px;
    }

    &-select {
      border-radius: 15px;
      margin: 0px 5px;
    }

    &-autocomplete {
      width: 367px;
    }

    &-button {
      width: 160px;
      padding: 13.5px 0px;
      &.button-done {
        padding: 1.5rem 0;
        width: 75%;
        margin: auto;
      }
    }

    &-members__block {
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 190px;
      max-height: 249px;
      overflow: auto;
      overflow-x: hidden;
      margin-right: -15px;
      padding-right: 5px;
      margin-bottom: 20px;

      &-member {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-width: 710px;
        padding: 5px 10px 5px 57px;

        &.scroll__visible {
          padding: 5px 4px 5px 55px;
        }

        &.deleted {
          position: relative;
          opacity: 0.7;
          border-radius: 15px;

          &-cross__out {
            border-top: 1px solid grey;
            width: 81%;
            position: absolute;
            top: 51%;
            left: 7%;
          }
        }

        &-select {
          display: flex;
          align-items: center;
          z-index: 10;

          &.active {
            z-index: 11;
          }
        }

        &-delete__icon {
          margin-left: 17px;

          &-restore {
            color: #394577;
            cursor: pointer;
          }
        }
      }

      &-user__icon {
        position: absolute;
        left: 18px;
      }

      &::-webkit-scrollbar-track-piece:end {
        margin-bottom: 6px;
      }
      &::-moz-scrollbar-track-piece:end {
        margin-bottom: 6px;
      }
      &::-ms-scrollbar-track-piece:end {
        margin-bottom: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: $text-low-emp;
        border-radius: 4px;
        box-shadow: inset 0px 7px 0px 0px #fff;
      }
      &::-moz-scrollbar-thumb {
        background: $text-low-emp;
        border-radius: 4px;
        box-shadow: inset 0px 7px 0px 0px #fff;
      }
      &::-ms-scrollbar-thumb {
        background: $text-low-emp;
        border-radius: 4px;
        box-shadow: inset 0px 7px 0px 0px #fff;
      }
    }

    &-body__title {
      display: flex;
      flex-direction: row;
      font-size: 10px;
      line-height: 12px;
      margin-bottom: 8px;
      color: #777679;

      &-error {
        display: flex;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #f26065;
        margin: 5px 0px 15px;
      }

      &-warn {
        display: flex;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #eba53a;
        margin: 5px 0px 15px;
      }
    }
  }
  .delete-modal-body {
    animation: animFadeIn 0.4s linear;
    font-size: 14px;
    line-height: 18.2px;

    &__normal {
      font-size: 14px;
      line-height: 18.2px;
    }

    &__bold {
      font-size: 14px;
      font-weight: 500;
      line-height: 18.2px;
    }

    &__btn {
      margin-top: 2rem;
    }

    @keyframes animFadeIn {
      0% {
        opacity: 0.1;
      }
      100% {
        opacity: 1;
      }
    }

    &.deleted {
      animation: animDeleted 0.3s linear;
    }

    @keyframes animDeleted {
      0% {
        opacity: 0.1;
        transform: scale(0.97);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .team__control__icon {
    &-edit {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: all 0.4s;
      width: 28px;
      height: 28px;
      cursor: pointer;

      &:hover {
        background-color: #f9f9fc;
      }
    }

    &-delete {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: all 0.4s;
      width: 28px;
      height: 28px;
      cursor: pointer;

      &:hover {
        background-color: #ffdfe0;
      }
    }
  }

  .ant-table-body {
    height: 150px;
  }
}
