.turns-overview-chart {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 208px;
  max-height: 208px;
  animation: $fadein;

  &__y-axis {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 64px;
    height: 208px;
    padding: 8px;
    gap: 16px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(71, 107, 132, 0.15);
    z-index: 1;
    @include userSelectDisable;

    &-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      color: #070b12;
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }

    &__ticks {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      padding: 0px 8px;
      gap: 16px;

      &-tick {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #070b12;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }

  &__content {
    width: calc(100% - 64px);
    min-height: 208px;
    max-height: 208px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &__chart {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      width: 100%;
      min-height: 176px;
      max-height: 176px;
      gap: 4px;
      cursor: default;
      position: relative;
      background: url(./icons/grid.svg);
      background-size: auto;
      background-repeat: repeat;
      z-index: 1;

      &__cell {
        display: flex;
        height: 100%;
        // width: 14.28%;
        gap: 6px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 0 8px;
        flex: 1 0 0;
        overflow: hidden;
        max-width: calc(14.285% - 3px);

        &__stripe {
          position: relative;
          width: calc(24% - 6px);
          height: 1px;
          background: #f1f4f8;
          border-radius: 2px 2px 0px 0px;
          transition: all 0.8s ease;
          animation: growAnimation 0.7s ease 0s forwards;

          @keyframes growAnimation {
            0% {
              transform: translateY(100%);
            }
            95% {
              transform: translateY(0);
            }
            100% {
              transform: none;
            }
          }

          &--prospectSide {
            background: $color-prospect;
            &-darker {
              background: #d21961;
            }
          }

          &--userSide {
            background: $color-user;
            &-darker {
              background: #271ba4;
            }
          }

          &--unassigned {
            background: $color-unassigned;
            &-darker {
              background: darken($color-unassigned, 3);
            }
          }

          &__avatar-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -22px);
            animation: $fadein;
          }
        }
      }

      &__select-participant {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #070b12;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        cursor: default;
        animation: $fadein;
      }
    }

    &__x-axis {
      display: flex;
      align-items: center;
      width: 100%;
      height: 32px;
      max-height: 32px;
      gap: 4px;
      border-top: 1px solid #e3e7ed;
      background: #ffffff;
      @include userSelectDisable;

      &__tick {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        padding: 8px;
        gap: 4px;
        flex: 1 0 0;
        background: #ffffff;
        white-space: nowrap;
        max-width: calc(14.285% - 3px);

        &-label {
          color: #95a1b6;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 100%;
        }
      }
    }
  }
}
