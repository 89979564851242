.maintenance {
    display: flex;
    height: 100vh;
    padding: 32px;
    color: $black-new-design;

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        gap: 30px;
        width: 552px;
    }

    &__logo {
        width: 176px;
    }

    &__image {
        width: 428px;
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    &__title {
        font-size: 48px;
        font-weight: 600;
        line-height: 72px;
    }

    &__description {
        font-size: 16px;
        line-height: 28px;
        color: $gray-300;
    }
}
