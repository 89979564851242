@import './components/ogrProfile/org-profile';
@import './components/generalInfo/general-info';
@import './components/subscriptionInfo/subscription-info';
@import './components/overview/overview-info';

.organization {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
  min-height: 100%;

  &__first-column {
    display: flex;
    flex-direction: column;
    width: 31.5%;
    min-width: 280px;
    height: 466px;
    background-color: $white;
    border-radius: 12px;
    animation: fadeIn 0.8s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;
  }

  &__second-column {
    display: flex;
    flex-direction: column;
    width: 31.5%;
    min-width: 280px;
    height: 466px;
    margin: 0px 16px;
    background-color: transparent;
    border-radius: 12px;
    animation: fadeIn 0.8s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;
  }

  &__third-column {
    display: flex;
    flex-direction: column;
    width: 31.5%;
    min-width: 280px;
    height: 466px;
    background-color: $white;
    border-radius: 12px;
    animation: fadeIn 0.8s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;
  }
}
