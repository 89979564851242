@import './components/HeaderAccount/header-account';
@import './components/Breadcrumbs/breadcrumbs';
@import './components/RelationshipsSearch/relationships-search';
@import './components/NavigationTabs/navigation-tabs';

.global-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px 12px 32px;
  border-bottom: 1px solid #E3E7ED;
  box-shadow: 0px 4px 12px 0px rgba(218, 220, 228, 0.24);
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 103;
  color: $black-new-design;

  &__left {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-grow: 1;
    max-width: calc(100% - 48px);
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__notifications {
    position: relative;
  }

  &__notifications-button {
    width: 36px;
    height: 36px;
    appearance: none;
    background-color: transparent;
    padding: 0;
    border: none;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 150ms ease-in-out;

    &:hover {
      background-color: #F8FAFC;
    }

    &:active {
      background-color: $gray-100;
    }
  }
}
