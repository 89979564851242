@import './components/ManageParticipantsErrorModal/manage-participants-error-modal';
@import './components/ParticipantItem/participant-item';

.manage-participants {
    display: flex;
    flex-direction: column;
    border: 1px solid #E3E7ED;
    border-radius: 12px;
    overflow: hidden;
    background-color: #F8FAFC;

    & > *:not(:last-child) {
        border-bottom: 1px solid #E3E7ED;
    }

    &__sides-wrapper {
        display: flex;

        & > * {
            max-width: 50%;
            width: 50%;
        }

        & > *:not(:last-child) {
            border-right: 1px solid #E3E7ED;
        }

        &--full-width > * {
            max-width: 100%;
            width: 100%;
        }
    }

    &__side-members {
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;

        & > *:not(:last-child) {
            border-bottom: 1px solid #E3E7ED;
        }

        &--all-bordered > * {
            border-bottom: 1px solid #E3E7ED;
        }
    }

    &__account-name {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        padding: 8px 16px;
        background-color: #F8FAFC;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__unassigned-wrapper {
        display: flex;
        flex-direction: column;
        margin: 8px;
        gap: 8px;
    }
}