.teamq-date-and-team-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-left: auto;

  &__dropdown-option {
    &:disabled {
      color: $gray-200;
    }
  }

  &__button {
    &--opened svg {
      transform: rotate(180deg);
    }
  }

  &__button-icon {
    path {
      fill: $black-new-design;
    }
  }
}
