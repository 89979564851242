.assign-role-modal {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  select {
    background-color: #F0F2F9;
    border: 0;
    border-radius: 1.5rem;
    padding: 1.6rem 2rem;
    font-size: 1.4rem;
    width: 15rem;
    text-transform: capitalize;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    option {
      text-transform: capitalize;
      border: 0;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    margin: 2rem 0;


    img {
      margin-right: 1rem;
    }
  }

  &__btns {
    width: 100%;

    &-btn {
      margin: 1rem 0;
    }
  }
}