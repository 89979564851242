.existing-contact-disclaimer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: 1px solid #E3E7ED;
    border-radius: 4px;
    background-color: #F5F7FF;
    padding: 8px;
    font-size: 12px;
    line-height: 20px;
    color: $black-new-design;

    &__accounts-amount {
        color: #537BFF;
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
        padding: 2px 4px;
    }
}
