.meeting-summary {
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-size: 12px;
    line-height: 20px;

    &__item {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__title {
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
    }

    &__list {
        margin-bottom: 0;
        padding-left: 18px;
    }
}
