.deals-empty-list {
  background-color: #f9f9fc;
  &__row {
    margin: 2px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
    width: 100%;
    border-radius: 8px;
    background-color: #fff;
    &-checkbox {
      margin: 0px 1.4%;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      border: 1px solid #d9d9d9;
      background-color: #f5f5f5;
    }
  }
}
