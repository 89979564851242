.invite-assistant-button {
    svg path {
        transition: fill 150ms ease-in-out;
    }

    &:disabled {
        svg path {
            fill: $gray-200;
        }
    }
}
