.ttpp-row {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #e3e7ed;

  &__header-row {
    position: sticky;
    top: 0;
    z-index: 9;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;
    background: #f8fafc;
    border-bottom: 1px solid #e3e7ed;

    &__cell {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px;
      gap: 4px;

      &:nth-child(1) {
        width: 30%;
        padding: 8px 8px 8px 16px;
      }
      &:nth-child(2) {
        width: 70%;
      }

      &-tooltip {
        cursor: default;
        height: 28px;
      }

      &-label {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        color: #070b12;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        cursor: default;
      }
    }
  }

  &__row-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e3e7ed;
    background: #ffffff;
    border-radius: 2px;
    transition: all 0.15s ease-in-out;
    position: relative;

    &:last-child {
      border-bottom: none;
    }
  }

  &__main-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;

    &__cell {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px;
      gap: 4px;
      color: #070b12;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      &-label {
        display: block;
        color: #070b12;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        cursor: default;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      &__bar-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        width: calc(100% - 39px);
        max-width: calc(100% - 39px);
        height: 8px;
        background: #e5ebff;
        overflow: hidden;
        gap: 1px;
        border-radius: 2px;

        &--prospectSide {
          border-color: #e5ebff;
        }

        &--userSide {
          border-color: #fdf1f6;
        }

        &--unassigned {
          border-color: #f1f4f8;
        }

        &__stripe {
          height: 8px;
          background: #5243fa;
          width: 0;

          &--prospectSide {
            background: $color-prospect;
          }

          &--userSide {
            background: $color-user;
          }

          &--unassigned {
            background: $color-unassigned;
          }
        }
      }

      &:nth-child(1) {
        width: 30%;
        padding: 8px 8px 8px 16px;
      }

      &:nth-child(2) {
        width: 70%;
        justify-content: space-between;

        & > span {
          color: #646f87;
          text-align: right;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          padding: 0 7px 0 3px;
        }
      }
    }
  }
}
