.meeting-duration {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__description {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    &-text {
      color: #646f87;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }

  &__chart {
    display: flex;
    flex-direction: row;
    height: 104px;
    width: 100%;
    margin-bottom: 30px;

    &__first-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 35px;

      &__y-axis {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 20px 0;

        &-label {
          color: #646f87;
          text-align: right;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }

      &--team-q {
        width: fit-content;
        white-space: nowrap;
      }
    }

    &__second-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: calc(100% - 35px);
      height: 100%;

      &__chart-body {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: max-content;
        min-height: 88px;
        width: 100%;
        padding: 17px 24px 1px 10px;

        &__avg-section {
          position: absolute;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          width: 100%;
          border: 1px dashed #e4e9f2;
          background: transparent;
          opacity: 0;

          &-line {
            position: absolute;
            top: 50%;
            left: 0%;
            height: 100%;
            width: 1px;
            background: #e4e9f2;
          }
        }

        &__bar {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          height: 24px;
          width: 100%;
          animation: barFadeIn 0.4s ease-in-out;

          @keyframes barFadeIn {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }

          &-me {
            &-stripe {
              height: 24px;
              width: 0%;
              background: #4eb4ff;
              border-radius: 4px;
              transition: width 2.5s ease-out, opacity 1s ease-out;
            }

            &-dashed-line {
              height: 100px;
              width: 3px;
              transform: translateY(15%);
              opacity: 0;
              transition: opacity 3s ease-out;
            }

            &-label {
              position: absolute;
              left: 0%;
              color: #4eb4ff;
              text-align: right;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              margin-left: 8px;
              transition: opacity 1s ease-out;
            }
          }

          &-team {
            &-stripe {
              height: 24px;
              width: 0%;
              background: #365798;
              border-radius: 4px;
              transition: width 2.5s ease-out, opacity 1s ease-out;

              &--team-q {
                background: #4eb4ff;
              }
            }

            &-dashed-line {
              height: 100px;
              width: 3px;
              transform: translateY(-35%);
              opacity: 0;
              transition: opacity 3s ease-out;
            }

            &-label {
              position: absolute;
              left: 0%;
              color: #44536a;
              text-align: right;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              margin-left: 8px;
              transition: opacity 1s ease-out;

              &--team-q {
                color: #4EB4FF;
              }
            }
          }

          &-n-a {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate(-50%, -16px);
            width: 100%;
            height: 20px;
            color: #4d4558;
            background-color: #ffffff;
            box-shadow: 0px 4px 10px rgba(57, 69, 119, 0.25);
            border-radius: 4px;
            font-weight: 500;
            font-size: 10px;
            line-height: 10px;
          }
        }
      }

      &__x-axis {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 18px 24px 1px 10px;

        &-label {
          color: #646f87;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          // transform: translateX(-50%);
          cursor: default;
          @include userSelectDisable;

          &:not(:first-child) {
            // transform: translateX(50%);
            cursor: default;
          }
        }
      }
    }

    &--team-q {
      margin: auto 0;
    }
  }

  &__legend {
    width: 100%;
    height: 12px;
    margin-top: 27px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    justify-self: flex-end;
    font-size: 11px;
    line-height: 12px;
    gap: 18px;
    color: #646f87;

    &-me {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 12px;

      &::before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 4px;
        background-color: #4eb4ff;
      }
    }

    &-team {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 12px;

      &::before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 4px;
        background-color: #365798;
      }

      &--team-q::before {
        background-color: #4eb4ff;
      }
    }
  }
}
