$toggle-bg-active: #e5ebff;
$toggle-border-active: #ffffff;

$toggle-bg-inactive: #95a1b6;
$toggle-border-inactive: #ffffff;

.topics-toggle {
  position: relative;
  display: block;
  background-color: $toggle-bg-inactive;
  color: #646c83;
  border-radius: 36.5px;
  min-width: 28px;
  width: 28px;
  height: 12px;
  cursor: pointer;
  border: 1px solid $toggle-border-inactive;
  transition: all 0.3s, color 0s;

  &-active {
    background-color: $toggle-bg-active;
    border: 1px solid $toggle-border-active;
  }

  &__toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-1px, -50%);
    background-color: #646f87;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    transition: all 0.3s linear;
  }

  &__control {
    width: 0px;
    border: 0;

    &:checked + .topics-toggle__toggle {
      transform: translate(75%, -50%);
      background-color: #537bff;
    }
  }
}
