@import './components/EmailsList/emails-list';
@import './components/ThreadOverview/thread-overview';
@import './components/EmailAnalysis/email-analysis';

.thread-details {
    display: flex;
    flex-direction: column;
    color: $black-new-design;
    gap: 24px;

    &__selling-zone-wrapper {
        height: 288px;
        padding: 24px;
        border: 1px solid #E3E7ED;
        border-radius: 16px;
        box-shadow: 0px 4px 12px 0px rgba(218, 220, 228, 0.34);
        background-color: #FFFFFF;
        position: relative;
    }

    &__selling-zone-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: calc(100% - 48px);
        height: 166px;
        left: 24px;
        top: 53px;
        background-color: #E3E7ED;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        z-index: 1;
    }

    &__selling-zone-spinner {
        width: 56px;
        height: 56px;
        animation: spinnerAnimation 1s linear infinite;
    
        @keyframes spinnerAnimation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
}
