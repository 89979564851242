.relationships-search {
  position: relative;
  width: 368px;
  width: 400px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  &-input {
    & > input.input__control {
      font-family: inherit;
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;

      &::placeholder {
        color: #646F87;
      }
    }

    & > input.input__control:focus {
      outline-color: #537BFF;
    }

    & > input {
      padding: 8px 36px;
      border-radius: 8px;
      background-color: transparent;
      outline: 1px solid #e3e7ed;
      background-color: #ffffff;

      &:not(.error).no-icon.input__control {
        &:focus {
          outline: 1px solid #537bff;
        }
      }
    }

    & > .input__icon {
      left: 12px;
    }
  }

  &-clear-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    cursor: pointer;
    z-index: 9;
    transition: opacity 0.2s;
    animation: $fadein;

    & path {
      transition: fill 150ms ease-in-out;
    }

    &:hover {
      & path {
        fill: #070B12;
      }
    }
  }

  &-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    animation: spinAnimation 1s linear infinite;

    @keyframes spinAnimation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &__popup {
    position: absolute;
    top: 49px;
    display: flex;
    // width: 368px;
    width: 400px;
    min-height: 224px;
    max-height: 292px;
    padding: 0px;
    gap: 8px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 4px 24px 0px rgba(110, 134, 169, 0.22);
    transition: opacity 0.2s ease-in-out, visibility 0s ease-in-out 0s;
    animation: fadeInPopup 0.3s linear;

    &.closed {
      transition: opacity 0.2s ease-in-out, visibility 0s ease-in-out 0.2s;
      opacity: 0;
      visibility: hidden;
    }

    @keyframes fadeInPopup {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      padding: 8px 0px;
      overflow-x: hidden;
      overflow-y: auto;
      gap: 4px;
      animation: fadeIn;

      &__row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        width: 100%;
        height: 40px;
        padding: 8px 16px;
        background: #ffffff;
        transition: all 0.15s ease-in-out;
        border-radius: 4px;

        &:hover {
          background: #F8FAFC;
          cursor: pointer;
        }

        &:active {
          background: #F1F4F8;
        }

        &
          > div:not(.relationships-search__popup__body__row__avatar) {
          max-width: calc(100% - 28px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &__avatar {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          margin-right: 4px;

          &-img {
            min-width: 24px;
            min-height: 24px;
            max-width: 24px;
            border-radius: 8px;
            border: 1px solid #fff;
            background: #f1f4f8;
            object-fit: contain;

            &.default-icon {
              padding: 6px;
              border: none;
            }
          }
        }

        &-company-name {
          color: #070b12;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;

          &--highlighted {
            color: #070b12;
            font-size: 12px;
            font-style: normal;
            line-height: 16px;
            background-color: rgb(83, 123, 255, 0.2);
          }
        }

        &__email {
          color: #646f87;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;

          &::before {
            content: '|';
            color: #646f87;
            width: max-content;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-right: 4px;
          }

          &__text {
            &-letter {
              color: #646f87;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;

              &--highlighted {
                color: #646f87;
                font-size: 12px;
                font-style: normal;
                line-height: 16px;
                background-color: rgb(83, 123, 255, 0.2);
              }
            }
          }
        }
      }

      &-title {
        color: #070b12;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }

      &-text {
        color: #646f87;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
      }

      &::-webkit-scrollbar {
        &-thumb {
          background: rgba(149, 161, 182, 0.12);
          border-radius: 4px;
          box-shadow: inset 0px 10px 0px 5px transparent;

          &:hover {
            background: rgba(149, 161, 182, 0.22);
          }
        }

        &-track-piece:end {
          margin-bottom: 8px;
        }

        &-track-piece:start {
          margin-top: 8px;
        }
      }

      &::-moz-scrollbar {
        &-thumb {
          background: rgba(149, 161, 182, 0.12);
          border-radius: 4px;
          box-shadow: inset 0px 10px 0px 5px transparent;

          &:hover {
            background: rgba(149, 161, 182, 0.22);
          }
        }

        &-track-piece:end {
          margin-bottom: 8px;
        }

        &-track-piece:start {
          margin-top: 12px;
        }
      }

      &::-ms-scrollbar {
        &-thumb {
          background: rgba(149, 161, 182, 0.12);
          border-radius: 4px;
          box-shadow: inset 0px 10px 0px 5px transparent;

          &:hover {
            background: rgba(149, 161, 182, 0.22);
          }
        }

        &-track-piece:end {
          margin-bottom: 8px;
        }

        &-track-piece:start {
          margin-top: 12px;
        }
      }
    }

    &__no-results-body {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin: auto 0;
      position: relative;
      animation: fadeIn;

      &-title {
        color: #070b12;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        animation: fadeIn;
      }

      &-text {
        color: #646f87;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        animation: fadeIn;
      }
    }
  }
}
