.custom-scrollbar {
    & .simplebar-scrollbar {
        min-height: 57px;

        &::before {
            background-color: $gray-200;
            opacity: 0.12;
            border-radius: 3.5px;
            width: 7px;
            margin: 16px 0;
            height: calc(100% - 32px);
        }
    }
}
