.player-controls {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #fff;
    padding: 12px 16px;
    height: 40px;
    gap: 16px;
    box-shadow: 0px 4px 16px 0px rgba(85, 106, 142, 0.12);
    border-top: 1px solid $gray-100;
    border-bottom: 1px solid $gray-100;

    &__volume-controls-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;

        &:hover .player-controls__volume-slider-wrapper {
            width: 64px;
            opacity: 1;
        }
    }

    &__volume-slider-wrapper {
        display: flex;
        align-items: center;
        width: 0;
        height: 10px;
        opacity: 0;
        overflow: hidden;
        transition: width 300ms ease-in-out, opacity 300ms ease-in-out;
    }

    &__timeline-slider-wrapper {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }

    &__text {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }

    &__time {
        width: 30px;
    }

    &--full-screen {
        color: #FFFFFF;
        background-color: rgba(149, 161, 182, 0.30);
        border-color: transparent;
        box-shadow: 0px 4px 16px 0px rgba(85, 106, 142, 0.12);

        & svg path {
            fill: #FFFFFF;
        }
    }
}
