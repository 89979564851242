.breadcrumbs {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;

    &__item {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: $black-new-design;
        transition: color 150ms ease-in-out;
        max-width: 25%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
            color: #537BFF;
        }

        &:active {
            color: #2244B3;
        }

        &--disabled {
            color: $gray-200;
            pointer-events: none;
            cursor: default;

            &:hover {
                color: $gray-200;
            }
        }
    }

    &__icon {
        width: 12px;
        min-width: 12px;
        height: 12px;
    }
}
