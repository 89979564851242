.your-sentiment {
  display: flex;
  flex-direction: column;
  height: 110px;
  color: #29335c;
  border-bottom: 1px solid #e7eaff;

  &__header {
    padding-left: 3px;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
  }

  &__body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 100px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 16.3px;

    &-negative {
      font-family: 'Open Sans';
      font-size: 11px;
      font-weight: 400;
      line-height: 15px;
      color: #989899;
    }

    &-positive {
      font-family: 'Open Sans';
      font-size: 11px;
      font-weight: 400;
      line-height: 15px;
      color: #989899;
    }

    &__bar-section {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 60%;
      height: 60px;

      &-bar {
        height: 8.5px;
        width: 100%;
        background: #ccd5f6;
        // box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
      }

      &-circle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 12px;
        width: 12px;
        background: #499fde;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        transition: all 0.6s ease-in-out 0.4s;
      }

      &-stripe {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -170%);
        height: 10px;
        width: 2px;
        background: #ccd5f6;
        opacity: 0.5;
      }
    }
  }
}
