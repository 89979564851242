.circle {
  margin-top: 56px;
  width: 177px;
  height: 177px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  background: url(../../icons/circleGradient_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  z-index: 2;
  animation: gradientAnimationIn 1.3s ease;
  @keyframes gradientAnimationIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &__border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 16.7px solid #fbfbff;
    border-radius: 50%;
    z-index: 20;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 50%;
    height: 100%;
    z-index: 4;
    background: rgb(255, 255, 255);
  }
  &:after {
    content: '';
    position: absolute;
    top: 0%;
    right: 0%;
    width: 50%;
    height: 100%;
    z-index: 3;
    background: url(../../icons/circleGradient_icon.svg);
    opacity: 1;
    background-size: 177px 146px;
    background-position: right;
    background-repeat: no-repeat;
  }

  &__conicGradMeasure {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow: hidden;
    background: rgb(255, 255, 255);
  }

  &__toRotate {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      top: 0px;
      right: 0px;
      width: 50%;
      height: 100%;
      background: #ffffff;
      z-index: 2;
    }
  }
  &__triangleToRotate {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transition: 2.5s linear;
    background: transparent;
    z-index: 20;

    &-triangle {
      position: absolute;
      z-index: 20;
      top: 9.5%;
      left: 50%;
      transform: translate(-50.5%, -50%);
      background-size: 100%;
      background-repeat: no-repeat;
      transition: 2.5s linear;
    }
  }

  &__lines {
    z-index: 11;
    width: 138.5px;
    height: 138.5px;
    padding: 0px;
    border-radius: 50%;
  }
  &.loaded {
    &:before {
      animation: mask_left 1.25s steps(1, end) forwards;
    }
    &:after {
      animation: mask_right 1.25s steps(1, end) forwards;
    }
    .circle__toRotate {
      animation: rotate 2.5s forwards linear;
    }
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes mask_left {
    0% {
      visibility: visible;
    }
    50% {
    }
    100% {
      visibility: hidden;
    }
  }
  @keyframes mask_right {
    0% {
      visibility: hidden;
    }
    97.5% {
      visibility: visible;
    }
  }
  &__photoPlace {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 115px;
    height: 115px;
    background: rgb(223, 223, 223);
    box-shadow: none;
    z-index: 20;

    &__photo {
      width: 115px;
      height: 115px;
      border-radius: 50%;
      cursor: default;
    }
  }
  .popup {
    position: absolute;
    background-color: white;
    color: #414042;
    padding: 1.5rem;
    margin-left: 1rem;
    max-width: max-content;
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgb(218, 218, 218);
    text-transform: none;

    &.open {
      border-radius: 15px;
      padding: 15px;
      border-top-left-radius: 3px;
      transition: 0.25s cubic-bezier(0.55, -0.33, 0.27, 1.45);
      text-transform: none;
      padding: 18px 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 19.6px;
    }
    &.closed {
      opacity: 0;
    }
  }
}
.ant-skeleton-header {
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
