$toggle-bg-active: #10b981;
$toggle-border-active: #ffffff;

$toggle-bg-inactive: #646f87;
$toggle-border-inactive: #ffffff;

.meetings-toggle {
  position: relative;
  display: block;
  background-color: $toggle-bg-inactive;
  color: #646c83;
  border-radius: 36.5px;
  min-width: 36px;
  width: 36px;
  height: 20px;
  padding: 2px;
  cursor: pointer;
  border: 1px solid $toggle-border-inactive;
  transition: all 0.3s, color 0s;

  &-active {
    background-color: $toggle-bg-active;
    border: 1px solid $toggle-border-active;
  }

  &__toggle {
    position: absolute;
    top: 50%;
    left: 1px;
    transform: translate(0, -50%);
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(85, 85, 85, 0.38);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    transition: all 0.3s;
  }

  &__control {
    width: 0px;
    border: 0;

    &:checked + .meetings-toggle__toggle {
      left: 100%;
      transform: translate(calc(-100% - 1px), -50%);
      box-shadow: 0px 1px 2px rgba(15, 71, 40, 0.38);
    }
  }
}
