@import './components/TurnsAnalysis/turns-analysis';

.meeting-analysis {
    &__summary-item {
        align-items: flex-start;
    }

    &__avatar {
        border-color: #FFFFFF;
    }

    &__metrics-wrapper {
        display: flex;
        gap: 24px;
    
        & > * {
            flex: 1 0 0;
        }
    }

    &__tooltip-content {
        display: flex;
        flex-direction: column;
    }

    &__tooltip-text-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;
        white-space: nowrap;

        & > :first-child {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__tooltip-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;

        &--gray {
            color: $gray-300;
        }
    }

    &__tooltip-text-separator {
        &::after {
            content: "";
            display: block;
            border-radius: 50%;
            background-color: $gray-300;
            width: 4px;
            height: 4px;
        }
    }
}
