@import './components/tableRow/relations-table-row';
@import './components/relationshipDetails/relationship-details';
@import './components/RelationshipFilters/relationship-filters';
@import './components/addBotModal/add-bot-modal';
@import './components/relationshipEvents/relationship-events';

.my-relationships {
  position: relative;
  cursor: default;
  animation: animationFadeIn 0.2s ease-in;
  z-index: 1;

  @keyframes tableFadeIn {
    0% {
      opacity: 0;
    }
    80% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }

  &__header-section {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 24px;

    &-title {
      color: #070b12;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
    }

    &__controls {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
    }
  }

  &__table-wrapper {
    width: 100%;
    height: calc(100vh - 230px);
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #e3e7ed;

    &__load-more {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 14px;
      left: 50%;
      transform: translate(calc(50% - 72px), 0px);
      z-index: 1;

      &.hidden {
        display: none;
        visibility: hidden;
      }

      &-button {
        position: relative;
        appearance: none;
        display: inline-flex;
        align-items: center;
        min-width: 105px;
        width: max-content;
        height: 40px;
        min-height: 40px;
        gap: 8px;
        padding: 7px 12px;
        border-radius: 8px;
        color: $black-new-design;
        background-color: #ffffff;
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;

        border: 1px solid #e3e7ed;
        transition: background-color 150ms ease-in-out,
          border-color 150ms ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: #f8fafc;

          .my-relationships__table-wrapper__load-more-button-tooltip {
            display: flex;
          }
        }

        &:active {
          background-color: $gray-100;
        }

        &-tooltip {
          display: none;
          position: absolute;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          width: max-content;
          left: 50%;
          top: 0;
          transform: translate(-50%, -50px);
          color: $black-new-design;
          padding: 5px 16px;
          border-radius: 8px;
          border: 1px solid #e3e7ed;
          background-color: #ffffff;
          box-shadow: 0px 1px 2px 0px rgba(71, 107, 132, 0.3),
            0px 2px 6px 0px rgba(71, 107, 132, 0.15);
          z-index: 15;
          animation: tooltip-enter 0.15s linear;
        }

        &-loader {
          position: absolute;
          top: 50%;
          right: 31%;
          width: 20px;
          height: 20px;
          z-index: 9;
          animation: spinAnimation 1s linear infinite;

          @keyframes spinAnimation {
            0% {
              transform: translate(-50%, -50%) rotate(0deg);
            }
            100% {
              transform: translate(-50%, -50%) rotate(360deg);
            }
          }
        }
      }
    }

    &-scroll-element {
      width: 100%;
      height: 0;
      opacity: 0;
      pointer-events: none;
    }

    &__no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 384px;
      height: calc(100vh - 282px);
      animation: $fadein;
      text-align: center;
      margin: 0 auto;

      &-title {
        color: #070b12;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
      }

      &-text {
        color: #646F87;
        font-size: 14px;
        line-height: 24px;
        margin-top: 4px;
      }

      &-buttons-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        gap: 16px;
      }

      &-img {
        margin: 26px 0;
        min-width: 230.661px;
        width: 230.661px;
        min-height: 190.781px;
        height: 190.781px;
        @include userSelectDisable;
      }

      &-clear-button {
        appearance: none;
        display: inline-flex;
        align-items: center;
        gap: 8px;
        padding: 7px 12px;
        border-radius: 8px;
        color: $black-new-design;
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
        min-height: 40px;
        border: 1px solid #e3e7ed;
        background-color: #18222f;
        border-color: #18222f;
        color: #ffffff;
        transition: background-color 150ms ease-in-out,
          box-shadow 150ms ease-in-out, border-color 150ms ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: #070b12;
          box-shadow: none;
        }

        &.hidden {
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }

  &__scrollbar {
    height: calc(100% - 50px);
  }

  &__tooltip {
    width: auto;
  }

  &__connect-crm-icon {
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }
}
