.meeting-details-error-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 12px 16px;
    gap: 8px;
    font-size: 14px;
    line-height: 24px;
    color: $gray-300;

    &__refresh-button {
        & > svg {
            width: 12px;
            min-width: 12px;
            height: 12px;
        }
    }

    &--horizontal {
        flex-direction: row;
        gap: 16px;
    }
}
