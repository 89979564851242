$border-color: $secondary-shade25;
$text-color: $text-high-emp;
$background-color: #f0f2f9;
$background-color-hover: $tertiary-background;

.mainBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 142px;
  width: 100%;
  z-index: 100;
  min-height: 35px;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: right;
  animation: fadeIn 0.4s linear;

  &.performance {
    width: 50px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    z-index: 1001;
    background-color: #f9f9fc;
  }

  &.teamq-page {
    width: 160px;
    margin-right: 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    z-index: 1001;
    background-color: #f9f9fc;
  }

  &.in-webhook {
    width: 160px;
    min-height: 31px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    z-index: 9;
    background-color: #f9f9fc;
  }

  &.in-subscription {
    height: 72px;
  }

  &.up-index {
    z-index: 101;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  &__label {
    text-align: start;
    text-transform: capitalize;
    display: block;
    font-weight: 600; // TODO: change if needed
    font-size: 12px;
    line-height: 12px;
    margin: 0px 0px 8px 0px;
    color: $text-med-emp;

    &.required {
      &::after {
        content: '*';
        width: 2px;
        height: 2px;
        color: $error;
      }
    }
  }

  &-current-selected-item-container {
    position: relative;
    padding: 1px;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    background-color: $background-color;
    z-index: 2;
    border-radius: 4px;

    &.performance {
      background-color: #f9f9fc;
      outline: none;

      &.open {
        background-color: #ffffff;
      }
    }

    &.teamq-page {
      background-color: #f9f9fc;
      outline: none;

      &.open {
        background-color: #ffffff;
      }
    }

    &.in-subscription {
      justify-content: space-between;
      padding: 25px 17px;
      background-color: #ffffff;
      border: 1px solid #cdd5e5;
      border-radius: 8px;

      &.open {
        outline: none;
        background-color: #ffffff;
        border: 1px solid #b3bcce;
        border-bottom: none;
        border-radius: 8px 8px 0 0;
      }
    }

    &.in-add-info-popup {
      justify-content: space-between;
      padding: 0px 16px 0px 50px;
      border: 1px solid #cdd5e5;
      border-radius: 8px;

      &.open {
        justify-content: space-between;
        border: 1px solid #cdd5e5;
        border-radius: 8px 8px 0px 0px;
        border-bottom: transparent;
        outline: none;
      }
    }

    &.in-add-bot-modal {
      outline: 1px solid #e3e7ed;

      &.open {
        outline: 1px solid #537bff;
      }
    }

    &.open {
      outline: 0.1px solid $secondary-shade25;
    }

    &.warn {
      outline: 0.1px solid #eba53a;
    }

    &.error {
      outline: 0.1px solid $error;
    }

    &-icon-in-add-info-popup {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
    }

    &-optionalIcon {
      width: 24px;
      height: 24px;
      @include userSelectDisable;
    }
  }

  &-current-selected-item-text {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #4d4558;
    min-width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @include userSelectDisable;

    &.teamq-page {
      justify-content: flex-start;
      white-space: nowrap;
      overflow: hidden;
    }

    &.in-webhook {
      justify-content: flex-start;
      white-space: nowrap;
      overflow: hidden;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 400;
    }

    &.placeholder {
      font-size: 12px;
      font-weight: 400;
      color: #8189a1;
    }

    &.in-subscription {
      width: 89%;
      justify-content: flex-start;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 400;
    }
  }

  &-chevron-container {
    width: 9px;
    height: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s linear;

    &.performance {
      width: 15px;
      height: 15px;
    }

    &.in-webhook {
      width: 16px;
      height: 16px;
    }

    &.in-subscription {
      width: 16px;
      height: 16px;
    }

    &.in-add-info-popup {
      width: 18px;
      height: 18px;
    }

    &.in-add-bot-modal {
      width: 16px;
      height: 16px;
    }

    &.revert {
      transform: rotateX(180deg);
    }
  }

  &-optionalIcon {
    position: absolute;
    left: 5%;
  }

  //drop

  &-dropped-overflow-container {
    overflow-y: auto;
    overflow-x: visible;
    height: fit-content;
    width: 100%;
    top: 10px;
    position: absolute;
    cursor: pointer;
    filter: drop-shadow(0px 2px 8px rgba(36, 45, 81, 0.2));
    border-radius: 4px;

    &.performance {
      top: 0px;
    }

    &.in-subscription {
      border: 0.1px solid #b3bcce;
      border-radius: 8px;
      filter: drop-shadow(0px 1px 4px rgba(36, 45, 81, 0.1));
      animation: subscriptionBorderAnimation 0.1s linear;

      @keyframes subscriptionBorderAnimation {
        0% {
          border: none;
          opacity: 0.5;
        }
        100% {
          border: 0.1px solid #b3bcce;
          opacity: 1;
        }
      }
    }

    &.in-add-info-popup {
      top: 50px;
      border: 1px solid #cdd5e5;
      border-radius: 8px;
      outline: none;
      filter: none;

      animation: fadeInAnimation 0.2s linear;

      @keyframes fadeInAnimation {
        0% {
          border: 0.1px solid transparent;
          opacity: 0;
        }
        100% {
          border: 1px solid #cdd5e5;
          opacity: 1;
        }
      }
    }

    &.in-add-bot-modal {
      overflow-y: hidden;
    }

    &.closed {
      animation: closeDropdownAnimation 0.2s linear;
      width: 0;
      height: 0;
    }
  }

  &-dropped-block {
    max-height: 180px;
    background-color: $background-color;
    padding-top: 25px;
    padding-bottom: 5px;
    z-index: 1;
    transition: all 0.3s ease;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(0);
    border-radius: 10px;
    border-top: none;

    &.performance {
      background-color: #f9f9fc;
      outline: none;
      padding-top: 35px;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
    }

    &.closed {
      transform: translateY(-100%);
      padding-top: 0;
    }

    &.in-crm {
      background-color: #f8f8fb;
    }

    &.in-webhook {
      background-color: $main-background;
    }

    &.in-subscription {
      padding-top: 20px;
      transition: all 0.1s linear;
      background-color: #ffffff;
    }

    &.in-add-info-popup {
      max-height: 300px;
      background-color: #ffffff;
      border-radius: 8px;
    }

    &.in-add-bot-modal {
      background-color: #ffffff;
      transform: translateY(10px);
      padding-top: 5px;
      padding-bottom: 15px;
      border-radius: 4px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @keyframes closeDropdownAnimation {
      0% {
        height: 100%;
        opacity: 1;
      }
      100% {
        height: 0px;
        opacity: 0;
      }
    }
  }

  .drop-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 5px 0px 5px 15px;
    min-height: 30px;
    font-size: 12px;
    white-space: nowrap;

    &.in-subscription {
      font-size: 14px;
      padding: 8px 0px 8px 15px;
    }

    &:hover {
      background: #edf0f4;
      color: #646c83;
      // border-radius: 10px;
    }

    &.all-users {
      border-top: 1px solid $tertiary-background;
    }

    &.in-add-info-popup {
      min-height: 45px;
      padding: 4px 8px 4px 50px;

      background-color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      color: #4d4558;
      transition: all 0.1s linear;

      &:hover {
        background: #f7f8fa;
        color: #646c83;
      }
    }

    &.new-acc {
      min-height: 36px;
      width: 95%;
      padding: 5px 0px 5px 5px;
      border-bottom: 1px solid rgba(227, 231, 237, 1);
      border-radius: 4px;

      &:hover {
        background: rgba(245, 247, 255, 1);
      }

      &::before {
        content: url(./assets/add-bot-plus-icon.svg);
        margin: 0 10px 0 4.5px;
        height: 16px;
        width: 16px;
      }
    }

    &.add-bot {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      min-height: 34px;
      width: 95%;
      padding: 5px 0px 5px 5px;
      margin-top: 4px;
      border-radius: 4px;

      &:hover {
        background: rgba(245, 247, 255, 1);
      }

      &.disabled {
        color: #414042;
        opacity: 0.5;
      }
    }

    &.disabled {
      color: rgb(207, 207, 207);
      pointer-events: none;
      cursor: default;
    }

    &-avatar {
      width: 24px;
      height: 24px;
      min-width: 24px;
      border-radius: 8px;
      background: #ffffff;
      margin-right: 8px;
    }
  }
}
