.rs-details-note-item {
  display: flex;
  margin: 0 8px;
  padding: 0 8px;
  gap: 16px;
  border-radius: 8px;
  transition: background-color 150ms ease-in-out;
  cursor: pointer;
  animation: $fadein;

  &:hover:not(:has(.rs-details-note-item__dropdown-button:hover)) {
    background-color: #f8fafc;
  }

  &:active:not(:has(.rs-details-note-item__dropdown-button:active)) {
    background-color: $gray-100;
  }

  & > * {
    padding: 8px 0;
    display: flex;
    gap: 4px;
  }

  &__time {
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    width: 124px;
    min-width: 124px;
  }

  &__time-text {
    font-size: 12px;
    line-height: 20px;

    &--gray {
      font-size: 10px;
      line-height: 16px;
      font-weight: 500;
      color: $gray-300;
    }
  }

  &__note {
    flex-grow: 1;
    font-size: 12px;
    line-height: 20px;
    justify-content: space-between;
  }

  &__note-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__dropdown-button {
    appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    padding: 4px;
    border: none;
    background-color: transparent;
    border-radius: 50%;
    transition: background-color 150ms ease-in-out;
    cursor: pointer;

    & > svg {
      width: 16px;
      height: 16px;
    }

    &:hover:not(.rs-details-note-item__dropdown-button--active) {
      background-color: #f8fafc;
    }

    &:active:not(.rs-details-note-item__dropdown-button--active) {
      background-color: $gray-100;
    }

    &--active {
      background-color: $gray-100;
    }
  }

  &__dropdown {
    height: 24px;
  }
}
