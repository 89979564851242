@import './components/TurnsScale/turns-scale';

.turns-analysis {
    &__button {
        appearance: none;
        background-color: transparent;
        padding: 0;
        border: none;
        display: inline-flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        line-height: 24px;
        color: $gray-300;

        &:not(:disabled) {
            cursor: pointer;
        }
    }

    &__button-icon {
        width: 12px;
        height: 12px;

        & > path {
            fill: $gray-300;
        }
    }

    &__content-wrapper {
        user-select: none;
        cursor: grab;
        overflow: auto hidden;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__content {
        position: relative;
    }

    &__current-time-wrapper {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__current-time {
        z-index: 3;
        position: absolute;
        top: 6px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 8px;
        border-radius: 20px;
        background-color: #18222F;
        color: #FFFFFF;
        font-size: 10px;
        line-height: 12px;
        width: 34px;
        visibility: hidden;
        user-select: none;
        cursor: grab;
    }

    &__current-time-line {
        position: absolute;
        top: 6px;
        left: 17px;
        transform: translateX(-50%);
        width: 1px;
        background-color: #18222F;
        z-index: 2;
        visibility: hidden;
    }

    &__timeline {
        display: flex;
        align-items: center;
        gap: 16px;
        user-select: none;
        border-bottom: 1px solid #E3E7ED;
        overflow: hidden;
        cursor: default;
    }

    &__timeline-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        height: 32px;
        width: 40px;
        min-width: 40px;
        text-align: center;
        color: $gray-200;
        font-size: 12px;
        line-height: 16px;
        position: relative;

        &--divider {
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 2px;
                width: 2px;
                border-radius: 50%;
                background-color: $gray-200;
            }
        }
    }

    &__total-turns {
        padding: 16px;
    }

    &__turn-items-wrapper {
        overflow: hidden;
        max-height: 0;
        transition: max-height 300ms ease-in-out;
    }

    &__turn-item {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 12px 16px;
        
        &:not(:first-child) {
            border-top: 1px solid #E3E7ED;
        }
    }

    &__turn-item-participant {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        line-height: 24px;
    }

    &__turn-item-participant-name {
        font-weight: 400;
    }

    & .event-analysis-compare {
        position: relative;
    }

    & .event-analysis-compare__content {
        padding: 0;
        gap: 0;
    }

    &--expanded {
        & .turns-analysis__button-icon {
            transform: rotate(180deg);
        }

        & .turns-analysis__turn-items-wrapper {
            max-height: max-content;
        }
    }

    &--dragging {
        & .turns-analysis__content-wrapper {
            cursor: grabbing;
        }
    }

    &--played {
        & .turns-analysis__timeline {
            cursor: pointer;
        }

        & .turns-analysis__current-time {
            visibility: visible;
        }

        & .turns-analysis__current-time-line {
            visibility: visible;
        }
    }

    &--changing-timeline {
        & .turns-analysis__current-time {
            cursor: grabbing;
        }
    }

    &--playing {
        & .turns-analysis__content-wrapper {
            overflow: hidden;
            cursor: default;
        }
    }

    &--loading {
        & .turns-analysis__timeline {
            width: 100%;
        }

        & .turns-analysis__content {
            overflow: hidden;
        }
    }
}