@import './components/EventSummaryTopics/event-summary-topics';
@import './components/EventSummaryList/event-summary-list';
@import './components/EventSummaryEmpty/event-summary-empty';
@import './components/EventSummarySignals/event-summary-signals';

.event-summary-item {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__content {
        font-size: 12px;
        line-height: 20px;
    }
}
