.event-summary-signals {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid #E3E7ED;
  border-radius: 12px;
  padding: 8px 12px;

  &__item {
    display: flex;
    gap: 8px;

    &--red {
      .event-summary-signals__indicator {
        background-color: #F0384A;
      }
    }

    &--green {
      .event-summary-signals__indicator {
        background-color: #10B981;
      }
    }
  }

  &__indicator-wrapper {
    display: flex;
    align-items: center;
    height: 20px;
  }

  &__indicator {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 1px solid #FFFFFF;
    background-color: $gray-300;
    box-shadow: 0px 2px 2px 0px rgba(85, 106, 142, 0.16);
  }
}