@import './components/companyDetails/company-details';
@import './components/rsDetailsControlsRow/controls';
@import './components/Notes/notes';
@import './components/MemberContactModal/member-contact-modal';
@import './components/TeamMember/team-member';
@import './components/EditModeChanges/edit-mode-changes';
@import './components/TeamMembersSection/team-members-section';
@import './components/EditModeToolbar/edit-mode-toolbar';

.rs-details {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 16px;
    margin-top: 24px;

    &-nav-item {
      color: #646f87;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      transition: color 0.2s linear, border-color 0.2s linear;

      &:hover {
        color: #3a404d;
      }

      &.active {
        color: #070b12;
        border-bottom: 2px solid #5243FA;
        cursor: default;
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
    margin-top: 24px;
    animation: $fadein;

    &__company {
      width: 100%;
      margin-bottom: 1%;
    }

    &__team {
      display: flex;
      width: calc(50% - 12px);
    }

    &__unassigned {
      width: 49.5%;
      margin-top: 1%;
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    background-color: #E3E7ED;
  }
}
