.reply {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__contacts {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #4d4558;

    &__to {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 9px 0;
      margin-top: 4px;

      &:first-child {
        padding: 0;
        margin-top: 0;
      }

      &-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
      }

      &-name {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
        margin-left: 10px;
      }

      &-sendto {
        background-color: transparent;
        margin-left: 10px;

        input {
          background-color: transparent;
          border-radius: 4px;
          padding: 6px 10px;
        }
      }

      &-cc {
        background-color: transparent;
        margin-left: 10px;

        input {
          background-color: transparent;
          border-radius: 4px;
          padding: 6px 10px;
        }
      }
    }

    &__subject {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      border: 1px solid #edf0f4;
      border-left: none;
      border-right: none;
      padding: 9px 0;

      &-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
      }

      &-name {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
        margin-left: 10px;
      }

      &-input {
        background-color: transparent;
        margin-left: 10px;

        input {
          background-color: transparent;
          border-radius: 4px;
          padding: 6px 10px;
        }
      }
    }
  }

  &__message {
    &__textarea {
      width: 100%;
      height: 100%;
      margin-top: 18px;

      &-control {
        padding: 10px 16px;
        border-radius: 8px;
        width: 100%;
        height: 100%;
        min-height: 120px;
        max-height: 500px;
        color: #4d4558;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
        border: 1px solid #cdd5e5;
        border: 1px solid transparent;

        &:focus {
          border: 1px solid #b3bcce;
        }

        &::placeholder {
          color: #94a3b8;
          padding-top: 2px;
        }
      }
    }
  }

  &__buttons {
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */

    &-button {
      display: flex;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      background-color: #4d4558;
      color: #ffffff;
      border-radius: 4px;
      border: none;
      transition: all 0.2s linear;

      &:hover {
        background-color: #3c3744;
      }

      &:last-child {
        margin-left: 8px;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.7;
        @include userSelectDisable;
      }
    }
  }
}
