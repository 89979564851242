.meeting-analysis-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    &__title {
        display: flex;
        gap: 4px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.75;
        white-space: nowrap;
        width: 50%;

        & > span {
            max-width: calc(100% - 20px);
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__legend-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
        width: 50%;
    }

    &__legend {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
        padding: 4px 0;
        font-size: 12px;
        line-height: 20px;
        width: calc(100% - 44px);

        & > * {
            max-width: calc(100% / 3);
        }

        &--no-zoom {
            width: 100%;
        }
    }

    &__legend-tooltip {
        cursor: pointer;
    }

    &__legend-tooltip-content {
        width: auto;
    }

    &__legend-item {
        display: flex;
        align-items: center;
        padding-left: 12px;
        position: relative;
        max-width: 100%;

        & span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }

        &::before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-color: $color-unassigned;
        }

        &--userSide {
            &::before {
                background-color:$color-user;
            }
        }

        &--prospectSide {
            &::before {
                background-color: $color-prospect;
            }
        }
    }

    &__zoom-tooltip {
        width: auto;
    }
}
