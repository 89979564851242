@import './components/AnalysisItem/analysis-item';
@import './components/AnalysisItemContent/analysis-item-content';
@import './components/AnalysisItemHeader/analysis-item-header';
@import './components/Chart/chart.scss';
@import './components/ChartAxis/chart-axis';
@import './components/ChartTooltip/chart-tooltip';
@import './components/ErrorScreen/error-screen';
@import './components/LoaderScreen/loader-screen';
@import './components/MeetingAnalysis/meeting-analysis';
@import './components/MeetingRightContent/meeting-right-content';
@import './components/NonVerbalAnalysis/non-verbal-analysis';
@import './components/ParticipantTooltip/participant-tooltip';
@import './components/TimelineChartWrapper/timeline-chart-wrapper';
@import './components/VerbalAnalysis/verbal-analysis';
@import './components/VideoPlayer/video-player';

.meeting-details {
    display: flex;
    flex-direction: column;
    color: $black-new-design;
    gap: 24px;
}
