.note-item-overview {
    color: $black-new-design;

    & .sound-wave-modal__dialog-content {
        padding: 0;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 24px;
        border-bottom: 1px solid #E3E7ED;
    }

    &__header-text-wrapper {
        display: flex;
        flex-direction: column;
        padding: 8px 0;
    }

    &__date-text {
        font-size: 14px;
        line-height: 24px;
    }

    &__time-text {
        font-size: 12px;
        line-height: 20px;
        color: $gray-300;
    }

    &__header-buttons-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__button {
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        min-width: 24px;
        height: 24px;
        padding: 4px;
        border: none;
        background-color: transparent;
        border-radius: 50%;
        transition: background-color 150ms ease-in-out;
        cursor: pointer;

        & > svg {
            width: 16px;
            height: 16px;
        }

        &:hover {
            background-color: #F8FAFC;
        }

        &:active {
            background-color: $gray-100;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 16px 24px;
        gap: 4px;
    }

    &__title {
        font-size: 10px;
        line-height: 16px;
        font-weight: 500;
        color: $gray-300;
    }

    &__text {
        font-size: 12px;
        line-height: 20px;
    }
}
