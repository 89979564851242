$border-color: $secondary-shade25;
$text-color: #414042;
$background-color: #f0f2f9;
$background-color-hover: rgb(231, 232, 248);

.main-block {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 160px;
  width: 54%;
  z-index: 100;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: right;
  animation: fadeIn 0.4s linear;

  &:focus {
    border: 1px solid #acbbf5;
    z-index: 1111111;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  &__selected-item-container {
    padding: 1px 20px;
    width: 100%;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    background-color: $background-color;
    z-index: 2;
    border-radius: 10px;
    &.disabled {
      opacity: 0.3;
      cursor: default;
    }

    &.open {
      outline: 0.1px solid $border-color;
    }
    &__first-block {
      display: flex;
      flex-direction: row;

      &__optionalIcon {
        @include userSelectDisable;
        margin: -1.5px 15px 0px 0px;
      }
      &__text {
        color: #394577;
        min-width: 60%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        @include userSelectDisable;
      }
    }
  }
  &__chevron-container {
    width: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in;
    margin-right: -3px;

    &.revert {
      transform: rotate(180deg);
    }
  }

  //drop

  &__dropped-overflow-container {
    overflow-y: auto;
    overflow-x: visible;
    height: fit-content;
    width: 100%;
    top: 10px;
    position: absolute;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    &.closed {
      height: 0;
    }
  }

  &__dropped-block {
    max-height: 200px;
    background-color: $background-color;
    padding-top: 45px;
    padding-bottom: 5px;
    z-index: 1;
    transition: all 0.12s ease-out;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(0);
    border-radius: 10px;
    border-top: none;

    &.closed {
      transform: translateY(-100%);
      padding-top: 0;
      animation: closeDropDownAnimation 0.15s ease-out forwards;
    }

    @keyframes closeDropDownAnimation {
      0% {
        height: 100%;
        opacity: 1;
      }
      100% {
        height: 0px;
        opacity: 0;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
    &::-moz-scrollbar {
      display: none;
    }
    &::-ms-scrollbar {
      display: none;
    }
  }

  .drop-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 5px 0px 5px 15.6%;
    min-height: 45px;

    &.red {
      color: #f26065;
    }

    &:hover {
      background: $background-color-hover;
      border-radius: 8px;
    }
  }
}
