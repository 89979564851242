@import './components/ModalHeader/modal-header';
@import './components/ModalFooter/modal-footer';

.sound-wave-modal {
    display: flex;
    position: fixed;
    overflow-y: auto;
    z-index: 1000002;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(7, 11, 18, 0.22);
    transition: opacity 150ms ease-in-out 150ms;
    animation: openAnimation 150ms ease-in-out;

    &:not(:last-child) {
        opacity: 0;
        transition: opacity 150ms ease-in-out;
    }

    &__dialog {
        display: flex;
        flex-direction: column;
        margin: auto;
        background-color: #FFFFFF;
        border-radius: 16px;
    }

    &__dialog-content {
        display: flex;
        flex-direction: column;
        padding: 24px;
    }

    &--closing {
        opacity: 0;
        transition: opacity 150ms ease-in-out;
        animation: dummyAnimation 150ms ease-in-out forwards;
    }

    &--confirmation {
        .sound-wave-modal__dialog {
            gap: 16px;
            padding: 24px 0;
        }

        .sound-wave-modal-header,
        .sound-wave-modal-footer {
            border: none;
            padding: 0 32px;
        }

        .sound-wave-modal__dialog-content {
            padding: 0 32px;
        }

        .sound-wave-modal-header__button {
            padding: 0;
            width: 16px;
            height: 16px;
        }
    }

    @keyframes openAnimation {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes dummyAnimation {
        from {
            display: flex;
        }
        to {
            display: flex;
        }
    }
}
