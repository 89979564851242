.btn {
  font-weight: 500;
  border-radius: 10px;
  border: 0;
  padding: 1.5rem 0;
  cursor: pointer;
  font-size: 1.6rem;
  display: block;
  width: 100%;
  transition: all 0.5s;

  &-common {
    background-color: $text-high-emp;
    color: #fff;

    &:hover {
      background-color: darken($text-high-emp, 5);
    }

    &:disabled {
      background: #dbdbdb;
      cursor: default;
    }
  }

  &-text {
    background-color: inherit;
    color: $text-high-emp;
    padding: 0;

    &:hover {
      color: darken($text-high-emp, 5);
    }
  }
  &-loader__wrapper {
    height: 25px;
    width: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: auto;
    div {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: white;
    }
    &-dot__one {
      animation: dotAnimation 1s infinite;
      animation-delay: 0.25s;
    }
    &-dot__two {
      animation: dotAnimation 1s infinite;
      animation-delay: 0.5s;
    }
    &-dot__three {
      animation: dotAnimation 1s infinite;
      animation-delay: 0.75s;
    }
    @keyframes dotAnimation {
      0% {
        transform: translateY(0px);
      }
      20% {
        transform: translateY(-5px);
      }
      40% {
        transform: translateY(0px);
      }
    }
  }
}
