@import './components/App/app';
@import './components/WebhooksForm/webhooks-form';

.apps {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 16px;
  animation: fadeIn 0.6s linear;

  &__suggest-feature-button {
    margin-left: auto;
    margin-right: 16px;
  }

  &__webhook-settings-button {
    & svg path {
      transition: fill 150ms ease-in-out;
    }

    &:hover:not(:disabled) svg path {
      fill: #453e4f;
    }
  }

  &__modal {
    & .sound-wave-modal__dialog-content {
      gap: 16px;
    }
  }

  &__modal-title {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px; 
  }

  &__modal-textarea textarea {
      height: 128px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
