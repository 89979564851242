.account-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;

  &-avatar {
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
    cursor: default;
  }

  &__name {
    display: block;
    font-weight: 500;
    width: max-content;
    max-width: calc(100% - 65px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &__tooltip {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 40px;
      background-color: $second-background;
      min-width: 150px;
      padding: 5px 15px;
      z-index: 100;
      height: max-content;
      box-shadow: 0px 4px 10px rgba(57, 69, 119, 0.25);
      color: $white;
      border-radius: 4px;
      white-space: pre-line;
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;

      &.open {
        display: flex;
      }
    }
  }

  &-subrows-qty {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    padding: 2px;
    border-radius: 50%;
    margin-left: 8px;
    color: white;
    background-color: $dark-grey;
    font-size: 11px;
    text-align: center;
  }
}
