.label {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 248px;
  height: 74px;
  border: 0;
  padding: 16px 0;
  background-color: inherit;
  border-radius: 10px;
  transition: all 0.25s;
  cursor: pointer;

  &.lower {
    height: 68px;
  }

  &.disabled {
    cursor: default;

    &:hover {
      background: transparent;
      background-color: transparent;
    }
  }

  &:hover {
    .label__edit {
      opacity: 1;
    }
  }

  &__image-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    background-color: $tertiary-background;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;

    &-img {
      width: 20px;
      height: 20px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: calc(100% - 50px);

    &-title {
      font-weight: 600; // TODO: change if needed
      font-size: 14px;
      line-height: 14px;
      color: $text-med-emp;

      &.lower {
        padding: 10px 0 6px;
      }
    }

    &__value {
      position: relative;
      width: 100%;

      &:hover {
        .label__body__value-tooltip {
          display: flex;

          &.hidden {
            display: none;
          }
        }
      }

      &-text {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: max-content;
        max-width: calc(100% - 20px);
        font-size: 14px;
        line-height: 18px;
        color: $text-high-emp;
      }

      &-tooltip {
        position: absolute;
        display: none;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-family: 'Open Sans';
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        height: 25px;
        width: max-content;
        left: 0;
        top: 0;
        transform: translate(0, -28px);
        border-radius: 4px;
        padding: 6px 10px 6px 10px;
        color: rgba(255, 255, 255, 0.9);
        background: $second-background;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        z-index: 15;
        animation: tooltip-enter 0.15s linear;

        &.hidden {
          display: none;
        }
      }

      &__lock {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 210px;
        transform: translate(0px, 34px);
        width: 20px;
        height: 20px;

        &:hover {
          .label__body__value__lock-tooltip {
            display: flex;
          }
        }

        &.lower {
          transform: translate(0px, 36.5px);
        }

        &-icon {
          border-radius: 50%;
        }

        &-tooltip {
          position: absolute;
          display: none;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-family: 'Open Sans';
          font-size: 10px;
          font-weight: 400;
          line-height: 15px;
          height: 25px;
          width: max-content;
          left: 50%;
          top: 0;
          transform: translate(-50%, -28px);
          border-radius: 4px;
          padding: 6px 10px 6px 10px;
          color: rgba(255, 255, 255, 0.9);
          background: $second-background;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
          z-index: 15;
          animation: tooltip-enter 0.15s linear;
        }
      }
    }
  }

  &__edit {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    top: 50%;
    right: 0;
    transform: translate(5px, -50%);
    opacity: 0;
    transition: opacity 0.3s linear;

    &.lower {
      transform: translate(5px, -37%);
    }

    &.hidden {
      display: none;
    }

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      transition: all 0.2s linear;
      cursor: pointer;

      &:hover {
        background-color: $tertiary-background;
      }
    }
  }

  &-title {
    display: flex;
    flex-direction: row;
    position: relative;
    text-transform: capitalize;
    font-weight: 600; // TODO: change if needed
    font-size: 14px;
    line-height: 14px;
    color: $text-med-emp;

    &-iconBlock {
      position: relative;
      width: 10px;
      height: 10px;
      margin-left: 5px;
      cursor: pointer;

      &__icon {
        z-index: 10;
        cursor: pointer;
      }

      &-tooltip {
        display: none;
        text-transform: none;

        &.open {
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, 13px);
          font-size: 10px;
          font-weight: 400;
          line-height: 16px;
          padding: 10px;
          width: max-content;
          max-width: 185px;
          min-height: 20px;
          border-radius: 12px;
          color: #ffffff;
          background: $second-background;
          box-shadow: 0px 4px 10px rgba(57, 69, 119, 0.25);
          border-radius: 4px;
          z-index: 100;
          animation: tooltip-enter 0.15s ease-in-out;
        }

        &.closed {
          display: none;
        }
      }
    }
  }

  &-editIcon {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: end;
    padding: 2.5rem;
    left: 0%;
    top: 0%;
    opacity: 0;
    z-index: 1;

    &:hover {
      opacity: 1;
      transition: 0.5s;
      z-index: 1;
    }
  }

  &-text {
    font-size: 14px;
    line-height: 18px;
    color: $text-high-emp;
  }
}
