.deals-no-data {
  position: relative;
  width: 100%;
  height: 60vh;
  background-image: url('./assets/no-data-list-background.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  animation: $fadein;

  &__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1020px;
    height: 352px;
    border-radius: 6px;

    &__body {
      width: 100%;
      height: 100%;

      &-img {
        width: 100%;
        height: 100%;
        @include userSelectDisable;
      }

      &__close-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0px;
        right: 0px;
        transform: translate(-26px, 19px);
        cursor: pointer;
        width: 20px;
        height: 20px;
        z-index: 100;

        &:hover {
          .icon-background {
            fill: #f8f8fb;
          }
        }
      }
    }
  }
}

// @media screen and (min-width: 1800px) {
//   .deals-no-data {
//     background-size: initial;
//   }
// }
