.outgoing-chart {
  &__legend {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: 12px;
    opacity: 0.4;

    &-first {
      display: flex;
      margin-left: 6px;

      &::before {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #ebbcd4;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        opacity: 0.1;
      }

      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #ebbcd4;
        opacity: 0.25;
      }
    }

    &-second {
      display: flex;

      &::before {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #ebbcd4;
        opacity: 0.5;
      }

      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #ebbcd4;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        margin-right: 6px;
      }
    }
  }

  &__graph {
    width: 100%;
    height: 100%;
    background-image: url(./assets/outgoing-mail-1920.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: -40px;
    opacity: 0.4;
  }
}

.incoming-chart {
  &__legend {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: 12px;
    opacity: 0.4;

    &-first {
      display: flex;
      margin-left: 6px;

      &::before {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #c4c5ef;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        opacity: 0.1;
      }

      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #c4c5ef;
        opacity: 0.25;
      }
    }

    &-second {
      display: flex;

      &::before {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #c4c5ef;
        opacity: 0.5;
      }

      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #c4c5ef;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        margin-right: 6px;
      }
    }
  }

  &__graph {
    width: 100%;
    height: 100%;
    background-image: url(./assets/incoming-email-1920.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: -40px;
    opacity: 0.4;
  }
}

.apc-wins-chart {
  &-graph {
    width: 100%;
    height: 100%;
    background-image: url(./assets/apc-vs-wins-1920.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: -30px;
    opacity: 0.4;
  }
}

.apc-sales-chart {
  &-graph {
    width: 100%;
    height: 100%;
    background-image: url(./assets/apc-vs-sales-1920.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: -30px;
    opacity: 0.4;
  }
}

@media screen and (max-width: $desktop1440) {
  .outgoing-chart__graph {
    background-image: url(./assets/outgoing-mail-1440.png);
    width: 100%;
  }

  .incoming-chart__graph {
    background-image: url(./assets/incoming-email-1440.png);
    width: 100%;
  }

  .apc-wins-chart {
    background-image: url(./assets/apc-vs-wins-1440.png);
    width: 100%;
  }

  .apc-sales-chart {
    background-image: url(./assets/apc-vs-sales-1440.png);
    width: 100%;
  }
}

@media screen and (max-width: ($desktop1280 - 1)) {
  .outgoing-chart__graph {
    background-image: url(./assets/outgoing-mail-1280.png);
    width: 100%;
    transform: scale(1, 0.8);
  }

  .incoming-chart__graph {
    background-image: url(./assets/incoming-email-1280.png);
    width: 100%;
    transform: scale(1, 0.8);
  }

  .apc-wins-chart {
    background-image: url(./assets/apc-vs-wins-1280.png);
    width: 100%;
    transform: scale(1, 0.8);
  }

  .apc-sales-chart {
    background-image: url(./assets/apc-vs-sales-1280.png);
    width: 100%;
    transform: scale(1, 0.8);
  }
}
