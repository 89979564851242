.upload-clip {
  display: block;
  width: 127px;
  cursor: pointer;
  background: no-repeat url(./clip.svg);
  background-position: 1px 0px;
  padding: 0px 18px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #29335c;
  margin: 15px 0px;
}
#clip {
  display: none;
}
