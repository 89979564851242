.player-control {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    border: none;
    width: 32px;
    height: 32px;
    background-color: rgba(153, 153, 153, 0.30);
    backdrop-filter: contrast(0.8) blur(1px);
    border-radius: 50%;
    padding: 0;
    transition: background-color 150ms ease-in-out;
    cursor: pointer;

    &:hover:not(:disabled) {
        background-color: rgba(153, 153, 153, 0.60);
    }

    &:disabled {
        opacity: 0.6;
        cursor: default;
    }

    &--big {
        width: 40px;
        height: 40px;
    }

    &--big-play {
        width: 56px;
        height: 56px;
    }

    &--small {
        width: unset;
        min-width: 16px;
        height: 16px;
        background-color: transparent;
        backdrop-filter: none;
        border-radius: 0;

        &:hover:not(:disabled) {
            background-color: transparent;
        }
    }
}
