.topics {
  display: flex;
  flex-direction: column;
  color: #29335c;
  margin-top: 10px;
  &__header {
    padding-left: 3px;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
  }
  &__body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 10px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 16.3px;
    &-item {
      position: relative;
      display: block;
      text-align: center;
      margin: 4px;
      padding: 6px 6px 6px 8px;
      min-width: 45px;
      border-radius: 4px;
      background-color: #f3f3fd;
      max-width: 30%;
      &-text {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &-toltip {
        display: none;
      }
      &:hover {
        .topics__body-item-toltip {
          font-family: 'Open Sans';
          font-size: 10px;
          font-weight: 400;
          line-height: 15px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          position: absolute;
          height: 25px;
          width: max-content;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -180%);
          border-radius: 4px;
          padding: 6px 10px 6px 10px;
          color: rgba(255, 255, 255, 0.9);
          background: $second-background;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          z-index: 15;
        }
      }
    }
    &-message {
      font-family: 'Open Sans';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      padding: 5px 4.5px;
    }
  }
}
