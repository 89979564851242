.sentiment-analysis {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__text {
        font-size: 12px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
    }

    &__range {
        margin: 7px 0;
        position: relative;
        width: 100%;
        height: 6px;
        background-color: #F8FAFC;
        border-radius: 4px;
        z-index: 0;
    }

    &__range-divider {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 14px;
        width: 4px;
        border: 1px solid #FFFFFF;
        border-radius: 2px;
        background-color: #18222F;
        z-index: 1;
    }

    &__range-part {
        position: absolute;
        top: 0;
        left: 50%;
        max-width: 50%;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
        height: 100%;
        transform-origin: center left;
        z-index: 0;
        transition: width 300ms ease-in-out;

        &--positive {
            background-color: #10B981;
        }

        &--negative {
            background-color: #F0384A;
            transform: rotate(-180deg);
        }
    }

    &--negative {
        .sentiment-analysis__range-part--negative {
            transition: width 300ms ease-in-out 300ms;
        }
    }

    &--positive {
        .sentiment-analysis__range-part--positive {
            transition: width 300ms ease-in-out 300ms;
        }
    }
}
