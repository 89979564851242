.efh-skeleton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  transition: z-index 0s ease-in-out 300ms;
  z-index: 1;
  background-color: #f8fafc;
  gap: 24px;

  &__left {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 50%;
    max-width: 50%;

    &__info {
      display: flex;
      flex-direction: column;
      width: calc(100% - 130px);

      &__top {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        color: #070b12;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        gap: 8px;

        &-cell {
          width: 64px;
          max-width: 64px;
        }
      }

      &__bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        max-height: 28px;
        color: #070b12;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        gap: 4px;

        &-cell {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 84px;
          max-width: 84px;
          gap: 4px;
        }
      }

      &-separator {
        font-size: 16px;
        display: flex;
        align-items: center;
        font-weight: 500;
        color: $gray-200;
        line-height: 28px;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    width: 50%;
    max-width: 50%;
    gap: 16px;
    overflow: hidden;

    &__content {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 32px);
      border-radius: 1000px;
      border: 1px solid #e3e7ed;
      background: #f8fafc;
      height: 64px;
      padding: 16px;

      &__participants-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;
        gap: 4px;
      }

      &__three-dot-menu {
        width: 40px;
        position: relative;

        &__button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 1000px;
          border: 1px solid #e3e7ed;
          background: #fff;
        }

        &-icon {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(-20%, 20%);
          width: 12px;
          height: 12px;
          padding: 3px;
          background: #f0384a;
          border-radius: 50%;
        }
      }
    }
  }
}
