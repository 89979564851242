.meeting-talk-time-by-team {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    gap: 12px;
    width: 100%;

    &__legend {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }

    &__legend-item {
        display: flex;
        align-items: center;
        gap: 8px;
        align-items: center;
        max-width: calc(100% / 3);
        white-space: nowrap;
    }

    &__legend-item-name {
        max-width: 50%;
        cursor: pointer;
        
        & > span {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__legend-item-tooltip {
        width: auto;
    }

    &__circle {
        width: 8px;
        min-width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $color-unassigned;

        &--prospectSide {
            background-color: $color-prospect;
        }

        &--userSide {
            background-color: $color-user;
        }

        &--unassigned {
            background-color: $color-unassigned;
        }
    }

    &__text-gray {
        color: $gray-300;
    }

    &__bar-wrapper {
        display: flex;
        height: 8px;
        border-radius: 2px;
        overflow: hidden;
    }

    &__bar {
        height: 100%;
        border-left: 1px solid #FFFFFF;
        border-right: 1px solid #FFFFFF;
        background-color: $color-unassigned;

        &:first-child {
            border-left: none;
        }

        &:last-child {
            border-right: none;
        }

        &--prospectSide {
            background-color: $color-prospect;
        }

        &--userSide {
            background-color: $color-user;
        }

        &--unassigned {
            background-color: $color-unassigned;
        }
    }
}
