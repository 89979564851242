@import './components/myEngagementsTitle/my-eng-page-title';
@import './components/header/detailed-view-header';
@import './components/prospectData/prospect';
@import './components/crmData/crm-data';
@import './components/threadData/thread-data';
@import './components/emailSection/email-section';
@import './components/tooltip/deals-tooltip';

.my-deals {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 20vh;
  width: 100%;
  margin: auto;
  border-radius: 8px;
  color: #29335c;

  &__first-column {
    position: relative;
    width: 26%;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: $fadein;
  }

  &__second-column {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 73%;
    background-color: rgba(255, 255, 255, 0.878);
    border-radius: 6px;
    box-shadow: 0px 0px 4px #cbd0e9;
    animation: $fadein;

    &__sz-row {
      display: flex;
      flex-direction: row;
      height: 250px;
      padding: 15px 20px 0px 20px;

      &__selling-zone {
        position: relative;
        height: 244px;
        width: 73%;
        animation: $fadein;

        &__skeleton {
          position: absolute;
          width: 100%;
          height: calc(100% - 25px);
          top: 57%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #fff;
          z-index: 1000;

          &-header {
            width: 30%;
          }

          &__body {
            height: 170px;
            width: 100%;

            &-grey-zone {
              width: 100%;
              height: 100%;
              border-radius: 6px;
              background: linear-gradient(
                180deg,
                #e8ebf7 0%,
                #f7f9fd 3.65%,
                #e8ebf7 50%,
                #f7f9fd 96.35%,
                #e8ebf7 100%
              );
            }
          }

          &__footer {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding: 10px 50px;
          }
        }
      }

      &__divider {
        height: 267px;
        width: 1px;
        background-color: #e7eaff;
        margin: -16px 1.5% 0px 1.5%;
      }
    }
  }

  .my-deals__second-column__thread-data-section {
    position: relative;
    overflow: hidden;
    width: 35%;
  }

  &.flipped--prev--start {
    .my-deals__second-column .email__email-section-for-animation {
      animation: prevMessageStart 230ms ease-in forwards;
    }
  }

  &.flipped--prev--end {
    .my-deals__second-column .email__email-section-for-animation {
      animation: prevMessageEnd 230ms ease-out forwards;
    }
  }

  &.flipped--next--start {
    .my-deals__second-column .email__email-section-for-animation {
      animation: nextMessageStart 230ms ease-in forwards;
    }
  }

  &.flipped--next--end {
    .my-deals__second-column .email__email-section-for-animation {
      animation: nextMessageEnd 230ms ease-out forwards;
    }
  }

  &.flipped--timeline__prev--start {
    .my-deals__second-column .email__email-section-for-animation {
      animation: horizontalFlippedPrevItemStart 400ms ease-in-out forwards;
    }
  }

  &.flipped--timeline__prev--end {
    .my-deals__second-column .email__email-section-for-animation {
      animation: horizontalFlippedPrevItemEnd 400ms ease-in-out forwards;
    }
  }

  &.flipped--timeline__next--start {
    .my-deals__second-column .email__email-section-for-animation {
      animation: horizontalFlippedNextItemStart 400ms ease-in-out forwards;
    }
  }

  &.flipped--timeline__next--end {
    .my-deals__second-column .email__email-section-for-animation {
      animation: horizontalFlippedNextItemEnd 400ms ease-in-out forwards;
    }
  }

  &.flipped--change__branch--start {
    .my-deals__second-column .email__email-section-for-animation {
      animation: horizontalFlippedNextItemStart 200ms ease-in forwards;
    }
    .my-deals__second-column .thread-data {
      animation: threadDataStart 200ms ease-in forwards;
    }
    .my-deals__first-column .prospect {
      animation: horizontalFlippedNextItemStart 200ms ease-in forwards;
    }
    .detailed-view-header__people,
    .detailed-view-header__central,
    .detailed-view-header-date {
      animation: verticalFlippedPrevItemStart 200ms ease-out forwards;
    }
  }

  &.flipped--change__branch--end {
    .my-deals__second-column .email__email-section-for-animation {
      animation: horizontalFlippedNextItemEnd 200ms ease-out forwards;
    }
    .my-deals__second-column .thread-data {
      animation: threadDataEnd 200ms ease-out forwards;
    }
    .my-deals__first-column .prospect {
      animation: horizontalFlippedNextItemEnd 200ms ease-out forwards;
    }
    .detailed-view-header__people,
    .detailed-view-header__central,
    .detailed-view-header-date {
      animation: verticalFlippedPrevItemEnd 200ms ease-out forwards;
    }
  }

  @keyframes verticalFlippedPrevItemStart {
    99% {
      transform: translateY(175%);
    }
    100% {
      visibility: hidden;
      transform: translateY(-175%);
    }
  }

  @keyframes verticalFlippedPrevItemEnd {
    0% {
      visibility: visible;
      transform: translateY(-175%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes verticalFlippedNextItemStart {
    99% {
      transform: translateY(-105%);
    }
    100% {
      visibility: hidden;
      transform: translateY(105%);
    }
  }

  @keyframes verticalFlippedNextItemEnd {
    0% {
      visibility: visible;
      transform: translateY(105%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes horizontalFlippedPrevItemStart {
    99% {
      transform: translateX(105%);
    }
    100% {
      visibility: hidden;
      transform: translateX(-105%);
    }
  }

  @keyframes horizontalFlippedPrevItemEnd {
    0% {
      visibility: visible;
      transform: translateX(-105%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes horizontalFlippedNextItemStart {
    99% {
      transform: translateX(-135%);
    }
    100% {
      visibility: hidden;
      transform: translateX(100%);
    }
  }

  @keyframes horizontalFlippedNextItemEnd {
    0% {
      visibility: visible;
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes prevMessageStart {
    99% {
      transform: translateX(101%);
    }
    100% {
      visibility: hidden;
      transform: translateX(-75%);
    }
  }

  @keyframes prevMessageEnd {
    0% {
      visibility: visible;
      transform: translateX(-75%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes nextMessageStart {
    99% {
      transform: translateX(-101%);
    }
    100% {
      visibility: hidden;
      transform: translateX(75%);
    }
  }

  @keyframes nextMessageEnd {
    0% {
      visibility: visible;
      transform: translateX(75%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes threadDataStart {
    99% {
      transform: translateX(-140%);
    }
    100% {
      visibility: hidden;
      transform: translateX(105%);
    }
  }

  @keyframes threadDataEnd {
    0% {
      visibility: visible;
      transform: translateX(105%);
    }
    100% {
      transform: translateX(0);
    }
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
  .ant-skeleton.ant-skeleton-active
    .ant-skeleton-content
    .ant-skeleton-paragraph
    > li {
    animation-duration: 1.5s;
    background: transparent;
    background-size: 100% 100%;

    &::after {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 25%,
        rgba(124, 144, 202, 0.24),
        rgba(255, 255, 255, 0) 63%
      );
      background-size: 100% 100%;
    }
  }

  .ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
    background: transparent;

    &::after {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 25%,
        rgba(124, 144, 202, 0.24),
        rgba(255, 255, 255, 0) 63%
      );
      background-size: 100% 100%;
    }
  }
}

@media screen and (min-width: ($desktop1280)) and (max-width: ($desktop2000 - 200)) {
  .my-deals {
    min-width: 600px;
  }
}
