.team-q-skeleton {
  width: 100%;

  &__cell-value {
    display: flex;
    align-items: center;
    width: 50%;
    height: 42px;
  }

  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
  .ant-skeleton.ant-skeleton-active
    .ant-skeleton-content
    .ant-skeleton-paragraph
    > li {
    animation-duration: 1.5s;
    background: linear-gradient(
      90deg,
      rgba(190, 190, 190, 0) 25%,
      #dfdfdf 37%,
      rgba(190, 190, 190, 0) 63%
    );
    background-size: 400% 100%;
    // -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
    // animation: ant-skeleton-loading 1.4s ease infinite;
  }
}
