.lv-row {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
  width: 100%;
  height: 47px;
  border: 0;
  background-color: inherit;
  border-radius: 10px;
  transition: all 0.25s;
  cursor: default;

  &.all-active {
    cursor: pointer;

    &:hover {
      .lv-row__label-text {
        color: $dark-grey-hover;
      }

      .lv-row__value-text link {
        color: $dark-grey-hover;
      }

      .lv-row__value-text-link-icon {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  &.with-fade-in {
    animation: $fadein;
  }

  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: max-content;
    width: max-content;

    &-text {
      font-size: 14px;
      line-height: 22px;
      color: $grey-hover;
      transition: color 0.2s linear;
    }
  }

  &__value {
    position: relative;
    width: max-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &:hover {
      .lv-row__value-tooltip {
        display: flex;

        &.hidden {
          display: none;
        }
      }
    }

    &-text {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: max-content;
      max-width: calc(100% - 20px);
      color: $text-high-emp;
      font-size: 14px;
      line-height: 22px;
      cursor: default;
      transition: color 0.2s linear;

      &.cuted {
        cursor: pointer;
      }

      &.active {
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: $success;
        background-color: $success-background;
      }

      &.inactive {
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: $error;
        background-color: $error-background;
      }

      &.link {
        color: $dark-grey;
        text-decoration: underline;
        padding-right: 22px;
        cursor: pointer;

        &:hover {
          color: $dark-grey-hover;

          .lv-row__value-text-link-icon {
            opacity: 1;
            cursor: pointer;
          }
        }
      }

      &-link-icon {
        position: absolute;
        right: 0%;
        top: 50%;
        transform: translate(0, -50%);
        opacity: 0.2;
        transition: opacity 0.2s linear;
      }
    }

    &-tooltip {
      position: absolute;
      display: none;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-family: 'Open Sans';
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;
      height: 25px;
      width: max-content;
      left: 50%;
      top: 0;
      transform: translate(-50%, -28px);
      border-radius: 4px;
      padding: 6px 10px 6px 10px;
      color: rgba(255, 255, 255, 0.9);
      background: $second-background;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      z-index: 15;
      animation: tooltip-enter 0.15s linear;

      &.hidden {
        display: none;
      }
    }
  }
}
