.topic {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #e3e7ed;

  &__header-row {
    position: sticky;
    top: 0;
    z-index: 9;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;
    background: #f8fafc;
    border-bottom: 1px solid #e3e7ed;

    &__cell {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px;
      gap: 4px;

      &:nth-child(1) {
        width: 30%;
        padding: 8px 8px 8px 16px;
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:nth-child(3) {
        width: 50%;
      }

      &-tooltip {
        cursor: default;
        height: 28px;
      }

      &-label {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        color: #070b12;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        cursor: default;
      }
    }
  }

  &__row-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e3e7ed;
    background: #ffffff;
    border-radius: 2px;
    transition: all 0.15s ease-in-out;
    position: relative;

    &:hover:not(.expanded):not(.is-currently-playing):not(.topic__header-row) {
      background-color: #f8fafc;
    }

    &.is-currently-playing {
      .topic__main-row {
        background: #f5f7ff;
      }

      .topic__main-row__cell:nth-child(1) {
        & > div {
          color: #537bff;
        }
      }
      .topic__main-row__cell:nth-child(2) {
        & > div {
          color: #070b12;
        }
      }
      .topic__main-row__cell:nth-child(3) {
        & > div.topic__main-row__cell__bar {
          background: #c9d4ff;
        }
      }
      .topic__main-row__cell__expand-button-icon {
        & > path {
          transition: all 0.2s linear;
          fill: #537bff;
        }
      }

      .topic__accordion {
        background: #f5f7ff;
      }
    }

    &.expanded {
      .topic__main-row {
        background: #f5f7ff;
      }

      .topic__accordion {
        height: 300px;
        opacity: 1;
        background: #f5f7ff;
        transition: height 300ms ease-in-out, padding 300ms ease-in-out,
          border-color 300ms ease-in-out;

        &-overflow {
          padding: 0;
        }
      }

      .topic__main-row__cell__expand-button-icon {
        transform: rotate(180deg);
        & > path {
          transition: all 0.2s linear;
          fill: #537bff;
        }

        &:hover {
          & > path {
            transition: all 0.2s linear;
            fill: #537bff;
          }
          background: #f1f4f8;
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__main-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;

    &__cell {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px;
      gap: 4px;
      color: #070b12;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      &-label {
        display: block;
        color: #070b12;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        cursor: default;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      &__bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        width: calc(100% - 32px);
        height: 8px;
        background: #e5ebff;
        overflow: hidden;
        gap: 1px;
        border-radius: 2px;

        &-my-side {
          height: 8px;
          background: #5243fa;
          width: 0;
        }

        &-prospect-side {
          height: 8px;
          background: #ee72a3;
          width: 0;
        }

        &-unassigned {
          height: 8px;
          background: #646F87;
          width: 0;
        }
      }

      &:nth-child(1) {
        width: 30%;
        padding: 8px 8px 8px 16px;
      }

      &:nth-child(2) {
        width: 20%;
        & > * {
          color: #646f87;
        }
      }

      &:nth-child(3) {
        width: 50%;
        justify-content: space-between;
        & > * {
          color: #95a1b6;
        }
      }

      &__expand-button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 12px;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.15s ease-in-out;
        padding: 0px;
        margin: 0 2px;

        &:hover {
          background: #f8fafc;
        }

        &.disabled {
          opacity: 0.3;
          cursor: default;

          &:hover {
            background: transparent;
          }
        }
      }

      &__expand-button-icon {
        width: 16px;
        min-width: 16px;
        height: 16px;
        transition: all 0.2s;

        &:hover {
          & > path {
            transition: all 0.2s linear;
            fill: #070b12;
          }
        }
      }

      &__expand-tooltip {
        width: auto;
        padding-bottom: 2px !important;
      }
    }
  }

  &__accordion {
    display: flex;
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: height 300ms ease-in-out, padding 300ms ease-in-out,
      border-color 300ms ease-in-out, border-width 0ms ease-in-out 300ms,
      opacity 0ms ease-in-out 300ms;

    &-overflow {
      padding: 0;
      transition: padding 300ms ease-in-out;
      display: flex;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 16px;

      &__summary {
        width: 100%;
        height: max-content;
        min-height: 76px;
        max-height: 76px;
        display: flex;
        align-items: flex-start;
        padding: 6px 0px 5px 8px;
        overflow-y: auto;
        overflow-x: hidden;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #c9d4ff;

        &-text {
          color: #646f87;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }

      &__discussed-by {
        width: 100%;
        display: flex;
        flex-direction: column;

        &-title {
          color: #070b12;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin-top: 24px;
        }

        &__timing {
          display: flex;
          flex-direction: row;
          width: 100%;
          margin-top: 16px;
          gap: 8px;

          &__cell {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
            height: 24px;

            &-icon {
              cursor: default;
            }

            &-name {
              color: #070b12;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
            }

            &-value {
              color: #646f87;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
            }
          }
        }
      }

      &__timeline {
        &-title {
          color: #070b12;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin-top: 24px;
        }

        &__bar {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
          width: calc(100% - 32px);
          height: 8px;
          background: #e5ebff;
          gap: 1px;
          border-radius: 2px;
          margin-top: 8px;

          &-background {
            width: 100%;
            height: 8px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #ffffff;
            z-index: 1;
          }

          &__section {
            position: relative;
            height: 8px;
            background: transparent;
            width: 0;
            z-index: 2;
            transition: all 0.15s linear;
            background: #537bff;

            &:hover {
              cursor: pointer;
              background: #2244b3;

              & > div {
                color: #537bff;
                text-decoration: underline;
                cursor: default;
                @include userSelectDisable;
              }
            }

            &-time {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, 60%);
              color: #95a1b6;
              // background: #f5f7ff;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              white-space: nowrap;
              transition: all 0.15s linear;
              cursor: default;
              pointer-events: none;
              @include userSelectDisable;

              &.zero {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(0%, 60%);
                z-index: 1;
              }
            }

            &__tooltip {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -150%);
              text-transform: none;
              color: $black-new-design;
              display: flex;
              flex-direction: column;
              gap: 4px;
              width: 133px;
              padding: 8px 16px;
              border-radius: 8px;
              border: 1px solid #e3e7ed;
              background-color: #ffffff;
              box-shadow: 0px 1px 2px 0px rgba(71, 107, 132, 0.3),
                0px 2px 6px 0px rgba(71, 107, 132, 0.15);
              animation: fadeInTooltip 0.4 ease-in-out;
              transition: opacity 0.3s ease-in-out;
              visibility: hidden;
              z-index: 111111;

              &.top-right {
                transform: translate(0, -150%);
              }

              &.top-left {
                transform: translate(-90%, -150%);
              }

              @keyframes fadeInTooltip {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }

              &-arrow-icon {
                position: absolute;
                filter: drop-shadow(0px 2px 1px rgba(71, 107, 132, 0.3));
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 100%);

                &--top-right {
                  position: absolute;
                  filter: drop-shadow(0px 2px 1px rgba(71, 107, 132, 0.3));
                  bottom: 0;
                  left: 50%;
                  transform: translate(-300%, 100%);
                }

                &--top-left {
                  position: absolute;
                  filter: drop-shadow(0px 2px 1px rgba(71, 107, 132, 0.3));
                  bottom: 0;
                  left: 50%;
                  transform: translate(300%, 100%);
                }
              }

              &-text {
                font-size: 12px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
