.team-members-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #ffffff;
  border: 1px solid #e3e7ed;
  border-radius: 8px;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 20px;
    border-bottom: 1px solid #e3e7ed;
  }

  &__group-title {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0 4px;
  }

  &__text {
    font-size: 14px;
    line-height: 24px;
    color: $black-new-design;
    animation: $fadein;

    &--gray-300 {
      color: $gray-300;
    }

    &--gray-400 {
      color: $gray-400;
    }

    &--blue {
      color: #537bff;
    }
  }

  &__scrollable {
    min-height: 144px;
    max-height: 296px;
    padding: 8px 16px;

    &:not(:last-child):after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #e3e7ed;
    }

    .simplebar-content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      animation: $fadein;
    }

    &--grow {
      flex-grow: 1;
    }
  }

  &__show-all-button {
    appearance: none;
    background: none;
    border: none;
    padding: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: max-content;
    cursor: pointer;

    & > svg {
      width: 16px;
      height: 16px;

      & > path {
        fill: #537bff;
      }
    }
  }

  &__add-member {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    min-height: 144px;
    max-height: 296px;

    &:not(:last-child):after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #e3e7ed;
    }

    &--grow {
      flex-grow: 1;
      animation: $fadein;
    }
  }

  &__add-member-separator {
    height: 1px;
    flex-grow: 1;
    flex-basis: 0;
    background-color: #e3e7ed;
  }

  &__add-member-button {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 4px 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: max-content;
    cursor: pointer;

    & > svg {
      width: 16px;
      height: 16px;
    }
  }
}
