.email-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px;
  height: 34px;
  width: 100%;
  background: #f5f5ff;
  border-radius: 0px 0px 6px 6px;

  &.disabled {
    pointer-events: none;
  }

  &__pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 32%;

    &-left {
      cursor: pointer;
    }

    &-right {
      cursor: pointer;
      transform: rotate(180deg);
    }

    &-pages {
      margin: 0px 8px;
      font-family: 'Open Sans';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &__right-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 32%;

    &__thread {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 225px;
      color: #777777;

      &::before {
        content: '';
        width: 1px;
        height: 16px;
        background-color: #e8ebf7;
        margin-left: -8px;
      }

      &::after {
        content: '';
        width: 1px;
        height: 16px;
        background-color: #e8ebf7;
        margin-right: 13px;
      }

      &-text {
        display: flex;
        min-width: 84px;
        font-family: 'Open Sans';
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
      }

      &__buttons {
        width: 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-button {
          cursor: pointer;
          border: none;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background-color: #fafaff;
          height: 20px;
          width: 35px;
          border-radius: 4px;
          font-family: 'Open Sans';
          font-size: 10px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: -0.01em;

          &.active {
            border: 1px solid #77a3f9;
            color: #77a3f9;
          }

          &:hover {
            background-color: #e8ebf7;
          }

          &:disabled {
            cursor: default;
            color: #ccd5f6;
            background-color: #fafaff;
          }
        }
      }
    }

    &-link {
      cursor: pointer;
      font-family: 'Open Sans';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #29335c;
      display: flex;
      flex-direction: row;
      min-width: 85px;

      &:hover {
        color: #77a3f9;
        border-bottom: 1px solid #77a3f9;
      }
    }
  }
}
