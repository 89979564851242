$widthTransition: all 0.6s ease-in-out 0.4s;

.thread-data {
  height: 200px;
  color: #4d4558;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    animation: $fadein;
    margin-bottom: 12px;

    &-title {
      font-family: 'Open Sans';
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      width: 50%;
    }

    &__duration {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      width: 50%;

      &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 3px;

        &__label {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          white-space: nowrap;
          font-family: 'Open Sans';
          font-size: 10px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: left;
          color: #4d4558;
          position: relative;
          cursor: pointer;

          &:hover {
            .thread-data__header__duration__row__label-tooltip {
              display: flex;
            }

            .thread-data__header__duration__row__label-icon {
              filter: brightness(0.6);
            }
          }

          &-icon {
            padding-left: 2px;
            transition: all 0.1s;
          }

          &-tooltip {
            display: none;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: max-content;
            height: max-content;
            max-width: 150px;
            white-space: initial;
            position: absolute;
            left: 0;
            top: 0;
            transform: translate(0px, 25px);
            color: rgba(255, 255, 255, 0.9);
            background-color: $second-background;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            padding: 6px 10px 6px 10px;
            font-family: 'Open Sans';
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            z-index: 15;

            img {
              padding-right: 6px;
            }
          }
        }

        &-value {
          white-space: nowrap;
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: right;
          color: #4d4558;
        }
      }

      &-text {
        font-family: 'Open Sans';
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.01em;
      }

      &-number {
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: -0.01em;
        margin-left: 4px;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: row;
    height: 206px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    animation: $fadein;
    border-top: 1px solid #edf0f4;

    &__left-section {
      height: 190px;
      width: 47%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 15px;

      &__user {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 35px;

        &-title {
          font-family: 'Open Sans';
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: -0.01em;
        }

        &__avatars {
          display: block;
          position: relative;
          width: 45px;
          height: 30px;

          &-one {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            transform: translate(-20px, -50%);
            z-index: 3;
          }

          &-two {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            transform: translate(-5px, -50%);
            z-index: 2;
          }

          &-plus {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: absolute;
            min-width: 17px;
            width: 17px;
            height: 17px;
            top: 0%;
            left: 50%;
            transform: translate(7px, -30%);
            z-index: 1;
            background-color: #bcc2d9;
            color: #fff;
            border-radius: 50%;
            font-family: Open Sans;
            font-size: 10px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
            cursor: pointer;
          }
        }
      }

      &__time {
        &-label {
          margin-top: 10px;
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: -0.01em;
        }
        &__body {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: 100%;

          &-line {
            min-width: 3px;
            height: 10px;
            background-color: #4eb4ff;
            margin: 12px 0px;
            border-radius: 0px 2px 2px 0px;
            transition: $widthTransition;
          }

          &-value {
            font-family: 'Open Sans';
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.01em;
            color: #4d4558;
            min-width: max-content;
            padding-left: 4px;
            text-align: center;

            &-bold {
              font-family: 'Open Sans';
              font-size: 12px;
              font-weight: 600;
              line-height: 16px;
              color: #4d4558;
              min-width: max-content;
              padding-left: 4px;
              text-align: center;
            }
          }

          &-n-a {
            font-family: 'Open Sans';
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: -0.01em;
            text-align: center;
            color: #bfbfbf;
            padding-left: 5px;
          }
        }
      }

      &__words {
        margin-top: 5px;

        &-label {
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: -0.01em;
        }

        &__body {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: 100%;

          &-line {
            min-width: 3px;
            height: 10px;
            background-color: #4eb4ff;
            margin: 12px 0px;
            border-radius: 0px 2px 2px 0px;
            transition: $widthTransition;
          }

          &-value {
            font-family: 'Open Sans';
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.01em;
            color: #4d4558;
            min-width: max-content;
            padding-left: 4px;
            text-align: center;

            &-bold {
              font-family: 'Open Sans';
              font-size: 12px;
              font-weight: 600;
              line-height: 16px;
              color: #4d4558;
              min-width: max-content;
              padding-left: 4px;
              text-align: center;
            }
          }

          &-n-a {
            font-family: 'Open Sans';
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: -0.01em;
            text-align: center;
            color: #bfbfbf;
            padding-left: 5px;
          }
        }
      }
    }

    &__divider {
      height: 180px;
      width: 1px;
      background: #e7eaff;
    }

    &__right-section {
      height: 190px;
      width: 47%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 15px;

      &__user {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 35px;

        &-title {
          font-family: 'Open Sans';
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: -0.01em;
        }

        &__avatars {
          display: block;
          position: relative;
          width: 45px;
          height: 30px;

          &-one {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            transform: translate(-20px, -50%);
            z-index: 3;
          }

          &-two {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            transform: translate(-5px, -50%);
            z-index: 2;
          }

          &-plus {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: absolute;
            min-width: 17px;
            width: 17px;
            height: 17px;
            top: 0%;
            left: 50%;
            transform: translate(7px, -30%);
            z-index: 1;
            background-color: #bcc2d9;
            color: #fff;
            border-radius: 50%;
            font-family: Open Sans;
            font-size: 10px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
            cursor: pointer;
          }
        }
      }
      &__time {
        &-label {
          margin-top: 10px;
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: -0.01em;
        }
        &__body {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: 100%;

          &-line {
            min-width: 3px;
            height: 10px;
            background-color: #365798;
            margin: 12px 0px;
            border-radius: 0px 2px 2px 0px;
            transition: $widthTransition;
          }

          &-value {
            font-family: 'Open Sans';
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.01em;
            color: #4d4558;
            min-width: max-content;
            padding-left: 4px;
            text-align: center;

            &-bold {
              font-family: 'Open Sans';
              font-size: 12px;
              font-weight: 600;
              line-height: 16px;
              color: #4d4558;
              min-width: max-content;
              padding-left: 4px;
              text-align: center;
            }
          }

          &-n-a {
            font-family: 'Open Sans';
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: -0.01em;
            text-align: center;
            color: #bfbfbf;
            padding-left: 5px;
          }
        }
      }

      &__words {
        margin-top: 5px;

        &-label {
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: -0.01em;
        }

        &__body {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: 100%;

          &-line {
            min-width: 3px;
            height: 10px;
            background-color: #365798;
            margin: 12px 0px;
            border-radius: 0px 2px 2px 0px;
            transition: $widthTransition;
          }

          &-value {
            font-family: 'Open Sans';
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.01em;
            color: #4d4558;
            min-width: max-content;
            padding-left: 4px;
            text-align: center;

            &-bold {
              font-family: 'Open Sans';
              font-size: 12px;
              font-weight: 600;
              line-height: 16px;
              color: #4d4558;
              min-width: max-content;
              padding-left: 4px;
              text-align: center;
            }
          }

          &-n-a {
            font-family: 'Open Sans';
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: -0.01em;
            color: #bfbfbf;
            padding-left: 5px;
          }
        }
      }
    }
  }

  &__tooltip {
    display: none;

    &.left-open {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: -7px;
      left: 0%;
      transform: translate(60px, 0px);
      background-color: $second-background;
      min-width: 110px;
      width: max-content;
      padding: 4px 10px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      z-index: 5;
    }

    &.right-open {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: -7px;
      left: 0%;
      transform: translate(60px, 0px);
      background-color: $second-background;
      min-width: 110px;
      width: max-content;
      padding: 4px 10px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      z-index: 5;
    }

    &__participant {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 32px;

      &-name {
        font-family: 'Open Sans';
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        text-align: center;
        color: #fff;
        margin-left: 10px;
      }

      &-avatar {
        font-size: 12px;
      }
    }
  }
}
