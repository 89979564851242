@import './component/TtppRow/ttpp-row';

.ttpp {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      display: flex;
      gap: 4px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.75;
    }

    &__legend {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 4px 0;
      font-size: 12px;
      line-height: 20px;

      &-item {
        display: flex;
        align-items: center;
        margin-left: 12px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          top: 50%;
          left: -12px;
          transform: translateY(-50%);
          background-color: $color-unassigned;
        }

        &--userSide {
          &::before {
            background-color: $color-user;
          }
        }

        &--prospectSide {
          &::before {
            background-color: $color-prospect;
          }
        }
      }
    }
  }

  &__content {
    width: 100%;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    overflow: hidden;

    &__show-more {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 16px;
      max-height: 16px;
      width: 100%;
      padding: 16px;
      gap: 4px;
      // border-bottom: 1px solid #e3e7ed;

      &__button {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        animation: $fadein;

        &-icon {
          & > path {
            transition: all 0.2s linear;
          }
        }

        &-text {
          cursor: pointer;
          overflow: hidden;
          color: var(--Information-Information, #537bff);
          text-overflow: ellipsis;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }

    &__scrollbar {
      max-height: 237px;
    }

    &__empty {
      width: 100%;
      height: 136px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #646f87;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      &-button {
        display: flex;
        height: 32px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        border: 1px solid #e3e7ed;
        color: #070b12;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        margin-top: 8px;
        // opacity: 0.5;
        transition: all 0.2s linear;
        cursor: pointer;

        &:hover {
          filter: brightness(0.7);
          color: #000000;
        }
      }
    }
  }
}
