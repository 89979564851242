.detailed-view-tooltip {
  display: inline-block;
  position: relative;
  width: 14px;
  height: 14px;
  margin-left: 3px;

  &__icon {
    cursor: pointer;
    width: 14px;
    height: 14px;
    margin-top: -1px;
  }

  &__popup {
    display: none;

    &.open {
      font-family: 'Open Sans';
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: absolute;
      height: 25px;
      width: max-content;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -150%);
      border-radius: 4px;
      padding: 6px 10px 6px 10px;
      color: rgba(255, 255, 255, 0.9);
      background: $second-background;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      z-index: 15;
    }
  }
}
