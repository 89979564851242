@import './components/Avatar/avatar';
@import './components/Tooltip/tooltip';
@import './components/Modal/modal';
@import './components/FormField/form-field';
@import './components/Form/form';
@import './components/ConfirmationModal/confirmation-modal';
@import './components/BottomNotification/bottom-notification';
@import './components/Sidebar/sidebar';
@import './components/Dropdown/dropdown';
@import './components/Button/button';
@import './components/InviteAssistantButton/invite-assistant-button';

.sound-wave-skeleton {
    &::after {
        background: linear-gradient(90deg, rgba(227, 231, 237, 0.25) 25%, rgba(227, 231, 237, 1) 37%, rgba(227, 231, 237, 0.25) 63%) !important;
    }

    &.ant-skeleton-paragraph {
        & li::after {
            background: linear-gradient(90deg, rgba(227, 231, 237, 0.25) 25%, rgba(227, 231, 237, 1) 37%, rgba(227, 231, 237, 0.25) 63%) !important;
        }

        & > li {
            height: 14px;
            border-radius: 8px;
        }

        & > li + li {
            margin-top: 4px;
        }
    }
}
