.sound-wave-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: $gray-200;
  background-color: $gray-200;
  transition: all 150ms ease-in-out;

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__text {
    color: #FFFFFF;
    font-weight: 500;
  }

  &__placeholder-icon {
    width: 66.7%;
    height: 66.7%;
  }

  &__inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
    border: 2px solid #FFFFFF;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &--no-border {
      border: none;
    }
  }

  &--xs {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-width: 1px;

    .sound-wave-avatar__inner-wrapper {
      border-width: 1px;
    }

    .sound-wave-avatar__text {
      font-size: 8px;
      line-height: 16px;
    }
  }

  &--s {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-width: 1px;

    .sound-wave-avatar__inner-wrapper {
      border-width: 1px;
    }

    .sound-wave-avatar__text {
      font-size: 10px;
      line-height: 16px;
    }
  }

  &--m {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;

    .sound-wave-avatar__text {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &--l {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;

    .sound-wave-avatar__text {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &--xl {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    border-width: 3px;

    .sound-wave-avatar__inner-wrapper {
      border-width: 3px;
    }

    .sound-wave-avatar__text {
      font-size: 16px;
      line-height: 28px;
    }
  }

  &--userSide {
    border-color: $color-user;
    background-color: $color-user;
  }

  &--prospectSide {
    border-color: $color-prospect;
    background-color: $color-prospect;
  }

  &--no-border {
    border: none;
  }

  &--square {
    border-radius: 4px;
    background-color: $gray-100;
  }
}
