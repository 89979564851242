@mixin barWrapper {
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 12px;
  min-height: 42px;
  height: 20%;
  border-bottom: 1px solid #f8f8fb;
}

@mixin barTitle {
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 60px;
  width: 30%;
  color: #414042;
  border-right: 1px solid #f8f8fb;
}

@mixin barValuesWrapper {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 4.5%;
  animation: animationIn 1.65s linear;

  &:first-child {
    border-radius: 4px 0px 0px 4px;
  }
}

@mixin barPositiveValue {
  position: relative;
  font-size: 10px;
  letter-spacing: -0.3px;
  line-height: 12px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #acacb7;

  &:hover {
    background-color: darken(#acacb7, 3);
  }

  &:first-child {
    border-radius: 4px 0px 0px 4px;
  }

  &:last-child {
    border-radius: 0px 4px 4px 0px;
  }

  &-open {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 12px);
    width: 224px;
    height: 88px;
    color: #070b12;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    z-index: 1001;
  }
}

@mixin barNeutralValue {
  position: relative;
  font-size: 10px;
  letter-spacing: -0.3px;
  line-height: 12px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #4eb4ff;

  &:hover {
    background-color: darken(#4eb4ff, 4);
  }

  &:first-child {
    border-radius: 4px 0px 0px 4px;
  }

  &:last-child {
    border-radius: 0px 4px 4px 0px;
  }

  &-open {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 12px);
    width: 224px;
    height: 88px;
    color: #070b12;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    z-index: 1001;
  }
}

@mixin barNegativeValue {
  position: relative;
  font-size: 10px;
  letter-spacing: -0.3px;
  line-height: 12px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #365798;

  &:hover {
    background-color: darken(#365798, 3);
  }

  &:first-child {
    border-radius: 4px 0px 0px 4px;
  }

  &:last-child {
    border-radius: 0px 4px 4px 0px;
  }

  &-open {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 12px);
    width: 224px;
    height: 88px;
    color: #070b12;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    z-index: 1001;
  }
}

.sent-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #4d4558;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    line-height: 12px;
    min-height: 30px;
    height: 15%;
    border-bottom: 1px solid #f8f8fb;

    &-title {
      font-size: 12px;
      line-height: 12px;
      display: flex;
      align-items: center;
      height: 100%;
      min-width: 60px;
      width: 30%;
      padding-bottom: 5px;
      border-right: 1px solid #f8f8fb;

      &-bold {
        margin-left: 3px;
        font-size: 11px;
        font-weight: 500;
        line-height: 12px;
      }
    }

    &-value {
      font-size: 12px;
      line-height: 12px;
      height: 100%;
      width: 70%;
      padding-bottom: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      &-bold {
        font-size: 11px;
        font-weight: 500;
        line-height: 12px;
        margin-left: 3px;
      }
    }
  }

  &__positive {
    @include barWrapper;

    &-title {
      @include barTitle;
    }

    &-wrap {
      @include barValuesWrapper;

      &__positive {
        @include barPositiveValue;
      }

      &__neutral {
        @include barNeutralValue;
      }

      &__negative {
        @include barNegativeValue;
      }
    }
  }
  &__neutral {
    @include barWrapper;

    &-title {
      @include barTitle;
    }

    &-wrap {
      @include barValuesWrapper;

      &__positive {
        @include barPositiveValue;
      }

      &__neutral {
        @include barNeutralValue;
      }

      &__negative {
        @include barNegativeValue;
      }
    }
  }

  &__negative {
    @include barWrapper;

    &-title {
      @include barTitle;
    }

    &-wrap {
      @include barValuesWrapper;

      &__positive {
        @include barPositiveValue;
      }

      &__neutral {
        @include barNeutralValue;
      }

      &__negative {
        @include barNegativeValue;
      }
    }
  }

  @keyframes animationIn {
    0% {
      opacity: 0;
      width: 0px;
    }
    100% {
      opacity: 1;
      width: 70%;
    }
  }

  &__bars-no-data {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__legend {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 6%;

    &-negative {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 12px;
      margin-right: 20px;

      &::before {
        content: '';
        width: 12px;
        height: 12px;
        background-color: #365798;
        border-radius: 2px;
        margin-right: 4px;
      }
    }

    &-neutral {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 12px;

      &::before {
        content: '';
        width: 12px;
        height: 12px;
        background-color: #4eb4ff;
        border-radius: 2px;
        margin-right: 4px;
      }
    }

    &-positive {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 12px;
      margin-left: 20px;

      &::before {
        content: '';
        width: 12px;
        height: 12px;
        background-color: #acacb7;
        border-radius: 2px;
        margin-right: 4px;
      }
    }
  }

  .sentiment-hidden-tooltip {
    display: none;
  }

  .sentiment-tooltip-body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 10px 15px 15px;
    height: 100%;
    width: 100%;
    border-radius: 12px;

    &-left-border {
      height: 64px;
      width: 5px;
      border-radius: 4px;
      border: 0.5px solid rgb(255, 255, 255);
      background-color: #515151;
      margin: 0px 10px 0px -4px;
    }

    &__ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      &__li {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        letter-spacing: 0.1px;
        line-height: 16px;

        &-bold {
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
        }

        &-place {
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 0.1px;
          text-decoration: underline;
        }
      }
    }
  }
}

@media screen and (max-width: ($desktop1280 - 99)) {
  .sent-wrap__header-value {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: ($desktop1440 + 10)) {
  .sent-wrap__header-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: ($desktop1280 - 100)) {
  .sent-wrap__header-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &-bold {
      margin: 0;
    }
  }
}
