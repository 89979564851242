.team-q-tendency {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 62%;
  height: 30px;
  color: #4d4558;

  &-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    animation: $fadein;

    &__icon-section {
      margin-left: 6px;
      position: relative;
      width: 12px;
      height: 12px;

      &-icon {
        position: absolute;
        width: 12px;
        height: 12px;
        cursor: pointer;
        z-index: 100;
      }

      &-tooltip {
        position: absolute;
        top: -2px;
        left: 20px;
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        padding: 8px;
        width: max-content;
        max-width: 156px;
        min-height: 20px;
        border-radius: 12px;
        color: #ffffff;
        background: $second-background;
        box-shadow: 0px 4px 10px rgba(57, 69, 119, 0.25);
        border-radius: 4px;
        z-index: 101;
        animation: tooltip-enter 0.15s linear;

        &.closed {
          display: none;
        }
      }
    }
  }

  &__bar-section {
    position: relative;
    width: 100%;
    animation: $fadein;

    &-triangle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      transition: all 2s ease-in;
      transition-delay: 0.5s;
      animation: $fadein;

      &-color {
        transition: 2s linear;
      }
    }

    &-bar {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 16px;
      width: 100%;
      border-radius: 2px;
      margin: 9px 0px;
      background-color: #edf0f4;

      &-gradient-bg {
        height: 100%;
        width: 41.5%;
        background: linear-gradient(
          90deg,
          rgba(183, 230, 204, 0) 0%,
          #b7e6cc 11.79%,
          #32b76c 34.2%,
          #32b76c 66.56%,
          #b7e6cc 89.28%,
          rgba(183, 230, 204, 0) 99.98%
        );
        border-radius: 2px;
        animation: $fadein;
        transition: color 0.5s linear;

        &.active {
          filter: brightness(0.9);
        }
      }

      @keyframes gradientAnimationIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      @keyframes gradientAnimationOut {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }
        100% {
          opacity: 0;
        }
      }

      &-grey {
        height: 100%;
        width: 41.5%;
        animation: gradientAnimationOut 1s 0s 0.3s linear;
      }

      &__separators {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 80%;
        transform: translate(-50%, -50%);

        &-section {
          width: 11.1%;
          height: 70%;
          border-right: 1px solid #cdd5e5;

          &:nth-child(4) {
            border-right: 1px solid $white;
          }

          &:nth-child(5) {
            border-right: 1px solid $white;
          }

          &:nth-child(6) {
            border-right: 1px solid $white;
          }

          &:last-child {
            border: none;
          }
        }
      }

      &__not-enough-data {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -16px);
        width: 90px;
        height: 20px;
        color: #4d4558;
        background-color: #ffffff;
        box-shadow: 0px 4px 10px rgba(57, 69, 119, 0.25);
        border-radius: 4px;
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
}
