@import './components/EventHeader/event-header';
@import './components//EventFrameHeader/event-frame-header';

.event-frame {
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-100;
  border-radius: 16px;
  background-color: #FFFFFF;

  &__content-wrapper {
    display: flex;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    height: calc(100vh - 178px);

    & > * {
      flex-basis: 50%;
      max-width: 50%;
    }
  }

  &__left {
    background-color: $gray-100;
    border-bottom-left-radius: inherit;
  }

  &__left > *, &__right > * {
    display: flex;
    flex-direction: column;
    padding: 24px;
  }

  &__right > * {
    gap: 24px;
  }

  &__left > *:not(:last-child), &__right > *:not(:last-child) {
    border-bottom: 1px solid $gray-100;
  }

  &__right-scrollbar {
    height: 100%;
  }

  &__content-title {
    font-size: 16px;
    line-height: 1.75;
    font-weight: 600;
  }

  &__content-subtitle {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
  }

  &--meeting {
    background-color: transparent;
    border: none;
    border-radius: 0;
    gap: 32px;

    & .event-frame__content-wrapper {
      gap: 24px;
      height: calc(100vh - 120px);
      
      & > * {
        border-radius: 16px;
        max-width: calc(50% - 12px);
        box-shadow: 0px 4px 16px 0px rgba(100, 111, 135, 0.12);
      }
    }

    & .event-frame__left {
      border: 1px solid #E3E7ED;
    }

    & .event-frame__right {
      background-color: #FFFFFF;
      overflow: hidden;
    }

    & .event-frame__left > *, & .event-frame__right > * {
      padding: 0;
    }

    & .event-frame__right > *:not(:last-child) {
      border-bottom: none;
    }
  }
}
