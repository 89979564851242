.my-profile {
  display: flex;
  flex-direction: column;

  &__avatar-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #e7eaff;

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: 14px;

      &-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: $dark-grey-hover;
      }

      &-role {
        text-transform: capitalize;
        background-color: $tertiary-background;
        padding: 3px 11px;
        font-size: 12px;
        font-weight: 400;
        border-radius: 4px;
        color: $text-high-emp;
      }
    }

    &__avatar {
      padding-bottom: 15px;

      &-image {
        width: 50px;
        height: 50px;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }
}
