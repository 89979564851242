.bottom-notification {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-self: center;
    text-align: center;
    max-width: 50%;
    padding: 4px 8px;
    border: 1px solid $gray-400;
    border-radius: 8px;
    background: rgba(3, 11, 26, 0.85);
    color: #FFFFFF;
    font-size: 12px;
    line-height: 20px;
    opacity: 0;
    visibility: hidden;
    z-index: 1000003;
    transition: opacity 150ms ease-in-out, visibility 0s ease-in-out 150ms;

    &--visible {
        opacity: 1;
        visibility: visible;
        transition: opacity 150ms ease-in-out;
    }

    &--success {
        padding: 8px 16px;
        border: 1px solid #10B981;
        border-radius: 4px;
        background: #F6FEFA;
        color: $black-new-design;
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
        bottom: unset;
        top: 52px;
    }
}
