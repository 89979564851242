.message {
  position: relative;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  z-index: 1;
  padding-right: 5px;
  border: 1px solid #ccd5f6;
  background-color: #fff;
  margin-right: 0.5%;

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    left: 5.3%;
    top: -2.5%;
    transform: rotate(45deg) skew(11deg, 11deg);
    border: 1px solid #ccd5f6;
    border-right: none;
    border-bottom: none;
    background: #fff;
    z-index: 0;
  }

  &__body {
    position: relative;
    min-height: 264px;
    max-height: 265px;
    padding: 22px 35px 0px 22px;
    font-family: 'Open Sans';
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: transparent;
    border-radius: 6px;
    white-space: pre-wrap;

    &::-webkit-scrollbar {
      width: 4px;

      &-thumb {
        background: #dadfff;
        border-radius: 4px;
        box-shadow: inset 0px 10px 0px 5px transparent;
      }

      &-track-piece:end {
        margin-bottom: 0px;
      }

      &-track-piece:start {
        margin-top: 20px;
      }
    }

    &::-moz-scrollbar {
      width: 4px;

      &-thumb {
        background: #dadfff;
        border-radius: 4px;
        box-shadow: inset 0px 10px 0px 5px transparent;
      }

      &-track-piece:end {
        margin-bottom: 0px;
      }

      &-track-piece:start {
        margin-top: 20px;
      }
    }
    &::-ms-scrollbar {
      width: 4px;

      &-thumb {
        background: #dadfff;
        border-radius: 4px;
        box-shadow: inset 0px 10px 0px 5px transparent;
      }

      &-track-piece:end {
        margin-bottom: 0px;
      }

      &-track-piece:start {
        margin-top: 20px;
      }
    }
  }
}
