@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 70px;

  &.detailed-deals-page {
    min-height: 10px;
  }

  &.hidden {
    display: none;
  }

  &__title {
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: space-between;
    min-width: 240px;
    margin-bottom: 0;
    cursor: default;
    color: $text-high-emp;

    .deals-detailed-view {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        color: #77a3f9;
      }
    }
  }

  &__user {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    min-width: max-content;

    &.performance-select {
      width: 50%;
    }

    &-divider {
      height: 16px;
      width: 1.5px;
      background-color: $text-high-emp;
      margin: 0 27px;
      cursor: default;
    }

    &-name {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: right;
      color: $text-high-emp;
      cursor: default;
    }

    &-avatar {
      margin: 0 12px 0 8px;
      cursor: default;
    }
  }
  @media screen and (max-width: $mobile) {
    display: flex;
    flex-direction: column;

    &__title {
      font-size: 26px;
    }

    &__user {
      display: none;
    }
  }
}
