@import './components/myMeetingsTable/my-meetings-table';
@import './components/upcomingMeetings/upcoming-meetings';
@import './components/MeetingRecordsFilters/meeting-records-filters';

.my-meetings {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      color: $black-new-design;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
    }

    &__controls {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
      cursor: default;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-direction: row;
    width: 100%;
    gap: 24px;
  }
}
