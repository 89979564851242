.select-dropdown {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1;
    transition: z-index 0s ease-in-out 300ms;

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        appearance: none;
        padding: 0;
        border: none;
        background-color: transparent;
        width: 16px;
        min-width: 16px;
        height: 16px;
        cursor: pointer;
    }

    &__button-icon {
        width: 16px;
        height: 16px;
    }

    &__content {
        visibility: hidden;
        opacity: 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: absolute;
        top: calc(100% + 8px);
        right: 0;
        padding: 12px 8px;
        width: 266px;
        border-radius: 8px;
        background-color: #FFFFFF;
        box-shadow: 0px 2px 8px 0px rgba(58, 76, 111, 0.10);
        transition: opacity 300ms ease-in-out, visibility 0s ease-in-out 300ms;
    }

    &__title {
        font-size: 12px;
        line-height: 20px;
        color: $gray-300;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &--expanded {
        z-index: 2;
        transition: none;

        & .select-dropdown__content {
            visibility: visible;
            opacity: 1;
            transition: opacity 300ms ease-in-out;
        }

        & .select-dropdown__button-icon {
            transform: rotate(180deg);
        }
    }

    &--hidden {
        & .select-dropdown__button {
            visibility: hidden;
            opacity: 0;
        }
    }
}
