.event-frame-header-title {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  min-height: 30px;
  width: 248px;

  &__label {
    display: flex;
    align-items: center;

    &--hidden {
      display: none;
    }
  }

  &__content {
    color: #070b12;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    padding: 0 4px;
    padding: 0;
    border: 1px solid transparent;
    border-radius: 4px;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;

    &--hidden {
      opacity: 0;
      visibility: hidden;
    }

    &--input {
      position: absolute;
      left: 0;
      top: 0;
      appearance: none;
      border-color: #537bff;
      background-color: #f5f7ff;
    }
  }

  &--editable {
    cursor: pointer;
  }
}
