.eng-pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  &__arrow-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: max-content;

    &__wrapper {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &:hover {
        .eng-pagination__arrow-section__wrapper-left-tooltip {
          display: flex;
        }
      }

      &-left {
        cursor: pointer;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        transition: all 0.2s;
        padding-right: 1px;

        &:hover {
          background-color: $text-low-emp;

          &.disabled {
            background-color: transparent;
          }
        }

        &-tooltip {
          display: none;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: 25px;
          width: max-content;
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(0px, 25px);
          color: rgba(255, 255, 255, 0.9);
          background-color: $second-background;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          padding: 6px 10px 6px 10px;
          font-family: 'Open Sans';
          font-size: 10px;
          font-weight: 400;
          line-height: 15px;
          z-index: 15;
        }
      }
    }

    &__wrapper {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &:hover {
        .eng-pagination__arrow-section__wrapper-right-tooltip {
          display: flex;
        }
      }

      &-right {
        cursor: pointer;
        transform: rotate(180deg);
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        transition: all 0.2s;
        padding-right: 1px;

        &:hover {
          background-color: $text-low-emp;

          &.disabled {
            background-color: transparent;
          }
        }

        &-tooltip {
          display: none;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: 25px;
          width: max-content;
          position: absolute;
          left: 0;
          top: 0;
          transform: translate(0px, 25px);
          color: rgba(255, 255, 255, 0.9);
          background-color: $second-background;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          padding: 6px 10px 6px 10px;
          font-family: 'Open Sans';
          font-size: 10px;
          font-weight: 400;
          line-height: 15px;
          z-index: 15;
        }
      }
    }

    &-pages {
      margin: 0px 14px;
      font-family: 'Open Sans';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      @include userSelectDisable;
    }
  }

  &-divider {
    height: 10px;
    width: 1px;
    background-color: #8189a1;
    margin: 0px 14px;
  }

  &__input-section {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;
      color: #8189a1;
      white-space: nowrap;
      @include userSelectDisable;
    }

    &-input {
      width: 40px;
      height: 20px;
      border: none;
      border: 1px solid $secondary-shade25;
      margin: 0 8px;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;
      color: $text-high-emp;
      @include userSelectDisable;
    }

    &-button {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;
      color: $secondary-shade25;
      cursor: default;
      transition: all 0.2s;
      @include userSelectDisable;

      &:hover {
        &:not(.disabled) {
          color: $secondary-shade50;
          cursor: pointer;
        }
      }

      &:active {
        &:not(.disabled) {
          color: $secondary-shade75;
          cursor: pointer;
        }
      }
    }
  }
}
