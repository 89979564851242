.rs-events {
  display: flex;
  flex-direction: column;
  border: 1px solid #e3e7ed;
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 14px;
  line-height: 24px;
  margin-top: 24px;
  color: $black-new-design;
  animation: $fadein;

  &__row {
    display: flex;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid #e3e7ed;
    transition: all 0.15s ease-in-out;
    cursor: pointer;

    & > .rs-events:nth-child(1) {
      width: 55%;
    }

    & > .rs-events:nth-child(2) {
      width: 15%;
    }

    & > .rs-events:nth-child(3) {
      width: 30%;
    }

    &:not(.meeting-records-list__row--header):not(.rs-events__row--header):hover {
      background: #fff;
      box-shadow: 0px 0px 4px 3px rgba(71, 107, 132, 0.08);
    }

    &--header {
      padding: 12px 0;
      color: $gray-400;
      border-bottom: 1px solid #e3e7ed;
    }
  }

  &__cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;

    &:nth-child(1) {
      width: 34%;
    }
    &:nth-child(2) {
      width: 33%;
    }
    &:nth-child(3) {
      width: 24%;
    }
    &:nth-child(4) {
      width: 5%;
    }
    &:nth-child(5) {
      margin-left: 5px;
      width: 2%;
      justify-content: flex-end;
      justify-content: center;
    }
  }

  &__button {
    appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    border: 1px solid #e3e7ed;
    border-radius: 8px;
    background-color: #ffffff;
    color: $black-new-design;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    min-height: 32px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
      background-color: #f8fafc;
      border-color: #e3e7ed;
    }

    & span {
      height: 14px;
    }
  }

  &__source-icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }

  &__title {
    display: flex;
    align-items: center;
    // gap: 8px;
    width: 100%;
    max-width: 100%;
    cursor: pointer;

    & > svg {
      margin-right: 7px;
    }

    & > div {
      white-space: nowrap;
      color: #646f87;
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-right: 3px;
    }

    & > span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 55px);
    }
  }

  &__participants {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    &-avatar {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(0%, -50%);
      @include userSelectDisable;

      > img {
        @include userDragDisable;
      }

      &:nth-child(1) {
        left: 0%;
        z-index: 1;
      }
      &:nth-child(2) {
        left: calc(0% + 18px);
        z-index: 2;
      }
      &:nth-child(3) {
        left: calc(0% + 36px);
        z-index: 3;
      }
      &:nth-child(4) {
        border: none;
        background: transparent;
        left: calc(0% + 60px);
        z-index: 4;
        color: #95a1b6;
      }
    }
  }

  &__three-dot-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 4px;
    position: relative;
    transition: background-color 0.15s linear;

    &.open,
    &:hover {
      background-color: #f1f4f8;
    }

    &-option {
      min-width: 232px;
      padding: 12px 10px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      border-radius: 0px;
      color: #070b12;
      cursor: pointer;

      & > span {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }

      &-disabled {
        color: #aaa;
        cursor: default;
      }

      &:hover {
        background-color: #f8fafc;
        transition: 0.1s ease-in-out;
      }
    }

    &-icon {
      min-width: 24px;
      min-height: 24px;
      @include userSelectDisable;
      @include userDragDisable;
    }

    &__dot {
      background-color: #646f87;
      min-width: 2px;
      min-height: 2px;
      width: 2px;
      height: 2px;
      margin: 1.5px 0;
      border-radius: 50%;

      &:hover {
        opacity: 1;
      }
    }

    &__popup {
      position: absolute;
      top: 42px;
      right: 0px;
      z-index: 1000;
      background-color: #ffffff;
      color: #191919;
      padding: 0;
      opacity: 0;
      visibility: hidden;
      z-index: 0;
      animation: none;
      transition: opacity 150ms ease-in-out, visibility 0ms ease-in-out 150ms,
        z-index 0ms ease-in-out 150ms;

      &.visible {
        padding: 8px 0;
        opacity: 1;
        z-index: 1;
        visibility: visible;
        transition: opacity 150ms ease-in-out;
      }
    }

    &__option {
      min-width: 150px;
      padding: 12px 20px;
      display: flex;
      flex-direction: row;
      border-radius: 10px;

      &-disabled {
        color: #aaa;
      }
      &:hover {
        background-color: #424242;
        transition: 0.2s;
      }
    }

    &__disabled {
      color: #aaa;
      &:hover {
        color: #aaa;
      }
    }
  }

  &__empty-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: calc(100vh - 376px);
    color: $gray-300;
  }

  &__scrollable {
    height: calc(100vh - 376px);
  }

  &__dropdown {
    padding: 8px 0;
  }

  &__dropdown-option {
    padding: 12px 16px;
  }
}
