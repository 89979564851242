.event-header-tab {
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 4px;
    border: none;
    border-radius: 4px;
    background-color: #FFFFFF;
    height: 24px;
    font-size: 12px;
    line-height: 24px;
    transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
    cursor: pointer;

    &:disabled {
        cursor: default;
    }

    &--active {
        background-color: $black-new-design;
        color: #FFFFFF;
    }
}
