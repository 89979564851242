.side-option {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 8px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: $gray-100;
    }

    &__image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        width: 28px;
        min-width: 28px;
        height: 28px;
        border-radius: 4px;
        background-color: $gray-100;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        & svg {
            width: 16px;
            height: 16px;
        }
    }

    &__label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        font-size: 12px;
        line-height: 24px;
    }

    &__selected-icon {
        width: 16px;
        min-width: 16px;
        height: 16px;
    }

    &__logo {
        width: 16px;
        height: 16px;
    }

    &--selected {
        cursor: default;
    }
}