.prospect {
  padding: 12px 6px 12px 24px;
  display: flex;
  flex-direction: column;
  min-height: 530px;
  max-height: 530px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  box-shadow: 0px 0px 4px #cbd0e9;

  &__header {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 10px;
    animation: $fadein;
  }

  &__body {
    padding-right: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    animation: $fadein;

    &::-webkit-scrollbar {
      width: 4px;

      &-thumb {
        background: #dadfff;
        border-radius: 4px;
        box-shadow: inset 0px 10px 0px 5px transparent;
      }

      &-track-piece:end {
        margin-bottom: 8px;
      }

      &-track-piece:start {
        margin-top: 8px;
      }
    }

    &::-moz-scrollbar {
      width: 4px;

      &-thumb {
        background: #dadfff;
        border-radius: 4px;
        box-shadow: inset 0px 10px 0px 5px transparent;
      }

      &-track-piece:end {
        margin-bottom: 8px;
      }

      &-track-piece:start {
        margin-top: 12px;
      }
    }

    &::-ms-scrollbar {
      width: 4px;

      &-thumb {
        background: #dadfff;
        border-radius: 4px;
        box-shadow: inset 0px 10px 0px 5px transparent;
      }

      &-track-piece:end {
        margin-bottom: 8px;
      }

      &-track-piece:start {
        margin-top: 12px;
      }
    }

    &__row {
      width: 100%;
      min-height: 28px;
      display: flex;
      flex-direction: row;
      align-items: center;

      &-label {
        width: 35%;
        font-family: 'Open Sans';
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: -0.01em;
      }

      &-value {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 65%;
        font-family: 'Open Sans';
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.01em;
        max-width: calc(66% - 5px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.no-data {
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: -0.01em;
          color: #bfbfbf;
        }

        &-company-link {
          cursor: pointer;
          margin-left: 7px;

          &:hover {
            filter: brightness(0.3);
          }
        }
      }

      &__hq {
        width: 65%;
        position: relative;
        cursor: default;

        &-text {
          display: block;
          width: 100%;
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: -0.01em;
          max-width: calc(100% - 5px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &-tooltip {
          display: none;
        }

        &:hover {
          .prospect__body__row__hq-tooltip {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, 15px);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: max-content;
            max-width: calc(100% - 15px);
            border-radius: 4px;
            padding: 5px 8px 5px 8px;
            color: rgba(255, 255, 255, 0.9);
            background: #4e4f52;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            z-index: 15;
            font-family: 'Open Sans';
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            overflow: hidden;
          }
        }
      }

      &-link-img {
        cursor: pointer;
        margin-right: 12px;
      }
    }

    &-divider {
      margin: 20px 0px 15px;
      height: 1px;
      width: 100%;
      background-color: #e8ebf7;
    }

    &__contact {
      display: flex;
      flex-direction: column;
      width: 100%;

      &-header {
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        margin-bottom: 18px;
      }

      &__data {
        display: flex;
        flex-direction: row;
        margin: 13px 0px;
        width: 100%;

        &-avatar {
          margin-right: 15px;
        }

        &-info {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          animation: $fadein;
          margin-top: -3px;
          width: 80%;

          &-name {
            cursor: default;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-family: 'Open Sans';
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: -0.01em;

            &-linkedin-img {
              cursor: pointer;
              margin-left: 12px;
            }
          }

          &-other {
            cursor: default;
            display: block;
            margin-top: 8px;
            font-family: 'Open Sans';
            font-size: 11px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: -0.01em;
            max-width: calc(100% - 10px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          &__has-tooltip {
            width: 100%;
            position: relative;
            cursor: default;

            &-item {
              display: block;
              margin-top: 8px;
              font-family: 'Open Sans';
              font-size: 11px;
              font-weight: 400;
              line-height: 15px;
              letter-spacing: -0.01em;
              max-width: calc(100% - 10px);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            &-tooltip {
              display: none;
            }

            &:hover {
              .prospect__body__contact__data-info__has-tooltip-tooltip {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0, -120%);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: max-content;
                max-width: calc(100% - 15px);
                border-radius: 4px;
                padding: 5px 8px 5px 8px;
                color: rgba(255, 255, 255, 0.9);
                background: #4e4f52;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                z-index: 15;
                font-family: 'Open Sans';
                font-size: 10px;
                font-weight: 400;
                line-height: 15px;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
  }
}
