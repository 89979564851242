.team-q-analytics {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 185px);
  color: $text-high-emp;
  background-color: $white;
  min-width: 920px;
  box-shadow: 0px 4px 8px rgba(54, 87, 152, 0.1);
  border-radius: 4px;
  overflow: hidden;

  &__navigation-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 124px;
    width: 100%;
    background: #ffffff;
    animation: $fadein;
    overflow: hidden;
    min-width: 915px;

    &__cell {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 30px 20px 15px 20px;
      width: 20%;
      height: 100%;
      border-bottom: 2px solid $tertiary-background;
      border-right: 1px solid $tertiary-background;
      cursor: pointer;
      transition: all 0.2s linear;
      color: $text-new-emp;
      @include userSelectDisable;

      &:hover {
        background-color: #ecf0f480;
        border-bottom: 2px solid #ecf0f480;
      }

      &.active {
        background-color: $tertiary-background;
        border-bottom: 2px solid $text-high-emp;
      }

      &:last-child {
        border-right: none;
      }

      &-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        white-space: nowrap;

        &.active {
          color: $text-med-emp;
        }
      }

      &__value {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        white-space: nowrap;
        animation: fadeIn 0.8s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;

        &.active {
          color: $text-high-emp;
        }

        &-bold {
          font-size: 28px;
          font-weight: 500;
          line-height: 42px;
          margin-right: 5px;
        }

        &-n-a {
          display: flex;
          align-items: center;
          height: 42px;
          font-size: 10px;
          font-weight: 400;
          line-height: 16px;
          color: $text-new-emp;
        }
      }
    }
  }

  &__chart-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 124px);
    background-color: $white;
    box-shadow: 0px 4px 8px rgba(54, 87, 152, 0.1);
    border-radius: 12px;
    overflow: hidden;
  }
}

@media screen and (min-width: ($desktop1660 + 1)) {
  .team-q-analytics__navigation-section__cell {
    &-title {
      font-size: 16px;
      line-height: 24px;
    }

    &__value {
      font-size: 12px;
      line-height: 20px;

      &-bold {
        font-size: 36px;
        line-height: 48px;
      }
    }
  }
}
