.not-installed {
  position: fixed;
  bottom: 20px;
  right: 88px;
  width: 340px;
  height: 206px;
  z-index: 100;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(36, 45, 81, 0.2);
  overflow: hidden;
  display: none;

  &.open {
    display: block;
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px 24px;
    color: #ffffff;
    background-color: #1e1e1e;

    &-close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;

      path {
        transition: all 0.1s linear;
      }

      &:hover {
        path {
          fill: #e5e5e5;
        }
      }
    }

    &-title {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
    }

    &-text {
      margin-top: 12px;
      text-align: center;
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      line-height: normal;
    }

    &-button {
      margin-top: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 10px 86px;
      border-radius: 8px;
      background-color: #ffffff;
      color: #4d4558;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      cursor: pointer;
      transition: all 0.1s linear;

      &:hover {
        background-color: darken($color: rgb(252, 252, 252), $amount: 2);
      }
    }

    &-dont-show-again {
      margin-top: 12px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      cursor: pointer;

      &:hover {
        color: darken($color: rgb(252, 252, 252), $amount: 2);
      }
    }
  }
}
