.sound-wave-select {
    position: relative;

    &__field {
        text-align: left;
        justify-content: space-between;
        cursor: pointer;

        & .sound-wave-select__text {
            color: $gray-200;
        }

        &:disabled {
            cursor: default;
        }
    }

    &__input.form-field__input {
        padding-right: 28px;
    }

    &__text {
        width: calc(100% - 20px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-flex;
        align-items: center;
        gap: 8px;

        & svg {
            width: 16px;
            min-width: 16px;
            height: 16px;
        }
    }

    &__field-icon {
        width: 16px;
        height: 16px;
    }

    &__dropdown {
        position: absolute;
        z-index: 1000;
        top: calc(100% + 8px);
        left: 0;
        width: 100%;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 8px 0px rgba(78, 114, 157, 0.12);
        opacity: 0;
        visibility: hidden;
        transition: opacity 150ms ease-in-out, visibility 0ms 150ms;
        max-height: 324px;

        & button {
            cursor: pointer;

            &:not(:disabled):hover, &:not(:disabled):focus {
                background-color: #F8FAFC;
            }

            &:disabled {
                cursor: default;
            }
        }
    }

    &__scrollable {
        height: 100%;
        max-height: 324px;

        .simplebar-content {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
    }

    &__option {
        appearance: none;
        display: inline-flex;
        align-items: center;
        text-align: left;
        gap: 8px;
        border: none;
        padding: 4px 8px;
        background-color: transparent;
        font-size: 12px;
        line-height: 20px;

        &--hidden {
            display: none;
        }
    }

    &__checked-icon {
        width: 16px;
        height: 16px;
    }

    &--autocomplete {
        & .sound-wave-select__dropdown {
            max-height: 336px;
        }
    
        & .sound-wave-select__scrollable {
            max-height: 336px;
    
            .simplebar-content {
                gap: 8px;
            }
        }

        & .sound-wave-select__field-icon {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }

    &--expanded {
        & .sound-wave-select__field-icon  {
            transform: rotate(180deg);
        }

        & .sound-wave-select__dropdown {
            opacity: 1;
            visibility: visible;
            transition: opacity 150ms ease-in-out;
        }
    }

    &--selected {
        & .sound-wave-select__field:not(:disabled) .sound-wave-select__text {
            color: $black-new-design;
        }
    }
}
