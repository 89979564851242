.wh-spinner {
  position: relative;
  min-width: 12px;
  max-width: 12px;
  min-height: 12px;
  max-height: 12px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #8b4cc9 94%, #0000) top/2px 2px
      no-repeat,
    conic-gradient(#0000 30%, #8b4cc9);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: spinner-rotation 1s infinite linear;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 8px;
    max-width: 8px;
    min-height: 8px;
    max-height: 8px;
    border-radius: 50%;
    background-color: white;
  }

  &-png {
    width: 16px;
    height: 16px;
    animation: spinner-rotation 1s infinite linear !important;
  }

  @keyframes spinner-rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
