@import './components/teamProfile/team-profile';

.org-team {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__navigation-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 44px;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    margin-bottom: 14px;

    &-cell {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 44px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #8189a1;
      border-bottom: 2px solid $tertiary-background;
      transition: all 0.2s linear;
      cursor: pointer;

      &:hover {
        background: $tertiary-background-hover;
        border-bottom: 2px solid $tertiary-background-hover;
      }

      &.active {
        color: $text-high-emp;
        background: $tertiary-background;
        border-bottom: 2px solid $text-high-emp;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: calc(100% - 58px);
    padding: 0px 8px 0px 16px;
    margin-right: 6px;
    overflow-y: auto;
    overflow-x: hidden;

    &__orgs {
      display: flex;
      flex-direction: column;
      animation: fadeIn 0.4s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;

      &__avatar {
        padding-bottom: 15px;
        cursor: default;

        &-image {
          width: 50px;
          height: 50px;
          cursor: default;
        }
      }
    }

    &__teams {
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: fadeIn 0.4s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;

      &.no-data {
        justify-content: center;
        height: 85%;
      }

      &__empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        justify-self: center;

        &-img {
          width: 55px;
          height: 55px;
          margin-bottom: 10px;
        }

        &-bold-message {
          white-space: pre-line;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: $text-high-emp;
          margin-bottom: 10px;
        }

        &__bottom-message {
          display: flex;
          flex-direction: row;
          width: max-content;

          &-text {
            display: block;
            white-space: pre-line;
            text-align: center;
            font-size: 14px;
            line-height: 22px;
            color: $text-med-emp;
          }

          &-link {
            font-size: 14px;
            line-height: 22px;
            color: $secondary-color;
            text-decoration: underline;
            cursor: pointer;
            margin-left: 4px;

            &:hover {
              color: $secondary-color-hover;
            }
          }
        }
      }
    }
  }
}
