.range-analysis-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #E3E7ED;

    &:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }

    &__title {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: #18222F;
    }
}
