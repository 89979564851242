.deals-table-tooltip {
  display: inline-block;
  position: relative;
  width: 1px;
  height: 15px;
  padding-left: 3px;
  text-transform: none;
  text-align: start;

  &__icon {
    cursor: pointer;
    width: 14px;
    height: 14px;
    margin-top: -7px;
  }

  &__popup {
    display: none;

    &.open {
      font-family: 'Open Sans';
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      white-space: initial;
      position: absolute;
      width: max-content;
      height: max-content;
      left: 50%;
      top: 20%;
      padding: 5px;
      transform: translate(-50%, -150%);
      border-radius: 4px;
      padding: 6px 10px 6px 10px;
      color: rgba(255, 255, 255, 0.9);
      background: $second-background;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      z-index: 15;
    }
  }
}
