@import './components/SpeechTrendChart/speech-trend-chart';

.meeting-speech-trend {
    display: flex;
    gap: 32px;

    &__side {
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: $gray-300;
        max-width: calc(100% / 3);
        white-space: nowrap;

        & > span {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__members {
        display: flex;
        align-items: center;

        & > * {
            margin-right: -8px;
        }
    }

    &__tooltip {
        &.meeting-participant-tooltip.sound-wave-tooltip--opened {
            & .sound-wave-avatar {
                background-color: #646F87;
                border-color: #FFFFFF;
            }
    
            & .sound-wave-avatar--prospectSide {
                background-color: #D21961;
                border-color: #FFFFFF;
            }
    
            & .sound-wave-avatar--userSide {
                background-color: #271BA4;
                border-color: #FFFFFF;
            }
        }
    }

    &__button {
        appearance: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: 2px solid transparent;
        background-color: transparent;
        border-radius: 50%;
        transition: border-color 150ms ease-in-out;
        cursor: pointer;

        &:disabled {
            cursor: default;
        }

        &:hover:not(:disabled) {
            border-color: #E3E7ED;
        }

        &--prospectSide {
            &:disabled {
                border-color: $color-prospect;

                &:hover {
                    border-color: #D21961;
                }
            }
        }

        &--userSide {
            &:disabled {
                border-color: $color-user;

                &:hover {
                    border-color: #271BA4;
                }
            }
        }

        &--unassigned {
            &:disabled {
                border-color: $color-unassigned;

                &:hover {
                    border-color: #646F87;
                }
            }
        }
    }

    &__avatar {
        border-color: #FFFFFF;
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
    }
}
