.meeting-details-loader-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 12px 16px;

    &__spinner {
        width: 32px;
        height: 32px;
        animation: spinnerAnimation 1s forwards linear infinite;
    }

    @keyframes spinnerAnimation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
