.account-subrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;

  &-vertical-line {
    display: flex;
    flex-direction: row;
    width: 2px;
    height: 50px;
    background-color: $tertiary-background-hover;

    &.last {
      align-self: flex-start;
      height: 26px;
    }
  }

  &-horisontal-line {
    width: 92%;
    height: 2px;
    background-color: $tertiary-background-hover;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translate(0px, -50%);
      background-color: $tertiary-background-hover;
    }
  }
}
