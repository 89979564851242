.input {
  position: relative;
  width: 100%;
  animation: fadeIn 0.8s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;

  &__control {
    width: 100%;
    padding: 1.3rem 2rem 1.3rem 5rem;
    border: 0;
    background-color: $main-background;
    border-radius: 8px;
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 400;

    &:focus {
      outline: 1px solid $secondary-shade25;
    }

    &.no-icon {
      padding-left: 20px;

      &:focus {
        outline: 1px solid $secondary-shade25;
      }
    }

    &.deals-rename {
      width: 100%;
      height: 30px;
      padding: 10px 20px;
      border: 0;
      outline: 1px solid $tertiary-background;
      background-color: $white;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }

    &.in-webhook {
      width: 100%;
      height: 30px;
      padding: 10px 15px;
      border: 0;
      outline: 1px solid $secondary-shade25;
      background-color: $main-background;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 30px;
    }

    &.hidden-value {
      padding: 8px 15px 0px;
      font-size: 14px;
    }

    &.white-variant {
      background-color: #ffffff;
      outline: 1px solid #cdd5e5;

      &:focus {
        outline: 1px solid #adb4c3;
      }
    }

    &:disabled {
      color: #787878;
    }

    &::placeholder {
      color: #8189a1;
    }

    &.error {
      outline: 1px solid $error;
      margin: 0px 0px 0px 0px;

      &:focus {
        outline: 1px solid $error;
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 1.7rem;

    &.in-global-header {
      top: 50%;
      left: 1.5rem;
      transform: translate(0, -50%);
    }
  }

  &__label {
    text-transform: capitalize;
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    margin: 0px 0px 8px 0px;
    color: $text-med-emp;

    &-required {
      color: $error;
    }
  }

  &__error {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(17px);
    color: $error;

    &.on-right {
      left: auto;
      right: 0;
      bottom: 0;
    }

    & + input {
      border-color: $error;
    }
  }
}
