.personalq-iframe {
  width: 233px;
  max-width: 233px;
  height: 261px;
  max-height: 261px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadein 0.5s ease;
  &__skeleton {
    animation: fadein 0.2s ease;
    &__title {
      display: flex;
      align-items: center;
    }
    &__circle {
      animation: fadein 0.5s linear;
    }
    &__message {
      display: block;
      margin-left: 15px;
    }
  }

  &__title {
    color: #29335c;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    padding: 10px 0px 15px;
    animation: fadein 0.5s ease;
  }
  &__message {
    padding-top: 15px;
    max-width: 170px;
    height: 84px;
    color: #6a6a6a;
    font-size: 13px;
    line-height: 21px;
    text-align: center;
    animation: fadein 0.5s ease;
  }
  &__link {
    display: inline-block;
    margin-left: 4px;
    text-decoration-line: underline;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    transition: all 0.2s linear;
    animation: fadein 0.7s ease;
    color: #5f80f5;
    &:hover {
      color: #29335c;
    }
  }
  .iframe-circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    background: url(./icons/circleGradient_icon.svg);
    background-size: 120px 102px;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 2;
    animation: fadein 0.5s ease;

    &__border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px solid #ffffff;
      border-radius: 50%;
      z-index: 20;
    }
    &__border__no-data {
      position: absolute;
      top: 9px;
      left: 9px;
      width: 85%;
      height: 85%;
      border: 12px solid #efefef8a;
      border-radius: 50%;
      z-index: 11;
    }
    &__border__no-data__white-border {
      position: absolute;
      top: 8.25px;
      left: 8.5px;
      width: 86%;
      height: 86%;
      border: 1.5px solid #ffffff;
      border-radius: 50%;
      z-index: 12;
      box-shadow: 0px 4px 8px #79797929;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 50%;
      height: 100%;
      z-index: 4;
      background: rgb(255, 255, 255);
    }
    &:after {
      content: '';
      position: absolute;
      top: 0%;
      right: 0%;
      width: 50%;
      height: 100%;
      z-index: 3;
      background: url(./icons/circleGradient_icon.svg);
      opacity: 1;
      background-size: 120px 102px;
      background-position: right;
      background-repeat: no-repeat;
    }

    &__conicGradMeasure {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 10;
      overflow: hidden;
      background: rgb(255, 255, 255);
    }

    &__toRotate {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 1;

      &:before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        width: 50%;
        height: 100%;
        background: #ffffff;
        z-index: 2;
      }
    }
    &__triangleToRotate {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      transition: 2.5s linear;
      background: transparent;
      z-index: 20;

      &-triangle {
        position: absolute;
        z-index: 20;
        top: 7.7%;
        left: 50%;
        transform: translate(-50.5%, -50%);
        width: 16px;
        height: 16px;
        background-size: 100%;
        background-repeat: no-repeat;
        transition: 2.5s linear;
      }
    }

    &__lines {
      z-index: 11;
      width: 97.5px;
      height: 98.5px;
      padding: 0px;
      border-radius: 50%;
    }
    &.loaded {
      &:before {
        animation: mask_left 1.25s steps(1, end) forwards;
      }
      &:after {
        animation: mask_right 1.25s steps(1, end) forwards;
      }
      .iframe-circle__toRotate {
        animation: rotate 2.5s forwards linear;
      }
    }
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    @keyframes mask_left {
      0% {
        visibility: visible;
      }
      50% {
      }
      100% {
        visibility: hidden;
      }
    }
    @keyframes mask_right {
      0% {
        visibility: hidden;
      }
      97.5% {
        visibility: visible;
      }
    }
    &__photoPlace {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      background: rgb(235, 235, 235);
      box-shadow: none;
      z-index: 20;

      &__photo {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
    }
  }
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
