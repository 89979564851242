@import './components/header/email-header';
@import './components/emailMessage/email-message';
@import './components/topics/topics';
@import './components/upperHand/upper-hand';
@import './components/replyTime/reply-time';
@import './components/assessment/assessment';
@import './components/sentiment/sentiment';
@import './components/wordCount/word-count';
@import './components/nextMove/next-move';
@import './components/footer/email-footer';
@import './components/replyEmail/reply-email';

.email {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 17px;
  min-height: 500px;
  border-radius: 8px 8px 0px 0px;
  animation: $fadein;
  cursor: default;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    padding: 15px;
    background-color: #f3f3fd;
    border-radius: 8px 8px 0px 0px;
  }

  &__body {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 430px;
    padding: 20px 18px 15px;

    &__first-column {
      width: 32%;
    }

    &__second-column {
      display: flex;
      flex-direction: column;
      margin-left: 1.5%;
      padding: 15px 1.5% 0px;
      width: 35%;
      border: 1px solid #ccd5f6;
      border-right: none;
      border-radius: 8px 0px 0px 8px;
    }

    &-divider {
      display: flex;
      align-self: center;
      height: 363px;
      width: 1px;
      background-color: #e7eaff;
    }

    &__third-column {
      display: flex;
      flex-direction: column;
      width: 32%;
      padding: 15px 1.5% 0px;
      border: 1px solid #ccd5f6;
      border-left: none;
      border-radius: 0px 8px 8px 0px;
    }
  }
}
