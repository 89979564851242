.crm {
  height: 35%;
  max-height: 333px;
  padding: 12px 24px;
  margin-top: 13px;
  background-color: #ffffff;
  color: #29335c;
  border-radius: 6px;
  box-shadow: 0px 0px 4px #cbd0e9;

  &.disabled {
    pointer-events: none;
  }

  &-background-contact {
    height: 35%;
    max-height: 333px;
    padding: 12px 24px;
    margin-top: 13px;
    background-color: #ffffff;
    box-shadow: 0px 0px 4px #cbd0e9;
    border-radius: 6px;
    color: #29335c;
    background-image: url(./assets/crm-contact-background.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0px 80%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    animation: $fadein;

    &-label {
      font-family: 'Open Sans';
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
    }

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Open Sans';
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.01em;
      height: 24px;
      width: 82px;
      border: none;
      background-color: #e8ebf7;
      box-shadow: inset 0px 1px 1px rgba(232, 235, 247, 0.8),
        inset 0px 4px 6px rgba(255, 255, 255, 0.8);
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: darken(#e8ebf7, 3);
      }

      &.active {
        background-color: #dadfff;
        box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &-input {
    position: relative;
    width: 100%;
    height: 32px;
    margin-top: 8px;
    background-color: #fafaff;
    overflow: hidden;
    animation: $fadein;

    &__control {
      display: block;
      width: 100%;
      height: 31px;
      padding-left: 10px;
      border: 0;
      color: #29335c;
      border-bottom: 0.5px solid #9898997d;
      background-color: #fafaff;
      border-radius: 0px;
      font-family: 'Open Sans';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      opacity: 0.8;
      animation: $fadein;

      &:focus,
      .crm-input {
        background-color: darken($color: #fafaff, $amount: 2);
      }
    }

    &__coming-soon {
      width: 80px;
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate(-85px, -50%);
      font-family: 'Open Sans';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.01em;
      color: #989899;
      opacity: 0.8;
    }

    &__edit-icon {
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate(-25px, -50%);
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 180px;
    animation: $fadein;

    &-message {
      display: inline;
      width: 100%;
      padding: 20px;
      border-radius: 2px;
      background-color: #f8f8fb;
      color: #29335c;
      cursor: default;
      font-family: 'Open Sans';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.01em;
      text-align: center;

      &-link {
        display: inline;
        font-family: 'Open Sans';
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.01em;
        text-align: center;
        border-bottom: 0.5px solid rgba(41, 51, 92, 0.6);
        margin-right: 4px;
        cursor: pointer;

        &:hover {
          color: #77a3f9;
          border-bottom: 1px solid #77a3f9;
        }
      }
    }
  }
}
