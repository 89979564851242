.integration-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border: 1px solid #E3E7ED;
    border-radius: 8px;
    background-color: #FFFFFF;
    text-align: center;
    font-weight: 500;
    padding: 16px 24px;

    &__icon {
        width: 32px;
        height: 32px;
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
    }

    &__title {
        font-size: 16px;
        line-height: 1.75;
    }

    &__text {
        font-size: 14px;
        line-height: 24 / 14;

        &--gray {
            color: $gray-300;
        }
    }

    &__status {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 4px;
        border-radius: 4px;
        background-color: #FFFCF5;
        padding: 8px;
        font-size: 10px;
        line-height: 1.6;
        color: #AD6E00;
        

        & svg {
            width: 16px;
            height: 16px;

            & path {
                fill: #AD6E00;
            }
        }

        &--error {
            background-color: #FFF5F6;
            color: #F0384A;

            & svg path {
                fill: #F0384A;
            }
        }
    }

    &__tooltip-container {
        width: 100%;
        margin-top: auto;
    }

    &__tooltip {
        width: auto;
    }

    &__button-icon {
        path {
            transition: fill 150ms ease-in-out;
        }
    }

    &__button {
        margin-top: auto;
        width: 100%;
        position: relative;

        &--loading {
            & > span {
                visibility: hidden;
            }
        }

        &:disabled {
            .integration-card__button-icon path {
                fill: $gray-200;
            }
        }
    }

    &__button-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 16px;
        height: 16px;
        animation: spinAnimation 1s linear infinite;

        &--primary {
            & path:nth-child(1) {
                fill: #F5F7FF;
            }
    
            & path:nth-child(2) {
                fill: #271BA4;
            }
        }
    }

    &__placeholder-icon {
        width: 24px;
        height: 24px;

        path {
            fill: $gray-200;
        }
    }

    &--placeholder {
        background-color: #F8FAFC;
        justify-content: center;
        min-height: 230px;
    }

    &--animated {
        animation: integrationCardAnimation 2000ms ease-in-out forwards;
    }

    @keyframes integrationCardAnimation {
        0% {
            background-color: #FFF3D6;
        }
        100% {
            background-color: #FFFFFF;
        }
    }
}
