.transcription-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  padding: 12px 16px;
  height: 48px;
  gap: 16px;
  box-shadow: 0px 4px 16px 0px rgba(85, 106, 142, 0.12);
  border-top: 1px solid $gray-100;
  border-bottom: 1px solid $gray-100;
  background: #ffffff;
  color: #070b12;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  &__left-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: max-content;

    &-title {
      color: #070b12;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__right-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 60%;
    gap: 16px;

    &-title {
      color: #070b12;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #e3e7ed;
  }

  &__cancel-search {
    min-width: 43px;
    width: 43px;
    cursor: pointer;
    overflow: hidden;
    color: #18222f;
    text-overflow: ellipsis;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  &__text {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  &__time {
    width: 30px;
  }

  &--active {
    color: #ffffff;
    background-color: rgba(149, 161, 182, 0.3);
    border-color: transparent;
    box-shadow: 0px 4px 16px 0px rgba(85, 106, 142, 0.12);

    & svg path {
      fill: #ffffff;
    }
  }

  &__searching-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 248px;
    background-color: #fff;
    height: 32px;
    gap: 16px;
    background: #ffffff;
    color: #070b12;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    &-input {
      & > input.input__control {
        font-family: inherit;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;

        &::placeholder {
          color: #646f87;
        }
      }

      & > input.input__control:focus {
        outline-color: #537bff;
      }

      & > input {
        padding: 8px 36px;
        border-radius: 8px;
        background-color: transparent;
        outline: 1px solid #e3e7ed;
        background-color: #ffffff;
        height: 32px;

        &:not(.error).no-icon.input__control {
          &:focus {
            outline: 1px solid #537bff;
          }
        }
      }

      & > .input__icon {
        left: 12px;
      }
    }

    &__found-items {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      height: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
      z-index: 9;
      transition: opacity 0.2s;
      animation: $fadein;

      &-text {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #646f87;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        cursor: default;
        @include userSelectDisable;
      }

      &-chevron {
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
          cursor: default;
        }
      }
    }

    &-clear-icon {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
      cursor: pointer;
      z-index: 9;
      transition: opacity 0.2s;
      animation: $fadein;

      & path {
        transition: fill 150ms ease-in-out;
      }

      &:hover {
        & path {
          fill: #070b12;
        }
      }
    }

    &-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
      animation: spinAnimation 1s linear infinite;

      @keyframes spinAnimation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}
