@import './components/SelectDropdown/select-dropdown';
@import './components/SideOption/side-option';

.range-analysis {
    display: flex;
    align-items: center;
    gap: 8px;

    &__content {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 24px;
        flex-grow: 1;
        gap: 4px;
    }

    &__title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__left {
        display: flex;
        align-items: center;
    }

    &__title {
        display: inline;
        margin-right: 8px;

        &--with-tooltip {
            cursor: pointer;
            transition: color 150ms ease-in-out;

            &:hover {
                color: $gray-400;
            }
        }
    }

    &__tooltip {
        width: auto;
        max-width: 350px;
    }

    &__value {
        color: $gray-300;
    }

    &__range-wrapper {
        padding: 4px 0;
    }

    &__range {
        height: 4px;
        min-width: 4px;
        border-radius: 4px;
        background-color: #E3E7ED;

        &--userSide {
            transition: width 300ms ease-in-out 300ms;
            background-color: $color-user;
        }

        &--prospectSide {
            transition: width 300ms ease-in-out 300ms;
            background-color: $color-prospect;
        }

        &--unassigned {
            transition: width 300ms ease-in-out 300ms;
            background-color: $color-unassigned;
        }
    }
}
