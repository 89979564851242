@import './pagination/detailed-pagination';

.detailed-view-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 36px;
  padding: 0px 10px;
  color: #414042;
  background-color: $white;
  box-shadow: 0px 0px 4px #cbd0e9;
  border-radius: 6px;
  animation: $fadein;
  margin-bottom: 13px;

  &__left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 28%;
    max-width: 28%;

    &__subject {
      width: max-content;
      max-width: calc(100% - 10px);
      position: relative;
      height: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;

      &-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 15px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin: 0px 2px 0px 9px;
        color: $text-high-emp;
      }

      &-value {
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        max-width: calc(100% - 10px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        color: $text-high-emp;
      }

      &-toltip {
        display: none;

        &.open {
          font-family: 'Open Sans';
          font-size: 10px;
          font-weight: 400;
          line-height: 15px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          position: absolute;
          height: 25px;
          width: max-content;
          left: 33px;
          top: 50%;
          transform: translate(0px, -160%);
          border-radius: 4px;
          padding: 6px 10px 6px 10px;
          color: rgba(255, 255, 255, 0.9);
          background-color: $second-background;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          z-index: 15;
        }
      }
    }
  }

  &__central {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40%;
    max-width: 40%;
    cursor: default;

    &__people {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: max-content;
      max-width: 50%;
      height: 16px;
      position: relative;
      margin-right: 12px;
      transition: all 0.2s;

      &:hover {
        &::before {
          filter: brightness(0.8);
        }
      }

      &::before {
        content: '';
        display: flex;
        align-self: center;
        justify-self: center;
        min-width: 18px;
        width: 18px;
        height: 100%;
        background-color: #fff;
        background-image: url(./assets/people-circle.svg);
        background-size: auto;
        background-position: center;
        color: #fff;
        // position: absolute;
        // top: 50%;
        // transform: translate(-23px, -50%);
        margin-right: 5px;
        border-radius: 50%;
        transition: all 0.2s;
      }

      &__tooltip {
        display: none;

        &.open {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 24px;
          left: 0;
          background-color: $second-background;
          color: white;
          min-width: 90px;
          width: max-content;
          padding: 4px 10px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          z-index: 100;
        }

        &__participant {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 32px;

          &-name {
            font-family: 'Open Sans';
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            text-align: center;
            color: #fff;
            margin-left: 10px;
          }

          &-avatar {
            font-size: 12px;
          }
        }
      }

      &-name {
        height: 20px;
        max-height: 20px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
        width: max-content;
        max-width: calc(100% - 10px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }
    }

    &__last-active {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: max-content;
      min-width: 20px;
      max-width: 40%;
      height: 16px;
      font-size: 14px;
      font-weight: 400;
      margin-right: 5px;
      position: relative;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        .detailed-view-header__central__last-active-tooltip {
          display: flex;
        }

        &::before {
          filter: brightness(0.8);
        }
      }

      &::before {
        content: '';
        display: flex;
        align-self: center;
        justify-self: center;
        min-width: 18px;
        width: 18px;
        height: 100%;
        background-color: #fff;
        background-image: url(./assets/pulse-circle.svg);
        background-size: auto;
        background-position: center;
        color: #fff;
        // position: absolute;
        // top: 50%;
        // transform: translate(-23px, -50%);
        border-radius: 50%;
        margin-right: 5px;
        transition: all 0.2s;
      }

      &-tooltip {
        display: none;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 25px;
        width: max-content;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(0px, 25px);
        color: rgba(255, 255, 255, 0.9);
        background-color: $second-background;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        padding: 6px 10px 6px 10px;
        font-family: 'Open Sans';
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        z-index: 15;
      }

      &-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 15px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        min-width: 135px;
      }

      &-value {
        display: block;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        max-width: calc(100% - 5px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &__message-qty {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 16px;
      width: max-content;
      font-size: 12px;
      font-weight: 400;
      position: relative;
      cursor: pointer;

      &:hover {
        .detailed-view-header__central__message-qty-tooltip {
          display: flex;
        }

        &::before {
          filter: brightness(0.8);
        }
      }

      &::before {
        content: '';
        display: flex;
        align-self: center;
        justify-self: center;
        min-width: 18px;
        width: 18px;
        height: 100%;
        background-color: #fff;
        background-image: url(./assets/arrows-circle.svg);
        background-size: auto;
        background-position: center;
        color: #fff;
        // position: absolute;
        // top: 50%;
        // transform: translate(-23px, -50%);
        border-radius: 50%;
        margin-right: 5px;
        transition: all 0.2s;
      }

      &-tooltip {
        display: none;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 25px;
        width: max-content;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(0px, 25px);
        color: rgba(255, 255, 255, 0.9);
        background-color: $second-background;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        padding: 6px 10px 6px 10px;
        font-family: 'Open Sans';
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        z-index: 15;
      }
    }

    &-date {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: max-content;
      max-width: 32%;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      padding-left: 20px;
      letter-spacing: -0.1px;
      white-space: nowrap;

      &::before {
        content: '';
        width: 1px;
        height: 16px;
        background-color: #ccd5f6;
        margin-right: 20px;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 28%;
    max-width: 28%;
  }
}
