.email-header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  padding: 15px 24px;
  background-color: #f3f3fd;
  box-shadow: 0px -2px 4px #cbd0e9;
  border-radius: 6px 6px 0px 0px;
  border-bottom: 1px solid #ccd5f6;
  color: #4d4558;

  &-reply-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0%, -50%);
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: default;
    background-color: #666469;
    background-color: #4d4558;
    color: #ffffff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600; // TODO: change if needed
    line-height: 20px;
    transition: all 0.1s ease-in;
    cursor: pointer;
    animation: scaleInReply 0.1s linear;

    &:hover {
      background-color: #3c3744;
    }

    &.close {
      background-color: #666469;
      line-height: 15px;
      animation: scaleInX 0.1s linear;

      &:hover {
        background-color: #3c3744;
        color: #ffffff;
      }
    }

    @keyframes scaleInReply {
      0% {
        opacity: 0;
        transform: translate(0%, -50%) scale(0.5);
      }
      100% {
        opacity: 1;
        transform: translate(0%, -50%) scale(1);
      }
    }
    @keyframes scaleInX {
      0% {
        opacity: 0;
        transform: translate(0%, -50%) scale(0.4);
      }
      100% {
        opacity: 1;
        transform: translate(0%, -50%) scale(1);
      }
    }
  }

  &__img-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 10%;

    &-svg {
      cursor: default;
    }
  }

  &__left-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 80%;
    max-width: 80%;
    // padding-left: 20%;

    &__from {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: max-content;
      height: 16px;

      &-title {
        margin: 0px 5px 0px 12px;
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
      }

      &-value {
        position: relative;
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;

        &__tooltip {
          display: none;

          &.open {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 24px;
            background-color: $second-background;
            min-width: 90px;
            width: max-content;
            padding: 4px 10px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            z-index: 100;
          }

          &__participant {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 32px;

            &-name {
              font-family: 'Open Sans';
              font-size: 10px;
              font-weight: 400;
              line-height: 15px;
              text-align: center;
              color: #fff;
              margin-left: 10px;
            }

            &-avatar {
              font-size: 12px;
            }
          }
        }
      }
    }

    &__central {
      display: flex;
      flex-direction: row;
      align-items: center;
      // justify-content: center;
      width: max-content;
      max-width: 70%;
      height: 16px;
      min-width: 60px;
      height: 16px;

      &-title {
        height: 16px;
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;

        &-to {
          font-family: 'Open Sans';
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          margin: 0px 5px 0px 25px;

          &-value {
            display: flex;
            position: relative;
            cursor: pointer;
            min-width: 100px;
            max-width: 45%;

            &-text {
              font-family: 'Open Sans';
              font-size: 14px;
              font-weight: 400;
              line-height: 19px;
              width: max-content;
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              display: block;
            }

            &-circle {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              background-color: #bcc2d9;
              color: #fff;
              min-width: 19px;
              width: 19px;
              height: 19px;
              border-radius: 50%;
              font-family: 'Open Sans';
              font-size: 10px;
              font-weight: 600;
              line-height: 15px;
              letter-spacing: 0em;
              text-align: center;
              margin-left: 5px;
            }

            &__tooltip {
              display: none;

              &.open {
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 24px;
                left: 0%;
                transform: translate(0%, 0%);
                background-color: $second-background;
                min-width: 110px;
                width: max-content;
                padding: 4px 10px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 4px;
                z-index: 5;
              }

              &__participant {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 32px;

                &-name {
                  font-family: 'Open Sans';
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 15px;
                  text-align: center;
                  color: #fff;
                  margin-left: 10px;
                }

                &-avatar {
                  font-size: 12px;
                }
              }
            }
          }
        }

        &-cc {
          font-family: 'Open Sans';
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          margin: 0px 5px 0px 25px;

          &-value {
            display: flex;
            position: relative;
            cursor: pointer;
            max-width: 45%;

            &-text {
              font-family: 'Open Sans';
              font-size: 14px;
              font-weight: 400;
              line-height: 19px;
              width: max-content;
              max-width: calc(100% - 15px);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              display: block;
            }

            &-circle {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              background-color: #bcc2d9;
              color: #fff;
              min-width: 19px;
              width: 19px;
              height: 19px;
              border-radius: 50%;
              font-family: 'Open Sans';
              font-size: 10px;
              font-weight: 600;
              line-height: 15px;
              letter-spacing: 0em;
              text-align: center;
              margin-left: 5px;
            }

            &__tooltip {
              display: none;

              &.open {
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 24px;
                background-color: $second-background;
                min-width: 90px;
                padding: 4px 10px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 4px;
              }

              &__participant {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 32px;

                &-name {
                  font-family: 'Open Sans';
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 15px;
                  text-align: center;
                  color: #fff;
                  margin-left: 10px;
                }

                &-avatar {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  &-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    min-width: max-content;
    width: 10%;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding-left: 20px;
  }
}
