.upper-hand-feedback {
  float: right;
  position: relative;

  &.disabled {
    pointer-events: none;
  }

  &__icon {
    width: 14px;
    height: 14px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 300ms ease-out;
    transform: translateY(2.5px);

    & ~ .detailed-view-tooltip__popup {
      left: -48px !important;
      display: none !important;
    }

    &:hover {
      border-color: $secondary-color;

      img {
        filter: brightness(0) saturate(100%) invert(29%) sepia(77%)
          saturate(539%) hue-rotate(181deg) brightness(91%) contrast(86%);
      }

      & ~ .detailed-view-tooltip__popup {
        display: flex !important;
      }
    }

    &.active {
      background: $secondary-color;
      border-color: $secondary-color;

      & ~ .detailed-view-tooltip__popup {
        display: none !important;
      }

      img {
        filter: brightness(0) saturate(100%) invert(96%) sepia(0%)
          saturate(7500%) hue-rotate(114deg) brightness(103%) contrast(108%);
      }
    }
  }

  &__menu {
    width: 228px;
    height: 130px;
    background: $white;
    border: 1px solid $text-low-emp;
    box-shadow: 0px 4px 10px rgba(57, 69, 119, 0.25);
    border-radius: 4px;
    position: absolute;
    bottom: 19px;
    right: 0;
    z-index: 1;

    &__vote {
      &__header {
        padding: 8px 16px;
        border-bottom: 1px solid $tertiary-background;
        font-size: 12px;
        line-height: 18px;
        color: $text-high-emp;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__close {
          color: $secondary-shade50;
          width: 8px;
          height: 8px;
          cursor: pointer;

          &:hover {
            color: $text-high-emp;
          }
        }
      }

      &__option {
        padding: 8px 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 300ms ease-out;

        &__icon {
          margin-left: 2px;
          color: $secondary-shade25;
          width: 12px;
          height: 14px;
          transition: 300ms ease-out;
        }

        &__text {
          margin-left: 10px;
          font-size: 11px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: $text-high-emp;
          transition: 300ms ease-out;
        }

        &.link {
          cursor: default;
          font-weight: 400;
        }

        &__link {
          font-size: 10px;
          line-height: 16px;
          text-decoration: underline !important;
          color: $dark-grey;
          margin-left: auto;

          &:hover {
            color: $dark-grey-hover;
          }
        }

        &.active {
          cursor: default;
          .upper-hand-feedback__menu__vote__option__icon {
            color: $secondary-color;
          }
        }

        &.disabled {
          .upper-hand-feedback__menu__vote__option__text {
            color: $secondary-shade25;
          }
        }

        &:not(.active):not(.link):hover {
          background: $main-background;

          .upper-hand-feedback__menu__vote__option__text {
            color: $text-high-emp !important;
          }

          .upper-hand-feedback__menu__vote__option__icon {
            color: $secondary-color;
          }
        }
      }
    }

    &__submitted {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__img {
        margin-bottom: 8px;
      }

      &__text {
        font-family: 'Open-sans', sans-serif;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: $green-primary;
      }
    }
  }
}
