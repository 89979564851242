.edit-mode-toolbar {
    position: fixed;
    bottom: 20px;
    right: 32px;
    padding: 16px;
    border-radius: 16px;
    background-color: #18222F;
    display: flex;
    align-items: center;
    gap: 8px;
    opacity: 0;
    visibility: hidden;
    width: calc(100vw - 136px);
    transition: opacity 150ms ease-in-out, visibility 0s 150ms;

    &__unselect-members-button {
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #FFFFFF;
        background-color: transparent;
        border: none;
        padding: 7px 9px 7px 0;
        cursor: pointer;

        &:disabled {
            color: #FFFFFF;
            cursor: default;
        }
    
        &:hover:not(:disabled) {
            background-color: transparent;
        }
          
        &:active:not(:disabled) {
            background-color: transparent;
        }
    }

    &__discard-button {
        margin-left: auto;
    }

    &__button {
        border-color: #E3E7ED;

        &:disabled {
            color: $gray-400;
            border-color: $gray-400;
            background-color: #18222F;

            .edit-mode-toolbar__activate-icon {
                & > path {
                    fill: #047857;
                }
            }

            .edit-mode-toolbar__deactivate-icon {
                & > path {
                    fill: #B40D1E;
                }
            }

            .edit-mode-toolbar__assign-icon {
                & > path:first-child {
                    fill: $gray-400;
                }

                & > path:last-child {
                    fill: $gray-200;
                }
            }
        }

        &:hover:not(:disabled) {
            background-color: $gray-300;
            border-color: #E3E7ED;
        }
      
        &:active:not(:disabled) {
            background-color: $gray-400;
            border-color: #E3E7ED;
        }
    }

    &__activate-icon,
    &__deactivate-icon,
    &__assign-icon {
        & > path {
            transition: fill 150ms ease-in-out;
        }
    }

    &__assign-option {
        &:disabled {
            color: $gray-200;
        }
    }

    &__assign-dropdown {
        width: auto;
        max-width: 300px;
    }

    &__apply-changes-tooltip {
        .sound-wave-tooltip__content {
            padding: 16px;
            border-color: #FFFFFF;
            background-color: #FFFFFF;
            box-shadow: 0px 2px 8px 0px rgba(83, 109, 141, 0.28);
        }
    }

    &--visible {
        opacity: 1;
        visibility: visible;
        transition: opacity 150ms ease-in-out;
    }
}
