.relations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 110px;
  @include userSelectDisable;
  user-select: none;

  &__title {
    display: flex;
    align-self: flex-start;
    color: #777679;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
  }

  &__avatarsblock {
    min-height: 60px;
    max-height: 60px;
    width: 230px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: nameAnimation 0.2s linear;

    &-arrows {
      cursor: pointer;
      background-color: #e7eaff;
      border-radius: 50%;

      &__empty {
        height: 16px;
        width: 16px;
        background-color: #e7eaff;
        border-radius: 50%;
      }
    }

    &-img1 {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 110%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 30px;
      width: 30px;
      border-radius: 30px;
      z-index: 1;
      transition: 0.35s cubic-bezier(0.1, -0.1, 0.2, 0.9);
      background-color: #e7eaff;
      box-shadow: 0px 2px 4px rgba(10, 8, 56, 0.03),
        0px -2px 4px rgba(10, 8, 56, 0.03);
    }

    &-img2 {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 80%;
      top: 50%;
      left: 59%;
      transform: translate(-50%, -50%);
      height: 22px;
      width: 22px;
      border-radius: 30px;
      transition: 0.35s cubic-bezier(0.1, -0.1, 0.2, 0.9);
      background-color: #e7eaff;
      box-shadow: 0px 2px 4px rgba(10, 8, 56, 0.03),
        0px -2px 4px rgba(10, 8, 56, 0.03);
    }

    &-img3 {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 80%;
      top: 50%;
      left: 41%;
      transform: translate(-50%, -50%);
      height: 22px;
      width: 22px;
      border-radius: 30px;
      transition: 0.75s cubic-bezier(0.1, -0.1, 0.2, 0.9);
      background-color: #e7eaff;
      box-shadow: 0px 2px 4px rgba(10, 8, 56, 0.03),
        0px -2px 4px rgba(10, 8, 56, 0.03);
    }
  }
  &__name {
    font-size: 17px;
    line-height: 10px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
    animation: nameAnimation 1s linear;
  }
  &__name-empty-block {
    height: 12px;
    width: 100%;
    display: flex;
    margin-bottom: 18px;
    justify-content: center;
    &__empty-el {
      height: 100%;
      width: 58px;
      border-radius: 30px;
      background-color: #e7eaff;
    }
  }
  @keyframes nameAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  &__skeleton-block {
    position: relative;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition-duration: 0;
    margin-top: -6px;

    &__img-block {
      position: relative;
      margin-top: 2px;
      height: 35px;
      width: 230px;
    }
  }
}
.ant-skeleton-content .ant-skeleton-title {
  margin-top: 7px;
}
