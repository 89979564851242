.filter-range {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__range-wrapper {
        padding: 8px;
    }

    &__range {
        position: relative;
        user-select: none;
        width: 100%;
        height: 8px;
        border-radius: 4px;
        border: 1px solid #E3E7ED;
        background-color: #F5F7FF;
    }

    &__range-button {
        appearance: none;
        outline: none;
        width: 16px;
        height: 16px;
        border: 1px solid #E3E7ED;
        border-radius: 50%;
        background-color: #537BFF;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }

    &__range-segment {
        position: absolute;
        top: -1px;
        height: 8px;
        background-color: #537BFF;
    }

    &__inputs-wrapper {
        display: flex;
        gap: 4px;
        align-items: center;
        font-size: 12px;
        line-height: 20px;
        color: $black-new-design;
    }

    &__input {
        appearance: none;
        display: inline-flex;
        align-items: center;
        text-align: center;
        background-color: #FFFFFF;
        border: 1px solid #537BFF;
        border-radius: 4px;
        padding: 8px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        width: calc(50% - 7px);
        color: $black-new-design;
    }
}
