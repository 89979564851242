@import './components/DatePickerInput/date-picker-input';
@import './components/DatePickerHeader/date-picker-header';

.date-picker {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__inputs-wrapper {
        display: flex;
        gap: 4px;

        & > * {
            flex: 1;
        }
    }

    &__wrapper {
        display: flex;

        & .react-datepicker {
            font-family: 'Poppins', sans-serif;
            padding: 8px;
            border-radius: 8px;
            border: none;
            width: 100%;
        }

        & .react-datepicker__header {
            background-color: #FFFFFF;
            padding: 0;
            border-bottom: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
        }

        & .react-datepicker__month-container {
            width: 100%;
        }

        & .react-datepicker__day-names {
            margin-bottom: 0;
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #E3E7ED;
        }

        & .react-datepicker__day-name, 
        & .react-datepicker__day {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            min-height: 24px;
            height: 24px;
            margin: 0;
            font-size: 12px;
            line-height: 16px;
            color: $black-new-design;
        }

        & .react-datepicker__day-name {
            font-weight: 500;
            color: $gray-300;
        }

        & .react-datepicker__month {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            padding: 8px;
            margin: 0;
        }

        & .react-datepicker__week {
            display: flex;
            gap: 8px;
            align-items: center;
        }

        & .react-datepicker__day {
            border-radius: 8px;
            position: relative;

            &:hover {
                border-radius: 8px;
                background-color: #FFFCF5 !important;
            }

            &--outside-month {
                color: $gray-200;
            }

            &--in-selecting-range {
                background-color: transparent !important;
            }

            &--in-range {
                z-index: 0;
                border-radius: 0;
                background-color: #FFFCF5 !important;

                &::before, &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 8px;
                    background-color: #FFFCF5;
                }

                &::before {
                    left: -8px;
                }

                &::after {
                    right: -8px;
                }

                &:first-child {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;

                    &::before {&--in-selecting-range {
                        background-color: transparent !important;
                    }
                        display: none;
                    }
                }
            }

            &--keyboard-selected,
            &--selected,
            &--range-start,
            &--range-end {
                border-radius: 8px;
                z-index: 1;
                background-color: $yellow-new-design !important;
                color: #FFFFFF !important;

                &:hover {
                    background-color: $yellow-new-design !important;
                }
                
                &::before {
                    display: none;
                }

                &::after {
                    display: none;
                }
            }
        }
    }

    &__buttons-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__button {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        appearance: none;
        background-color: transparent;
        border: none;
        padding: 8px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: $black-new-design;
        cursor: pointer;
    }

    &__button-icon {
        width: 12px;
        height: 12px;
    }
}
