.assessment {
  display: flex;
  flex-direction: column;
  color: #29335c;
  margin-top: 15px;

  &__header {
    padding-left: 3px;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 8px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 16.3px;

    &-message {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 4px;
      font-family: 'Open Sans';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;

      &::before {
        content: '';
        width: 8px;
        height: 8px;
        background: #ccd5f6;
        box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        margin-left: 3px;
        margin-right: 12px;
      }
    }
  }
}
