.popup {
  background-color: $second-background;
  color: #fff;
  transition: all 0.2s;
  z-index: 1000;
  padding: 5px 0;
  min-height: 50px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(36, 45, 81, 0.2);

  &__option {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    padding: 10px 22px;
    cursor: pointer;
    min-width: max-content;
    width: 100%;

    &:hover {
      background-color: darken($second-background, 5);
      transition: 0.2s;
      cursor: pointer;
    }

    &.sidebar {
      border-radius: 0px;
      background-color: $white;
      transition: 0.1s;
      padding: 12px 22px;

      &:hover {
        background-color: $tertiary-background;
      }
    }

    &.bottom-border {
      border-bottom: 1px solid $tertiary-background;
    }

    &-icon {
      margin-right: 10px;
      display: flex;
      align-items: center;

      &:last-child {
        fill: $error;
      }
    }

    &.red {
      color: #c32c2c;
    }

    &-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;

      &.in-sidebar {
        font-size: 14px;
      }
    }

    &-disabled {
      background-color: $second-background;
      color: #aaa;
      @include userSelectDisable;
      pointer-events: none;

      &:hover {
        color: #aaa;
        background-color: $second-background !important;
      }

      &.sidebar {
        background-color: $white;
        color: $text-low-emp;

        &:hover {
          background-color: $white !important;
          color: $text-low-emp;
          @include userSelectDisable;
          cursor: default;
        }
      }
    }
  }

  &.closed {
    height: 0;
    width: 0;
    padding: 0;
    z-index: -1;
    transition: all 0.2s;

    & > div {
      display: none;
    }
  }

  &.open {
    display: block;
    animation: fadeIn 0.2s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;
  }
}
