.relationship-filter {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: #FFFFFF;

    &__button {
        appearance: none;
        display: inline-flex;
        align-items: center;
        gap: 8px;
        font-size: 12px;
        line-height: 20px;
        border: none;
        padding: 4px 8px;
        background-color: transparent;
        color: $black-new-design;
        cursor: pointer;

        &:disabled {
            color: $gray-200;
            cursor: default;
        }
    }

    &__checkbox-icon {
        width: 20px;
        height: 20px;
    }

    &__badge {
        display: inline-flex;
        align-items: center;
        padding: 4px;
        border-radius: 4px;
        background-color: #F8FAFC;
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
        color: $gray-300;
    }

    &__content {
        display: none;
        padding: 8px;
    }

    &--active {
        background-color: #F8FAFC;

        & .relationship-filter__content {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }
}