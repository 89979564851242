.graph-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 15px 0;
  color: $text-med-emp;
  transition: all 0.4s cubic-bezier(0.27, 0.1, 0.75, 1) 0s;

  &:first-child {
    margin: 10px 0 15px;
  }

  &__name-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 18%;
    overflow: hidden;
    transition: all 0.4s ease-in-out;

    &.s-z {
      height: 100%;
      align-items: flex-start;
    }

    &-value {
      width: max-content;
      max-width: calc(100% - 12px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      font-weight: 300;
      line-height: 16px;
      cursor: default;
    }
  }

  &__bar-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.4s linear;
    width: 62%;
    overflow: hidden;

    &__bar {
      position: relative;
      display: flex;
      align-items: center;
      height: 24px;
      width: 0;
      background-color: $secondary-color;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      transition: all 1.4s cubic-bezier(0.27, 0.1, 0.75, 1) 0s,
        color 0.2s linear;
      animation: barSlideInAnimation 1.8s ease-in-out;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      color: $text-med-emp;
      cursor: pointer;

      &:hover {
        transition: color 0.2s;
        background-color: darken($secondary-color, 7);
      }

      &-tooltip {
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        padding: 10px;
        width: max-content;
        max-width: 156px;
        min-height: 20px;
        border-radius: 12px;
        color: #ffffff;
        background: $second-background;
        box-shadow: 0px 4px 10px rgba(57, 69, 119, 0.25);
        border-radius: 4px;
        z-index: 100;
        animation: tooltip-enter 0.15s ease-in-out;
      }

      &-value {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-15px, -47%);
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
        color: $white;
        animation: valueScaleAnimation 1.8s ease-in-out;
        white-space: nowrap;
        color: $white;

        &.centered {
          top: 50%;
          right: 50%;
          transform: translate(50%, -47%);
        }
      }

      &-n-a {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        color: $text-med-emp;
        white-space: nowrap;
        cursor: default;

        &::before {
          content: '';
          width: 4px;
          height: 20px;
          margin-right: 8px;
          border-radius: 0px 4px 4px 0px;
          background-color: $tertiary-background;
        }
      }
    }
  }

  &__sz-bar-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    transition: all 0.4s linear;
    width: 62%;
    overflow: hidden;

    &-n-a {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      color: $text-med-emp;
      cursor: default;

      &::before {
        content: '';
        width: 4px;
        height: 20px;
        margin-right: 8px;
        border-radius: 0px 4px 4px 0px;
        background-color: $tertiary-background;
      }
    }

    &__bar {
      position: relative;
      height: 16px;
      width: 62%;
      background-color: $secondary-color;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      transition: all 1.4s cubic-bezier(0.27, 0.1, 0.75, 1) 0s;
      animation: barSlideInAnimation 1.8s ease-in-out;
      margin-bottom: 8px;
      cursor: pointer;

      &:hover {
        transition: all 0.2s linear;
        filter: brightness(0.8);
      }

      &:last-child {
        margin-bottom: 0;
      }

      &-value {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-15px, -48%);
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: $white;
        animation: valueScaleAnimation 1.8s ease-in-out;
        white-space: nowrap;
      }
    }
  }

  @keyframes valueScaleAnimation {
    0% {
      transform: translate(-15px, -48%) scale(0.5, 0.5);
    }
    100% {
      transform: translate(-15px, -48%) scale(1, 1);
    }
  }

  @keyframes barSlideInAnimation {
    0% {
      transform: translate(-100%);
    }
    100% {
      transform: translate(0%);
    }
  }
}

@media screen and (max-width: ($desktop1660)) {
  .graph-row {
    margin: 12px 0;
  }
}

.ant-tooltip-arrow {
  display: none;
}

.ant-tooltip {
  width: max-content;
  max-width: max-content;
  min-height: 20px;
  background: transparent;
}

.ant-tooltip-content {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  border-radius: 12px;
  color: #ffffff;
  background: $second-background;
  box-shadow: 0px 4px 10px rgba(57, 69, 119, 0.25);
  border-radius: 4px;
}

.ant-tooltip-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: transparent;
  background-color: transparent;
  box-shadow: none;
}
