.select {
  background-color: #f0f2f9;
  border: 0;
  border-radius: 30px;
  padding: 1.6rem 5rem;
  font-size: 1.4rem;
  width: 100%;
  text-transform: capitalize;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &__option {
    text-transform: capitalize;
    border: none;
    overflow: hidden;
  }

  &-container {
    position: relative;

    &__imgArrow {
      position: absolute;
      top: 50%;
      right: 2rem;
      transform: translateY(-50%);
    }
    &__iconTZ {
      position: absolute;
      top: 50%;
      right: 32.6rem;
      transform: translateY(-60%);
    }
  }
}
