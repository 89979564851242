.player-slider {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    border-radius: 4px;
    height: 8px;
    cursor: pointer;

    &::-webkit-slider-runnable-track {
        height: 8px;
        background-color: transparent;
        border: none;
        border-radius: 4px;
    }

    &::-moz-range-track {
        height: 8px;
        background-color: transparent;
        border: none;
        border-radius: 4px;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        background-color: #537BFF;
        border: 1px solid $gray-100;
        border-radius: 50%;
        margin-top: -4px;
    }

    &::-moz-range-thumb {
        appearance: none;
        width: 16px;
        height: 16px;
        background-color: #537BFF;
        border: 1px solid $gray-100;
        border-radius: 50%;
        margin-top: -4px;
        box-sizing: border-box;
    }

    &--small {
        height: 4px;

        &::-webkit-slider-runnable-track {
            height: 4px;
        }

        &::-moz-range-track {
            height: 4px;
        }

        &::-webkit-slider-thumb {
            width: 10px;
            height: 10px;
            margin-top: -3px;
        }

        &::-moz-range-thumb {
            width: 10px;
            height: 10px;
            margin-top: -3px;
        }
    }
}
