.deals-table-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 500px;
  margin-top: 15px;

  &-remove-thread-icon {
    display: flex;
    flex-direction: row;
    width: 10%;
    cursor: pointer;
    padding: 0px 2.7%;
    transform: scale(1);
    transition: all 0.15s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;

    &.hidden {
      cursor: default;
      transform: scale(0);
      opacity: 0;
    }

    &-text {
      display: flex;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      min-width: max-content;
      margin-left: 32%;
      text-decoration: underline;
      color: $primary-color;
      transition: all 0.1s linear;
    }

    &:hover {
      .deals-table-footer-remove-thread-icon-text {
        color: $primary-color-hover;
      }
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    width: max-content;

    &-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #414042;
    }

    &-button {
      font-size: 20px;
      font-weight: 300;
      line-height: 20px;
      color: $text-high-emp;
      width: 28px;
      height: 28px;
      border-radius: 6px;
      background-color: #ffff;
      border: none;
      margin: 0 8px;
      cursor: pointer;
      transition: all 0.1s linear;

      &:hover {
        filter: brightness(0.9);
      }

      &:disabled {
        color: #ccd5f6;
        filter: none;
        cursor: default;
      }
    }
  }
}
