.meeting-signals-list-item {
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border: none;
    background-color: #FFFFFF;
    transition: background-color 150ms ease-in-out;
    width: 100%;
    cursor: pointer;

    &:not(:last-child) {
        border-bottom: 1px solid #E3E7ED;
    }

    &:hover:not(:disabled) {
        background-color: #F8FAFC;
    }

    &:disabled {
        cursor: default;
        background-color: #F5F7FF;
    }

    &__left {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__signal-wrapper {
        display: flex;
        align-items: center;

        & > * {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
        }
    }

    &__signal {
        
        background-color: $gray-400;
        margin-left: -4px;
    }

    &__signal-icon {
        width: 16px;
        height: 16px;
    }

    &__time {
        color: #537BFF;
    }
}
