.add-new-account-button {
    appearance: none;
    position: relative;
    background-color: transparent;
    border: none;
    padding: 8px 20px 12px 20px;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: $black-new-design;

    &__icon {
        width: 16px;
        height: 16px;
    }

    & > span {
        max-width: calc(100% - 28px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:not(:last-child)::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        width: calc(100% - 32px);
        background-color: #E3E7ED;
    }
}
