.edit-user {
  height: 100%;
  transition: all 0.6s;

  &__title {
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 2rem;
  }

  &__avatar {
    margin: 0 auto;
  }

  &__btn {
    margin: 2rem 0;
    
    &-timezone {
      margin: 20px 0 0;
    }

    &.err {
      transition: 0.1s;
      margin: 2.4rem 0 2rem 0;
    }
  }

  &__input {
    &:not(:first-child) {
      margin: 2rem 0;
    }

    display: block;
  }

  &__change-pass-notice {
    max-width: 364px;
    margin-top: 14px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    &.err {
      margin-top: 17px;
    }

    &__light {
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}
