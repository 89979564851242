.onboarding {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1002;
  }

  &__modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    height: 297px;
    padding: 36px 48px;
    background-color: #fff;
    border-radius: 16px;
    padding: 30px;
    z-index: 1003;
    color: $text-high-emp;
    @include userSelectDisable;

    &-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: 600; // TODO: change if needed
      line-height: normal;
      color: #4d4558;
      transition: none;
      cursor: default;
    }

    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 240px;
      padding: 8px 20px;
      margin: 40px 0;
      border-radius: 4px;
      background-color: #201b25;
      box-shadow: 0px 2px 8px 0px rgba(36, 45, 81, 0.2);
      color: #ffffff;
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
      transition: all 0.1s linear;

      img {
        margin-left: 5px;
      }

      &:hover {
        background-color: #343434;
      }
    }

    &-divider {
      width: 69%;
      height: 1px;
      height: 1px;
      background-color: #e0e2ee;
    }

    &__links {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 10px;
      width: 80%;

      &-link {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #2f86c7;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        margin: 10px 8px;
        transition: all 0.1s linear;
        cursor: pointer;

        &:after {
          content: url(./assets/onboard-link-icon.svg);
          margin-left: 3px;
          height: 12px;
          width: 12px;
        }

        &:hover {
          color: #359be9;

          &:after {
            filter: brightness(1.15);
          }
        }
      }
    }
  }

  &-enter {
    opacity: 0.4;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 0.3s;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 0.2s;
  }
}
