@import './components/graphWrapper/team-graph-wrap';
@import './components/graphRow/team-q-graph-row';
@import './components/graphTendencyBar/team-q-tendency-bar.scss';

.q-graph {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: all 0.5s linear;

  &__no-data-wrap {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 20px;
    min-width: 87px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 5px;
    box-shadow: 0px 4px 10px rgba(57, 69, 119, 0.25);
    border-radius: 4px;
    background-color: #ffffff;
    z-index: 2;

    &-message {
      color: #4d4558;
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
      animation: $fadein;
    }
  }

  &__avg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 62%;
    top: 0;
    left: 18%;
    padding: 65px 0 0;
    z-index: 1;
    cursor: default;
    animation: avgLineIn 2.25s ease-out;
    pointer-events: none;
    @include userSelectDisable;

    @keyframes avgLineIn {
      0% {
        opacity: 0;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    &__movable {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;

      &-value {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 73px;
        width: max-content;
        white-space: nowrap;
        height: 26px;
        padding: 0 10px;
        transform: translateX(-50%);
        background-color: $grey-hover;
        color: $white;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
      }

      &-line {
        width: 1.5px;
        height: 100%;
        background-color: $grey-hover;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 25px 0 22px;
    animation: fadeIn 0.8s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;

    &.hidden {
      transition: none;
      visibility: hidden;
    }

    &__member {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 18%;
      height: 40px;

      &-label {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $text-med-emp;
      }
    }

    &__value {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 62%;
      height: 40px;

      &-label {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $text-high-emp;
      }
    }

    &__sort-section {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      cursor: pointer;

      &__icon {
        width: max-content;
        height: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        // cursor: pointer;
        transform: scale(0.85);

        &-path {
          transition: all 0.25s linear;

          &:hover {
            filter: brightness(0.85);
          }
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100% - 120px);
    margin-top: 4px;
    overflow-x: auto;
    animation: fadeIn 0.8s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;
    z-index: 2;

    &::-webkit-scrollbar-track {
      background: #f8f8fb;
    }

    &::-moz-scrollbar-track {
      background: #f8f8fb;
    }

    &::-ms-scrollbar-track {
      background: #f8f8fb;
    }

    &::-webkit-scrollbar-thumb {
      background: $text-low-emp;
      border-radius: 4px;
    }

    &::-moz-scrollbar-thumb {
      background: $text-low-emp;
      border-radius: 4px;
    }

    &::-ms-scrollbar-thumb {
      background: $text-low-emp;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $secondary-shade50;
    }

    &::-moz-scrollbar-thumb:hover {
      background: $secondary-shade50;
    }

    &::-ms-scrollbar-thumb:hover {
      background: $secondary-shade50;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 2;
    background-color: $white;
    animation: fadeIn 0.8s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;
    cursor: default;

    &.hidden {
      transition: none;
      visibility: hidden;
    }

    &__member {
      width: 18%;
    }

    &__axis {
      display: flex;
      flex-direction: row;
      width: 61.6%;
      height: 21px;

      &-text {
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        color: $grey;

        &-low {
          position: absolute;
          bottom: 0;
          left: 0;
          animation: fadeIn 0.8s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;
        }

        &-high {
          position: absolute;
          bottom: 0;
          right: 0;
          animation: fadeIn 0.8s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;
        }
      }

      &__line-section {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        background-color: white;

        &-range {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          height: 1px;
          width: 100%;
          background-color: $grey;

          &__low-high {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 21px;
            width: 100%;
            border-top: 1px solid $grey;
            background-color: $white;
            font-size: 10px;
            font-weight: 500;
            line-height: 12px;
            color: $grey;

            &::before {
              content: 'Low';
              width: 0;
              height: 12px;
              margin-left: -30px;
              margin-top: -6px;
            }

            &::after {
              content: 'High';
              width: 0;
              height: 12px;
              margin-right: -10px;
              margin-top: -6px;
            }
          }
        }

        &__subsection {
          display: flex;
          flex-direction: column;
          width: 20px;
          margin-right: calc(20% - 20px);

          &:last-child {
            width: 0;
            margin: 0 0 0 -2px;
          }

          &-tick {
            width: 2px;
            height: 4px;
            background-color: $grey;
          }

          &-value {
            width: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            font-weight: 400;
            line-height: 16px;
            color: $grey;
          }
        }
      }
    }
  }
}
