.image-cropper {
  width: 100%;
  height: 40rem;

  &__cropper {
    width: 100%;
    height: 70%;
    position: relative;

    img {
      height: 100%;
    }
  }
  &__slider {
    position: relative;
    margin: 0 auto;
    width: 90%;
    height: 55px;
    padding: 27px 0px 0px;
    font-weight: 500;
    font-size: 14px;
    &-value {
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%, 20%);
    }
    &-minus {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 31px;
      left: -15px;
      height: 20px;
      width: 22px;
      border-radius: 50%;
      color: #394577;
      background-color: #e1ecf4;
      font-weight: 400;
      font-size: 20px;
      padding-bottom: 1px;
      cursor: pointer;
      z-index: 1001;
      @include userSelectDisable;
      &:active {
        transform: scale(0.95);
        background-color: darken(#e1ecf4, 2);
      }
      &:disabled {
        cursor: default;
      }
    }
    &-plus {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 31px;
      right: -15px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      color: #394577;
      background-color: #e1ecf4;
      font-weight: 400;
      font-size: 20px;
      cursor: pointer;
      z-index: 1001;
      @include userSelectDisable;
      &:active {
        transform: scale(0.95);
        background-color: darken(#e1ecf4, 2);
      }
      &:disabled {
        cursor: default;
      }
    }
  }
  &__btn-block {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0;
    width: 100%;
    min-height: 90px;
    &__btn {
      width: 100%;
      background-color: #394577;
    }

    &:last-child {
      margin: 0;
    }
  }

  .reactEasyCrop_CropAreaGrid::before,
  .reactEasyCrop_CropAreaGrid::after {
    border: 0 !important;
  }

  .reactEasyCrop_CropArea,
  img {
    border: 1px solid #6bc6ed !important;
  }

  .reactEasyCrop_Container {
    border-radius: 1.5rem;
    margin: 2rem 0;
  }
}
.ant-slider {
  margin: 9px 6px 10px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #f2f8fc;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider:hover .ant-slider-rail {
  background-color: #f2f8fc;
  height: 2px;
}
.ant-slider-track {
  background-color: transparent !important;
  &:hover {
    background-color: transparent;
  }
}
.ant-slider-handle {
  border: solid 5px #e1ecf4 !important;
  width: 3px;
  height: 20px;
  margin-top: -9px;
  border-radius: 2px;
  &:focus {
    box-shadow: 0 0 0 transparent !important;
    border-color: #c6dff1 !important;
  }
}
.ant-slider-disabled {
  cursor: default !important;
}

.ant-slider-disabled .ant-slider-track {
  background-color: transparent !important;
}

.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  cursor: default;
  background-color: #f2f8fc;
  border-color: #e1f0fa !important;
}
