.speech-trend-chart-avatar {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    &__decorator-icon {
        position: absolute;
        top: calc(100% + 1px);
        left: 50%;
        transform: translate(-50%, 0);
    }

    &--prospectSide {
        display: flex;

        .speech-trend-chart-avatar__decorator-icon path {
            fill: $color-prospect;
        }
    }

    &--userSide {
        display: flex;

        .speech-trend-chart-avatar__decorator-icon path {
            fill: $color-user;
        }
    }

    &--unassigned {
        display: flex;

        .speech-trend-chart-avatar__decorator-icon path {
            fill: $color-unassigned;
        }
    }
}

.speech-trend-chart-disclaimer {
    display: inline-flex;
    text-align: center;
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
