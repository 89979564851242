.date-picker-input {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__label {
        color: $black-new-design;
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
    }

    &__button {
        appearance: none;
        display: inline-flex;
        align-items: center;
        gap: 4px;
        padding: 7px;
        background-color: #FFFFFF;
        border: 1px solid #E3E7ED;
        border-radius: 4px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: $gray-200;
        cursor: pointer;

        &:disabled {
            cursor: default;
            color: $black-new-design;
            border-color: #537BFF;
        }
    }

    &__icon {
        width: 16px;
        height: 16px;
    }
}
