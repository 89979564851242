@import './components/Signal/signal';

.signals {
  display: flex;
  width: 50%;
  height: 120px;

  &__overflow {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
