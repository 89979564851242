@import './components/TranscriptList/transcript-list';
@import './components/FloatingControls/floating-controls';
@import './components/Controls/controls';
@import './components/ControlButton/control-button';
@import './components/Slider/slider';
@import './components/Toggle/topics-toggle';
@import './components/TranscriptionSearch/transcription-search';
@import './components/Signals/signals';

.player-container {
  padding-bottom: 0;
  height: 100%;
}

.player {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  background-color: #000000;
  height: calc(50% - 40px);

  &__video-wrapper {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    overflow: hidden;
  }

  &__signals-wrapper {
    position: absolute;
    bottom: 64px;
    left: 0;
    width: 100%;
  }

  &__floating-controls-wrapper {
    position: absolute;
    bottom: 12px;
    left: 0;
    width: 100%;
    z-index: 2;
  }

  &__controls-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    width: 100%;
  }

  &__big-play-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(./assets/meeting-preview.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &--timeline-changed {
      background-image: none;
    }
  }

  &__loader-wrapper {
    width: 56px;
    height: 56px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__loader {
    width: 56px;
    height: 56px;
    animation: loaderAnimation 1s linear infinite;

    @keyframes loaderAnimation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &--fullscreen {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: 100%;
    background-color: transparent;
    margin-bottom: 0;

    .player__floating-controls-wrapper {
      bottom: 52px;
    }

    .player__controls-wrapper {
      transform: translateY(0);
    }

    .player__big-play-wrapper {
      border-radius: 0;
      background-image: url(./assets/meeting-preview-big.png);
    }
  }
}
