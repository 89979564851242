.controls-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  animation: $fadein;

  &__left-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  &__right-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    &-text {
      color: #070b12;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }

    &__controls {
      display: flex;
      flex-direction: row;
      align-items: center;
      outline: 1px solid #e3e7ed;
      background: #ffffff;
      padding: 4px;
      border-radius: 8px;
      gap: 8px;

      &-button {
        appearance: none;
        color: #070b12;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        background: #ffffff;
        border-radius: 4px;
        padding: 0 8px;
        border: none;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        &:hover {
          background: #f3f3f3;
        }

        &.active {
          color: #ffffff;
          background: #070b12;
          cursor: default;

          &:hover {
            background: #070b12;
          }
        }
      }
    }
  }
}
