.message-sent-row {
  width: 100%;
  min-width: 90px;
}

@media screen and (max-width: ($desktop1280 - 100)) {
  .message-sent-row {
    text-align: start;
  }
}
