@import './components/upperHandFeedback/upper-hand-feedback';

.upper-hand {
  display: flex;
  flex-direction: column;
  height: 110px;
  max-height: 110px;
  color: #29335c;
  border-bottom: 1px solid #e7eaff;

  &__header {
    padding-left: 3px;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
  }

  &__body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 16.3px;
    height: 100px;

    &__left-person {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 50%;
      min-height: 67px;

      &-avatar {
        margin-right: 14px;

        &.bigger {
          margin: 0;
        }
      }

      &-name {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(20%, -50%);
        font-family: 'Open Sans';
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin-right: 22px;
        max-width: 71px;

        &.active {
          margin-right: 9px;
        }
      }
    }

    &__right-person {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 50%;
      min-height: 67px;

      &-avatar {
        margin-left: 15px;

        &.bigger {
          margin: 0;
        }
      }

      &-name {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-70%, -50%);
        font-family: 'Open Sans';
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin-left: 22px;
        max-width: 71px;

        &.active {
          margin-left: 9px;
        }
      }
    }
  }
}
