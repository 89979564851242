.event-analysis-summary {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #E3E7ED;
    border-bottom: 1px solid #E3E7ED;
    padding: 16px 0;

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        &:first-child {
            align-items: flex-start;
        }
    }

    &__title {
        display: flex;
        gap: 4px;
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
    }

    &__value {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        color: $gray-400;

        &--with-tooltip {
            cursor: pointer;
        }
    }

    &__avatars-group {
        display: flex;
        align-items: center;
        margin-right: 8px;

        & > .sound-wave-avatar {
            margin-right: -8px;
        }
    }

    &__avatars-group-counter {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        color: $gray-400;
        margin-left: 12px;
        margin-right: -8px;
    }

    &__content-tooltip.sound-wave-tooltip__content-wrapper {
        width: auto;
        max-width: 350px;
    }
}
