.avatar {
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &.hov-border {
    border-radius: 50%;
    transition: all 0.2s linear;

    &:hover {
      // outline: 1px solid $white;
      box-shadow: 0px 0px 1.5px 1.5px $white;
      filter: drop-shadow(0px 0px 2.5px rgb(244, 244, 244));
    }
  }

  &.white-border {
    border-radius: 50%;
    box-shadow: 0px 0px 0.5px 2px $white;
    filter: drop-shadow(0px 0px 1px rgb(244, 244, 244));
  }

  &__photo {
    transition: all 0.7s;
    border-radius: 50%;
    min-width: 50px;
    min-height: 50px;
    width: 50px;
    height: 50px;
    color: #4d4558;
    background-color: $tertiary-background;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    &.header {
      min-width: 24px;
      min-height: 24px;
      width: 24px;
      height: 24px;
      cursor: default;
    }

    &.table {
      min-width: 20px;
      min-height: 20px;
      width: 20px;
      height: 20px;
      font-family: 'Open Sans';
      font-weight: 400;
      letter-spacing: -0.01em;
      text-align: center;
      font-size: 11px;
    }

    &.larger {
      min-width: 78px;
      min-height: 78px;
      width: 78px;
      height: 78px;
    }

    &.small {
      min-width: 20px;
      min-height: 20px;
      width: 20px;
      height: 20px;
      font-family: 'Open Sans';
      font-weight: 400;
      line-height: 19px;
      letter-spacing: -0.01em;
      text-align: center;
      font-size: 12px;
    }

    &.in-sidebar {
      min-width: 40px;
      min-height: 40px;
      width: 40px;
      height: 40px;
    }

    &.in-my-meetings {
      min-width: 36px;
      min-height: 36px;
      width: 36px;
      height: 36px;
      border: 2px solid #ffffff;
    }

    &.engage {
      min-width: 24px;
      min-height: 24px;
      width: 24px;
      height: 24px;
    }
  }

  &__active {
    width: 16px;
    height: 16px;
    background-color: $green-primary;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &.active {
    .avatar__hover {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      background-color: $tertiary-background;
      width: 50px;
      height: 50px;
      font-size: 1rem;
      transition: all 0.7s;
      transform: translateY(100%);
      &.larger {
        width: 78px;
        height: 78px;
      }
    }

    &:hover {
      .avatar__photo {
        transform: translateY(-100%);
      }

      & .avatar__hover {
        transform: translateY(0);
      }
    }
  }
}

#avatar-upload {
  display: none;
}
