.turns-overview-table-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 12px;
  height: 40px;
  border-bottom: 1px solid #e3e7ed;
  animation: $fadein;
  transition: background 0.5s ease;
  cursor: pointer;

  &__unassigned {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    border-radius: 8px;
    border: 1px solid #e3e7ed;
    background: #ffffff;
    color: #646f87;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding-right: 8px;

    &__left-wrapper {
      display: flex;
      flex-direction: row;
      width: 50%;
      max-width: 50%;

      & > div {
        &:nth-child(1) {
          width: 60%;
          max-width: 60%;
          padding: 0px 8px 0px 8px;
        }
        &:nth-child(2) {
          min-width: 40%;
          max-width: 40%;
          padding: 0 8px 0 0;
        }
      }
    }
  }

  &--prospectSide {
    background: #fdf1f6;
  }

  &--userSide {
    background: #f6f5ff;
  }

  &--unassigned {
    background: #f1f4f8;
  }

  &--no-data {
    cursor: default;
    // pointer-events: none;
    // @include userSelectDisable;
  }

  &.empty-turn-row {
    cursor: default;
    pointer-events: none;
  }

  &:hover:not(.empty-turn-row):not(.turns-overview-table-row--no-data):not(.turns-overview-table-row--prospectSide):not(.turns-overview-table-row--userSide):not(.turns-overview-table-row--unassigned) {
    background: #f8fafc;
  }

  &__cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 4px;
    color: #070b12;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    &:nth-child(1) {
      width: 60%;
      max-width: 60%;
      padding: 8px 8px 8px 16px;
    }
    &:nth-child(2) {
      min-width: 40%;
      max-width: 40%;
      padding: 0 8px 0 0;
    }

    &-label {
      display: block;
      color: #070b12;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      @include userSelectDisable;
    }

    &__turn-type {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding: 4px;
      gap: 4px;
      width: 100%;
      height: 32px;
      max-height: 32px;
      position: relative;

      &-bar {
        width: 16px;
        height: 0;
        background: transparent;
        // border-radius: 2px 2px 0px 0px;
        transition: height 1s ease-in, background 0.3s ease-in;

        &--prospectSide {
          background: $color-prospect;
          &-darker {
            background: #d21961;
          }
        }

        &--userSide {
          background: $color-user;
          &-darker {
            background: #271ba4;
          }
        }

        &--unassigned {
          background: $color-unassigned;
          &-darker {
            background: darken($color-unassigned, 3);
          }
        }
      }

      &__no-data {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        color: #646f87;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        transition: all 0.2s ease-in;

        &:hover {
          .sound-wave-tooltip {
            transition: color 0.15s linear;
            color: #44536a;

            & > svg > path {
              transition: all 0.15s linear;
              fill: #44536a;
            }
          }
        }
      }
    }
  }

  &__header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid #e3e7ed;
    background: #f8fafc;

    &__cell {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px;
      gap: 4px;
      color: #070b12;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      &:nth-child(1) {
        width: 60%;
        max-width: 60%;
        padding: 8px 8px 8px 16px;
      }
      &:nth-child(2) {
        min-width: 40%;
        max-width: 40%;
        padding: 2px;

        .turns-overview-table-row__header-row__cell__title {
          & > span {
            width: calc(100% - 20px);
          }
        }
      }

      &__title {
        display: flex;
        color: #070b12;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        overflow: hidden;
        gap: 4px;

        & > span {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }
      }
    }
  }
}
