.sound-wave-subscription-info {
    display: flex;
    gap: 16px;
    padding: 8px 0;
    border-bottom: 1px solid #E3E7ED;

    &__item {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex-basis: 25%;
    }

    &__item-title {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: $gray-300;
    }

    &__item-value {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        font-size: 16px;
        line-height: 28px;
        font-weight: 500;
    }
}
