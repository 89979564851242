@import './components/RelationshipFilter/relationship-filter';
@import './components/FilterSelect/filter-select';
@import './components/DatePicker/date-picker';
@import './components/FilterRange/filter-range';

.relationship-filters {
    display: flex;
    align-items: center;
    position: relative;

    &__popup {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        width: 296px;
        z-index: 1000;
        left: 0;
        top: calc(100% + 8px);
        border-radius: 8px;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(57, 69, 119, 0.25);
        transition: opacity 150ms ease-in-out, visibility 0ms 150ms;

        &--opened {
            opacity: 1;
            visibility: visible;
            transition: opacity 150ms ease-in-out;
        }
    }

    &__popup-header {
        padding: 16px;
        border-bottom: 1px solid #E3E7ED;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }

    &__popup-content {
        display: flex;
        flex-direction: column;
    }

    &__filters-group {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 16px;

        &:not(:last-child) {
            border-bottom: 1px solid #E3E7ED;
        }
    }

    &__popup-footer {
        display: flex;
        justify-content: space-between;
        padding: 12px 16px;
        border-top: 1px solid #E3E7ED;
    }

    &__applied-filters-counter {
        position: relative;
        margin-left: 12px;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -8px;
            width: 1px;
            height: 16px;
            background-color: #E3E7ED;
        }
    }
}
