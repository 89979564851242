.event-summary-list {
    margin-bottom: 0;
}

ol.event-summary-list {
    padding-left: 16px;
}

ul.event-summary-list {
    padding-left: 18px;
}
