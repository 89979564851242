.table-sort-chevron {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8px;
  height: 6px;
  transition: all 0.2s linear;

  &.rotated {
    transform: rotate(180deg);
  }

  &.low-opacity {
    opacity: 0.4;
  }
}
