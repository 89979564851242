.arrow__wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &-select__block {
    display: flex;
    flex-direction: row;

    &-downloadButton {
      background-color: #394577;
      min-width: 78px;
      height: 32px;
      border-radius: 10px;
      border: none;
      color: white;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      margin-left: 7px;

      &:disabled {
        background-color: #cacaca;
      }
    }
  }
  &-warn__message {
    position: absolute;
    top: 115%;
    transform: translate(-38%, 0px);
    max-width: 280px;
    width: max-content;
    color: #eba53a;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
  }
  &-error__message {
    position: absolute;
    top: 90%;
    width: 150px;
    max-width: 151px;
    color: #f02501;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
  }
}
