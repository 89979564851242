.turns-scale {
    display: flex;
    height: 6px;
    border-radius: 6px;
    position: relative;
    z-index: 1;
    background-color: $gray-100;

    &__items-wrapper {
        height: 100%;
        display: flex;
        position: relative;
    }

    &__item {
        display: flex;
        height: 100%;
        padding: 0 2px;
        position: absolute;
        top: 0;

        &::before {
            content: '';
            display: block;
            height: 100%;
            width: 100%;
            border-radius: 6px;
        }

        &--userSide {
            &::before {
                background-color: $color-user;
            }
        }
    
        &--prospectSide {
            &::before {
                background-color: $color-prospect;
            }
        }
    
        &--unassigned {
            &::before {
                background-color: $color-unassigned;
            }
        }

        &--hidden {
            opacity: 0;
        }
    }

    &--userSide {
        background-color: #F6F5FF;
    }

    &--prospectSide {
        background-color: #FFFCF5;
    }

    &--unassigned {
        background-color: #F8FAFC;
    }
}
