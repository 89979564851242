.barwrapper {
  position: relative;
  width: 100%;
  min-height: 92px;

  &-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    text-transform: uppercase;
    color: #777679;
    margin: 13px 10px 18px 0px;
    animation: $fadein;

    &__icon-section {
      cursor: pointer;
      margin-left: 6px;
      position: relative;
      width: 12px;
      height: 12px;

      &-icon {
        width: 12px;
        height: 12px;
      }

      &-tooltip {
        display: none;

        &.open {
          position: absolute;
          top: 12px;
          left: 12px;
          display: block;
          width: max-content;
          max-width: 200px;
          border-radius: 15px;
          border-top-left-radius: 3px;
          text-transform: none;
          font-weight: 400;
          font-size: 14px;
          line-height: 19.6px;
          letter-spacing: -0.1px;
          padding: 15px;
          background-color: white;
          color: #414042;
          box-shadow: 0px 0px 20px rgb(218, 218, 218);
          z-index: 100;
        }
      }
    }
  }

  &__bar-section {
    position: relative;
    width: 100%;
    animation: $fadein;

    &-triangle {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      transition: all 2s linear;
      animation: $fadein;

      &-color {
        transition: 2.5s linear;
      }
    }
    &-bar {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 16px;
      width: 100%;
      border-radius: 20px;
      margin: 9px 0px;
      background-color: white;
      box-shadow: 0px 4px 10px rgba(57, 69, 119, 0.08);

      &-gradient {
        height: 100%;
        width: 41.5%;
        background: url(../../icons/barGradient_icon.svg);
        background-position: center;
        background-size: 100%;
        animation: $fadein;
      }
      @keyframes gradientAnimationIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      @keyframes gradientAnimationOut {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }
        100% {
          opacity: 0;
        }
      }
      &-grey {
        height: 100%;
        width: 41.5%;
        background: #e7eaff;
        animation: gradientAnimationOut 1s 0s 0.3s linear;
      }
      &-lines {
        position: absolute;
        width: 70%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    position: relative;
    animation: $fadein;

    &__coming-soon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%, -70%);
      width: 90px;
      height: 20px;
      background-color: #fff5d3;
      border-radius: 10px;
      color: #eba53a;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
    }
    &__not-enough-data {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -20%);
      width: 90px;
      height: 20px;
      background-color: #fff5d3;
      border-radius: 10px;
      color: #eba53a;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
    }

    &__text {
      font-size: 8px;
      font-weight: 500;
      line-height: 10px;
      color: #777679;
    }
  }
  &__skeleton-block {
    position: relative;
    width: 100%;
    min-height: 58px;
  }
}
