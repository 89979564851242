.overview-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 280px;
  height: max-content;
  background-color: $white;
  border-radius: 12px;
  padding: 16px 16px 6px 16px;
  animation: fadeIn 0.8s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 14px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $dark-grey-hover;
    cursor: default;

    &.margin {
      margin-top: 16px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }
}
