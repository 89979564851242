.email-analysis {
    &__metrics-wrapper {
        display: flex;
        gap: 24px;
    
        & > * {
            flex: 1 0 0;
        }
    }
}
