@import './components/ImageCropper/image-cropper';

.image-file-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 68px;
    min-width: 68px;
    height: 68px;
    margin-right: 16px;
    appearance: none;
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;

    &__image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: $gray-100;
        border-radius: 8px;
        overflow: hidden;
    }

    &__icon {
        width: 28px;
        height: 28px;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__input {
        display: none;
    }

    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: -8px;
        width: 24px;
        height: 24px;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
        background-color: #537BFF;
        transition: background-color 150ms ease-in-out;
    }

    &__circle-icon {
        width: 12px;
        height: 12px;
    }

    &--rounded {
        width: 56px;
        min-width: 56px;
        height: 56px;

        .image-file-upload__image-wrapper {
            border-radius: 50%;
        }

        .image-file-upload__icon {
            width: 24px;
            height: 24px;
        }

        .image-file-upload__circle {
            right: 0;
            width: 22px;
            height: 22px;
            border-width: 1px;
        }
    }

    &--disabled {
        cursor: default;

        .image-file-upload__circle {
            display: none;
        }
    }

    &:hover {
        .image-file-upload__circle {
            background-color: #2244B3;
        }
    }
}
