@import './components/NoteItem/note-item';
@import './components/NoteItemOverview/note-item-overview';

.rs-details-notes {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid #e3e7ed;
  border-radius: 8px;
  background-color: #ffffff;
  color: $black-new-design;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e3e7ed;
    padding: 8px 20px;
  }

  &__header-text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__header-title {
    font-size: 14px;
    line-height: 24px;
  }

  &__header-subtitle {
    font-size: 12px;
    line-height: 20px;
    color: $gray-300;
    animation: $fadein;
  }

  &__content {
    height: 308px;
  }

  &__empty-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 20px;
    color: $gray-200;
  }

  &__scrollbar {
    height: 100%;

    & .simplebar-content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
