.filter-select {
    position: relative;

    &__field {
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        border: 1px solid #E3E7ED;
        background-color: #FFFFFF;
        padding: 7px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        width: 100%;
        text-align: left;
        cursor: pointer;
    }

    &__text {
        width: calc(100% - 20px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__field-icon {
        width: 16px;
        height: 16px;
    }

    &__dropdown {
        display: flex;
        flex-direction: column;
        gap: 4px;
        position: absolute;
        z-index: 1000;
        top: calc(100% + 8px);
        left: 0;
        width: 100%;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 8px 0px rgba(78, 114, 157, 0.12);
        padding: 4px 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity 150ms ease-in-out, visibility 0ms 150ms;
    }

    &__option {
        appearance: none;
        display: inline-flex;
        align-items: center;
        text-align: left;
        gap: 8px;
        border: none;
        padding: 4px 8px;
        background-color: transparent;
        font-size: 12px;
        line-height: 20px;
        cursor: pointer;

        &:hover:not(:disabled) {
            background-color: #F8FAFC;
        }

        &:disabled {
            cursor: default;
        }
    }

    &__checked-icon {
        width: 16px;
        height: 16px;
    }

    &--expanded {
        & .filter-select__field-icon  {
            transform: rotate(180deg);
        }

        & .filter-select__dropdown {
            opacity: 1;
            visibility: visible;
            transition: opacity 150ms ease-in-out;
        }
    }
}
