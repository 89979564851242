.team-q-overview {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $text-high-emp;
  min-width: 920px;

  &__activity-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(54, 87, 152, 0.10);
    border-radius: 12px;
    margin-bottom: 24px;
    padding: 16px;
    color: $black-new-design;
    animation: $fadein;
    min-width: 915px;

    &__cell {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 8px;
      flex-grow: 1;
      flex-basis: calc(100% / 6 - 32px);
      cursor: default;

      &-title {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: $gray-300;
        white-space: nowrap;
      }

      &__value {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        white-space: nowrap;
        animation: fadeIn 0.8s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;

        &-bold {
          font-size: 32px;
          font-weight: 500;
          line-height: 48px;
          margin-right: 8px;
        }

        &-n-a {
          display: flex;
          align-items: center;
          height: 48px;
          font-size: 10px;
          font-weight: 400;
          line-height: 16px;
          color: $text-new-emp;
        }
      }

      & .team-q-skeleton__cell-value {
        height: 48px;
      }
    }

    &-divider {
      height: 84px;
      width: 1px;
      align-self: center;
      border-radius: 1px;
      background-color: $tertiary-background;
    }
  }

  &__chart-sections-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__chart-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: max-content;
    background-color: $white;
    box-shadow: 0px 4px 8px rgba(54, 87, 152, 0.1);
    border-radius: 12px;

    &-divider {
      height: 240px;
      width: 1px;
      background-color: $tertiary-background;
      align-self: center;
    }
  }
}

@media screen and (min-width: ($desktop1660 + 1)) {
  .team-q-overview__activity-section__cell {
    &-title {
      font-size: 16px;
      line-height: 24px;
    }

    &__value {
      font-size: 12px;
      line-height: 20px;

      &-bold {
        font-size: 36px;
        line-height: 48px;
      }
    }
  }
}
