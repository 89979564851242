.turns-overview-unassigned {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.unassigned-expanded {
    .turns-overview-unassigned__content {
      height: 56px;
      opacity: 1;
      background: #f5f7ff;
      border-bottom: 1px solid #e3e7ed;
      background: #f8fafc;
      transition: height 300ms ease-in-out, padding 300ms ease-in-out;
    }
    .turns-overview-unassigned__content__overflow {
      padding: 8px 8px;
    }
  }

  &__content {
    display: flex;
    overflow: hidden;
    height: 0;
    opacity: 0;
    background: #f8fafc;
    transition: height 300ms ease-in-out, padding 300ms ease-in-out,
      border-width 0ms ease-in-out 300ms, opacity 0ms ease-in-out 300ms;

    &__overflow {
      padding: 0 8px;
      transition: padding 300ms ease-in-out;
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 8px;
      gap: 8px;
      overflow: hidden;

      // & > div:not(:first-child) {
      //   margin-top: 8px;
      // }
    }
  }

  &__controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 16px;
    max-height: 16px;
    width: 100%;
    padding: 16px;
    gap: 4px;
    border-bottom: 1px solid #e3e7ed;

    &__button {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      animation: $fadein;

      &-icon {
        & > path {
          transition: all 0.2s linear;
        }
      }

      &-text {
        cursor: pointer;
        overflow: hidden;
        color: var(--Information-Information, #537bff);
        text-overflow: ellipsis;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }

  &--unassigned {
    border: 1px solid #e3e7ed;
    border-radius: 8px;
    padding: 8px;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: row;

    &__table {
      width: 50%;
      max-width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;

      &:first-child {
        border-right: 1px solid #e3e7ed;
      }
    }
  }
}
