@import './components/competence/table-competence';
@import './components/lastActive/table-last-active';
@import './components/lastMessageSentTime/table-time-sent';
@import './components/linkToThread/table-link-to-thread';
@import './components/participants/table-participants';
@import './components/subject/table-subject';
@import './components/threadDuration/table-thread-duration';
@import './components/deleteThread/table-delete-thread';
@import './components/removethreadIcon/remove-thread-icon';
@import './components/removeThreadModal/remove-thread-modal';
@import './components/headerTooltip/deals-table-tooltip';
@import './components/footer/deals-table-footer';
@import './components/emptyList/deals-empty-list';
@import './components/skeleton/deals-table-skeleton.scss';
@import './components/noDataFirstTime/deals-no-data';
@import './components/renameAccountModal/rename-account-modal.scss';
@import './components/tableHeader/Chevron/table-sort-chervon.scss';
@import './components/tableHeader/Account/table-header-account';
@import './components/tableHeader/LastInteraction/table-header-last-interaction';
@import './components/account/account-cell';
@import './components/accountSubrow/account-cell-subrow';
@import './components/noData/engagements-no-data';
@import './components/sampleDataLabel/sample-data-label';

.deals-table {
  position: relative;
  cursor: default;
  animation: animationFadeIn 0.2s ease-in;

  @keyframes tableFadeIn {
    0% {
      opacity: 0;
    }
    80% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }

  .ant-table-thead > tr > th {
    text-transform: capitalize;
    color: $text-med-emp;
    font-weight: 600; // TODO: change if needed
    font-size: 14px;
    min-width: 40px;
  }

  // .ant-table-tbody > tr {
  //   box-shadow: none;
  // }

  // .ant-table-tbody > tr:active > td {
  //   background: #e0e5fa;
  //   &:last-child {
  //     div {
  //       opacity: 1;
  //       max-height: unset;
  //       text-align: start;
  //     }
  //   }
  // }

  .ant-table-ping-right:not(.ant-table-has-fix-right)
    .ant-table-container::after {
    box-shadow: unset;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-row .ant-table-row-level-0 .ant-table-row-selected {
    background: #e0e5fa;
  }

  .ant-table-header {
    border: none;
  }

  .ant-table-thead {
    border-bottom: 1px solid #f0f1f9;
    height: 60px;
  }

  .ant-table-container {
    background: #f9f9fc;
  }

  .ant-table {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.2px;
    color: $text-high-emp;

    table {
      border-spacing: 0 8px;
      cursor: pointer;
    }
  }

  .ant-table-body {
    .ant-table-measure-row {
      visibility: collapse;
    }

    &::-webkit-scrollbar-track-piece:end {
      margin-bottom: 6px;
    }

    &::-moz-scrollbar-track-piece:end {
      margin-bottom: 6px;
    }

    &::-ms-scrollbar-track-piece:end {
      margin-bottom: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: $text-low-emp;
      border-radius: 4px;
      box-shadow: inset 0px 8px 0px 0px #f9f9fc;
    }
    &::-moz-scrollbar-thumb {
      background: $text-low-emp;
      border-radius: 4px;
      box-shadow: inset 0px 8px 0px 0px #f9f9fc;
    }
    &::-ms-scrollbar-thumb {
      background: $text-low-emp;
      border-radius: 4px;
      box-shadow: inset 0px 8px 0px 0px #f9f9fc;
    }
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border: 0;
    padding: 5px 2px;
    letter-spacing: -0.1px;
    min-width: 10px;

    &:nth-child(2) {
      width: 30px;
      max-width: 30px;
      padding: 0px 0.6% 0px 0px;
      // display: none;
    }

    &:nth-child(3) {
      width: 130px;
      max-width: 130px;
      padding: 0px 2px;
    }

    &:nth-child(4) {
      min-width: 180px;
      width: 180px;
      max-width: 180px;
    }

    &:nth-child(5) {
      width: 180px;
      max-width: 180px;
      text-align: start;
    }

    &:nth-child(6) {
      min-width: 100px !important;
      width: 140px;
      max-width: 140px;
      text-align: start;
    }

    &:nth-child(7) {
      min-width: 30px;
      width: 30px;
      max-width: 30px;
      text-align: start;
    }

    &:nth-child(8) {
      min-width: 30px;
      width: 30px;
      max-width: 30px;
      text-align: start;
      padding: 0px;
    }

    &:nth-child(9) {
      min-width: 35px;
      width: 35px;
      max-width: 35px;
      padding: 0px;
    }

    &:nth-child(10) {
      min-width: 45px;
      width: 45px;
      max-width: 45px;

      div {
        display: inline;
        width: 25px;
        max-width: 25px;
      }
    }
  }

  .ant-table-tbody > tr {
    &.expanded-row {
      td {
        &:nth-child(7) {
          div {
            display: none;
          }
        }
      }
    }
  }

  .ant-table-expand-icon-col {
    width: 24px;
    z-index: 1;

    @media screen and (max-width: 1366px) {
      width: 32px;
    }

    @media screen and (min-width: 1367px) and (max-width: 1700px) {
      width: 30px;
    }

    @media screen and (min-width: 1701px) {
      width: 20px;
    }
  }
  .ant-table .ant-table-selection-col {
    width: 20px;

    @media screen and (max-width: 1366px) {
      width: 25px;
    }
  }

  .ant-table-tbody > tr > td {
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding: 0 5px;
      min-width: 10px;
      // background: transparent !important;

      @media screen and (max-width: 1366px) {
        padding: 0 10px;
      }
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 0px;
      vertical-align: middle;
      text-align: center;
    }
  }

  .ant-table-tbody > .disabled > td {
    color: rgba(65, 64, 66, 0.4);
    font-weight: 500;
  }

  .ant-spin-container > ul {
    display: none;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    display: none;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border: 1px solid #575f83;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #575f83;
    border-color: #575f83;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td,
  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: $tertiary-background-hover !important;
  }

  .ant-checkbox-inner::after {
    top: 40%;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 4px solid #fff;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(0.55) translate(-14px, -12px);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }

  .ant-checkbox-inner::after {
    left: 20%;
    width: 10px;
    height: 16px;
  }

  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled
    .ant-checkbox-disabled
    .ant-checkbox-input {
    cursor: default;
    margin-left: 1.4%;
  }

  .ant-table-body {
    height: 600px;
    background: #f9f9fc;
  }

  &__expanded-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    overflow: hidden;
    margin-top: -10px;
    border-radius: 0px 0px 8px 8px;
    // border-top: 2px solid #e0e7f0;

    &::before {
      content: '';
      width: 99.5%;
      height: 2px;
      background-color: #e0e7f0;
      margin: auto;
    }

    .hidden {
      @include userSelectDisable;
      pointer-events: none;
      transition: none;
      opacity: 0;
      visibility: hidden;
    }

    .ant-table table {
      border-spacing: 0 0;
    }

    .ant-table-wrapper::before {
      display: none;
    }

    .ant-table-wrapper::after {
      display: none;
    }

    .ant-table-thead {
      height: 0;
      border: none;
    }

    .ant-table-thead > tr > th {
      padding: 0;

      &:nth-child(2) {
        width: 30px;
        max-width: 30px;

        .ant-table-selection {
          display: none;
        }
      }
    }

    .ant-table-tbody > tr {
      box-shadow: none;

      &:hover {
        td {
          background: #f7f8fa !important;
        }
      }
    }

    .ant-table-tbody > tr > td {
      background: #fbfbfd;

      &:first-child {
        border-radius: 0;
        padding: 0 5px;
        min-width: 10px;
        max-width: 10px;
      }

      &:nth-child(2) {
        width: 30px;
        max-width: 30px;

        .ant-table-selection {
          display: none;
        }

        .ant-checkbox-wrapper {
          display: none !important;

          .ant-checkbox {
            display: none;
          }
        }
      }

      &:last-child {
        border-radius: 0;
        padding: 0px;
        vertical-align: middle;
        text-align: center;
      }
    }
  }
}

.deals-table-header {
  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    &.centered {
      justify-content: center;
    }

    &-last-active {
      display: block;
      max-width: calc(100% - 21px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-time-sent {
      display: block;
      max-width: calc(100% - 20px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-duration {
      display: block;
      max-width: calc(100% - 21px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-competence {
      display: block;
      max-width: calc(100% - 21px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.expand-row-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: 4px solid #f8f8fb;
  background-color: $white;
  cursor: pointer;
  transition: all 0.1s linear;
  margin: auto;
  // transform: translate(-17px, 0px);

  @media screen and (max-width: 1366px) {
    transform: none;
  }

  &:hover {
    filter: brightness(0.98);
    background-color: $tertiary-background;
  }
}

.popup-icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: ($desktop1440 + 81)) {
  .deals-table-header-title {
    flex-direction: column-reverse;
  }

  .deals-table {
    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th {
      &:nth-child(2) {
        max-width: 120px !important;
        // border-radius: 10px 0px 0px 10px;
      }

      &:nth-child(3) {
        max-width: 200px !important;
      }

      &:nth-child(9) {
        min-width: 40px;
        max-width: 40px !important;
      }
    }
  }
}
