.reply-time {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  border-bottom: 1px solid #e7eaff;
  min-height: 140px;
  max-height: 140px;
  width: 100%;
  max-width: 100%;
  color: #4d4558;

  &__header {
    padding-left: 3px;
    font-family: 'Open Sans';
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;

    &-bold {
      font-family: 'Open Sans';
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      margin-right: 4px;
    }
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 16.3px;
    height: 80px;
    padding-top: 8px;

    &__left-person {
      min-width: 60px;
      height: 45px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-name {
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translate(-115%, -120%);
        font-family: 'Open Sans';
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        max-width: 80px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &__image-section {
        position: relative;
        cursor: pointer;

        &-tooltip {
          display: none;

          &.open {
            font-family: 'Open Sans';
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            position: absolute;
            height: 25px;
            width: max-content;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -200%);
            border-radius: 4px;
            padding: 6px 10px 6px 10px;
            color: rgba(255, 255, 255, 0.9);
            background: $second-background;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            z-index: 15;
          }

          &-capital {
            text-transform: capitalize;
            margin-right: 2.5px;
          }
        }

        &-capital {
          text-transform: capitalize;
          margin-right: 2.5px;
        }

        &-svg {
          font-size: inherit;
          transition-delay: 0.4s;
          transition: all 0.6s ease-in-out 0.4s;

          &.active {
            transform: scale(1.3, 1.4);
          }
        }

        &-avatar {
          cursor: default;
          border-radius: 50%;
          outline: 1px solid #e8ebf7;
          position: absolute;
          top: -33%;
          left: 70%;
        }
      }

      &-time {
        font-family: 'Open Sans';
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.2px;
        padding-top: 10px;
        height: 22px;

        &-bold {
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          margin-right: 3px;
        }

        &-n-a {
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: -0.01em;
          color: #bfbfbf;
        }
      }
    }

    &-na {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 17px);
      font-family: 'Open Sans';
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: -0.01em;
      color: #bfbfbf;
    }

    &-divider {
      display: flex;
      align-self: center;
      width: 1px;
      margin: -22px 10px 0px 10px;
      height: 17px;
      background-color: #ccd5f6;
    }

    &__right-person {
      min-width: 60px;
      height: 45px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-name {
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translate(68px, -120%);
        font-family: 'Open Sans';
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        max-width: 80px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &__image-section {
        position: relative;
        cursor: pointer;

        &-tooltip {
          display: none;

          &.open {
            font-family: 'Open Sans';
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            position: absolute;
            height: 25px;
            width: max-content;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -200%);
            border-radius: 4px;
            padding: 6px 10px 6px 10px;
            color: rgba(255, 255, 255, 0.9);
            background: $second-background;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            z-index: 15;
          }

          &-capital {
            text-transform: capitalize;
            margin: 0px 2.5px;
          }
        }

        &-svg {
          font-size: inherit;
          transition-delay: 0.4s;
          transition: all 0.6s ease-in-out 0.4s;

          &.active {
            transform: scale(1.3, 1.4);
          }
        }

        &-avatar {
          cursor: default;
          border-radius: 50%;
          outline: 1px solid #e8ebf7;
          position: absolute;
          top: -33%;
          left: 70%;
        }
      }

      &-time {
        font-family: 'Open Sans';
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.2px;
        padding-top: 10px;
        height: 22px;

        &-bold {
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          margin-right: 3px;
        }

        &-n-a {
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: -0.01em;
          color: #bfbfbf;
        }
      }
    }
  }

  &__footer {
    padding-left: 3px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    max-width: calc(100% - 5px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
