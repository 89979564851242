.checkbox {
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  cursor: pointer;

  &.disabled {
    cursor: default;
    opacity: 0.9;
  }

  &__control {
    display: none;

    &:checked + div {
      position: relative;
      animation: overlayScaling 0.1s cubic-bezier(0.98, 0.56, 0.77, 1.51);

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        border: 3px solid #4d4558;
        border-top: 0;
        border-left: 0;
        width: 10px;
        height: 20px;
        transform: rotate(45deg) scale(0.6) translate(-19px, -7px);
        opacity: 1;
        animation: checkmarkAnimation 0.2s cubic-bezier(0.98, 0.56, 0.77, 1.51);
      }
    }

    &:disabled {
      cursor: default;
      opacity: 0.9;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #4d4558;
    border-radius: 2px;
  }

  @keyframes checkmarkAnimation {
    0% {
      transform: translate(-48%, -55%) rotate(45deg) scale(0);
    }
    100% {
      transform: translate(-48%, -55%) rotate(45deg) scale(0.6);
    }
  }

  @keyframes overlayScaling {
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }
}
