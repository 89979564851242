@import './sentimentReceived/sentiment';
@import '../skeletons/chart-skeletons';
@import '../charts/comingSoon/coming-soon';
@import '../charts/estimatedTends/tendencyBar/tendency-bar.scss';
@import '../charts/mostCompetent/mostCompRow/most-competent-row';
@import '../charts/heatMaps/heatMap/heat-map';
@import './meetingDuration/meeting-duration-chart';

.charts {
  &__qactivity {
    width: 100%;
    display: flex;
    justify-content: center;

    &-analyzed {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 12px;
      margin-right: 20px;

      &::before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 2px;
        margin-right: 4px;
        background-color: #4eb4ff;
      }
    }

    &-simulated {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 12px;

      &::before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 2px;
        margin-right: 4px;
        background-color: #365798;
      }
    }
  }

  &__estimated-tendencies {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0px 7%;
  }

  &__upper-hand {
    &-value-on-top {
      font-size: 36px;
      font-weight: 500;
      line-height: 48px;
    }
  }

  &__avg-partics {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: 10%;

    @media screen and (max-width: 1920px) {
      margin-left: 12%;
    }

    @media screen and (max-width: 1660px) {
      margin-left: 10%;
    }

    @media screen and (max-width: 1440px) {
      margin-left: 12%;
    }

    @media screen and (max-width: 1280px) {
      margin-left: 3%;
    }

    &__user-legend {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 12px;

      &::before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 2px;
        margin-right: 4px;
        background-color: #4eb4ff;
      }
    }

    &__prospect-legend {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 12px;
      margin: 0px 20px;

      &::before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 2px;
        margin-right: 4px;
        background-color: #365798;
      }
    }

    &__other-legend {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 12px;

      &::before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 2px;
        margin-right: 4px;
        background-color: #acacb7;
      }
    }
  }

  &__sell-zone {
    &__wrapper-title {
      display: flex;
      height: 25px;

      &__tm {
        display: flex;
        align-self: end;
        font-size: 6px;
        height: 25px;
        width: 12px;
      }

      &-chart-value {
        display: block;
        width: max-content;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;

        &.decreased {
          font-size: 10px;
        }
      }
    }

    &__legend {
      width: 100%;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      line-height: 12px;

      &-below {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 12px;
        margin: 0px 24px;

        &::before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 2px;
          margin-right: 4px;
          background-color: #646c83;
        }
      }

      &-in {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 12px;

        &::before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 2px;
          margin-right: 4px;
          background-color: #57c888;
        }
      }
      &-above {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 12px;

        &::before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 2px;
          margin-right: 4px;
          background-color: #acacb7;
        }
      }
    }
  }

  &__most-competent {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    color: #4d4558;

    &__header {
      height: 30px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      &-name {
        width: 43%;
      }

      &-emails {
        width: 27%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      &-date {
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }

  &__incoming-activity-legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 16px;
    color: $text-high-emp;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;

    &__color-scheme {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 10px;
      border-radius: 4px;
      width: 40px;

      &-left {
        width: 10px;
        height: 10px;
        background-color: $primary-shade25;

        &::after {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          margin-left: 10px;
          background-color: $primary-shade50;
        }
      }

      &-right {
        width: 10px;
        height: 10px;
        background-color: $primary-shade75;
        margin-left: 10px;

        &::after {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          margin-left: 10px;
          background-color: $primary-color;
        }
      }
    }
  }

  &__outgoing-activity-legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 16px;
    color: $text-high-emp;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;

    &__color-scheme {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 10px;
      border-radius: 4px;
      width: 40px;

      &-left {
        width: 10px;
        height: 10px;
        background-color: $secondary-shade25;

        &::after {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          margin-left: 10px;
          background-color: $secondary-shade50;
        }
      }

      &-right {
        width: 10px;
        height: 10px;
        background-color: $secondary-shade75;
        margin-left: 10px;

        &::after {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          margin-left: 10px;
          background-color: $secondary-color;
        }
      }
    }
  }

  &__talk-time-ratio {
    &__wrapper-title {
      display: flex;
      height: 25px;

      &__tm {
        display: flex;
        align-self: end;
        font-size: 6px;
        height: 25px;
        width: 12px;
      }

      &-chart-value {
        display: block;
        width: max-content;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        pointer-events: none;

        &.decreased {
          visibility: hidden;
          font-size: 10px;
        }
      }
    }

    &__total-meetings {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: absolute;
      transform: translate(0%, calc(-50% + 100px));
      z-index: 100;

      &-value {
        color: #070b12;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px;
      }

      &-subtitle {
        color: #646f87;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.3px;
      }
    }

    &__chart-center {
      display: block;
      white-space: nowrap;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(-50% - -17px));
      color: #070b12;
      text-align: center;
      width: max-content;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;
      z-index: 100;

      &-arrow {
        margin: 0px 0px 0px 8px;
      }

      &-tooltip-content {
        white-space: nowrap;
      }
    }

    &__legend {
      width: 100%;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 11px;
      line-height: 12px;
      gap: 6px;

      &-me {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 12px;

        &::before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 4px;
          background-color: #4eb4ff;
        }
      }

      &-prospect {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 12px;

        &::before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 4px;
          background-color: #365798;
        }
      }

      &-team {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        font-size: 12px;
        line-height: 12px;

        &::before {
          content: '';
          width: 12px;
          min-width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 4px;
          background-color: #57c888;
        }

        &--team-q {
          &::before {
            background-color: #4eb4ff;
          }
        }

        &-text {
          display: block;
          white-space: nowrap;
        }
      }

      &-unassigned {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 12px;

        &::before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 4px;
          background-color: #646c83;
        }
      }

      &-silence {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 12px;

        &::before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 4px;
          background-color: #acacb7;
        }
      }
    }
  }

  &__meeting-count {
    &__total-meetings-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      height: 15px;
      z-index: 0;
      position: relative;
      transform: translateY(7px);

      &-value {
        color: #070b12;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
      }

      &-subtitle {
        color: #646f87;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }

    &__legend {
      width: 100%;
      height: 12px;
      margin-top: 27px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 11px;
      line-height: 12px;
      gap: 18px;
      color: #646f87;

      &-your {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 12px;

        &::before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 4px;
          background-color: #4eb4ff;
        }
      }

      &-team {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 12px;

        &::before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 4px;
          background-color: #365798;
        }
      }
    }
  }

  &__meeting-duration {
    &__description-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      height: 15px;
      z-index: 0;
      position: relative;
      transform: translateY(7px);

      &-value {
        color: #070b12;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
      }

      &-subtitle {
        color: #646f87;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }

    &__legend {
      width: 100%;
      height: 12px;
      margin-top: 27px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 11px;
      line-height: 12px;
      gap: 18px;
      color: #646f87;

      &-me {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 12px;

        &::before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 4px;
          background-color: #4eb4ff;
        }
      }

      &-team {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 12px;

        &::before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 4px;
          background-color: #365798;
        }
      }
    }
  }
}

.recharts-text {
  &.recharts-cartesian-axis-tick-value {
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    text-align: right;
    color: #646c83;
  }
}

.chart-tooltip {
  position: relative;
  min-height: 45px;
  width: 195px;
  display: flex;
  flex-direction: column;
  color: #ffff;
  background: $second-background;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  padding: 15px 5px 15px 15px;
  z-index: 1001;

  border-radius: 8px;
  border: 1px solid #e3e7ed;
  background: #ffffff;
  width: max-content;
  min-height: 28px;
  // max-height: 28px;
  padding: 12px 16px;
  box-shadow: 0px 1px 2px 0px rgba(71, 107, 132, 0.3),
    0px 2px 6px 0px rgba(71, 107, 132, 0.15);
  color: #070b12;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  z-index: 1001;

  &::after {
    content: url(../../icons/tooltip-arrow-icon.svg);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -16.5px;
    border: none;
    outline: none;
    z-index: 111;
    filter: drop-shadow(-2px 1px 1px rgba(71, 107, 132, 0.15));
  }

  &.no-triangle {
    &::after {
      content: '';
      display: none;
    }
  }

  &.light {
    border-radius: 8px;
    border: 1px solid #e3e7ed;
    background: #ffffff;
    width: max-content;
    min-height: 28px;
    max-height: 28px;
    padding: 4px 16px;
    box-shadow: 0px 1px 2px 0px rgba(71, 107, 132, 0.3),
      0px 2px 6px 0px rgba(71, 107, 132, 0.15);
    color: #070b12;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    z-index: 1001;

    &::after {
      content: url(../../icons/tooltip-arrow-icon.svg);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -16.5px;
      border: none;
      outline: none;
      z-index: 111;
      filter: drop-shadow(-2px 1px 1px rgba(71, 107, 132, 0.15));
    }
  }

  &-arrow-icon {
    position: absolute;
    bottom: -11px;
  }

  &__multi-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    position: relative;

    &-left-border {
      height: 54px;
      width: 7px;
      border-radius: 4px;
      border: 0.5px solid rgb(255, 255, 255);
      background-color: #515151;
      margin-right: 10px;
    }

    &__body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__label {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-transform: capitalize;
      margin-bottom: 5px;
    }
  }

  &__single-line {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-left-border {
      height: 54px;
      width: 5px;
      border-radius: 4px;
      border: 0.5px solid rgb(255, 255, 255);
      background-color: #515151;
      margin-right: 10px;
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__date-on-top {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;

    &.margin {
      margin-bottom: 12px;
    }
  }

  &__date {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    margin-bottom: 15px;

    &.uh {
      display: block;
      min-width: max-content;
      white-space: nowrap;
      margin-bottom: 5px;
    }
  }

  &__label {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-transform: capitalize;
    margin-bottom: 5px;

    &-participants {
      height: 35px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;

      &-value {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }
    }

    &__email-length {
      height: 35px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;

      &-value {
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }

  &__last-days {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 5px;
  }

  &__value {
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 4px;
    color: #070b12;

    &-text {
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;

      &.nowrap {
        white-space: nowrap;
      }

      &-alone {
        height: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }

    &.top-domains {
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.1px;
      margin-bottom: 0px;
    }

    &.participants {
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      margin: 0px;
    }

    &.uh {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      min-width: max-content;
      width: 90%;
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: 0px;
      padding: 0px 4px;
      color: #070b12;

      &-dot {
        width: 12px;
        height: 12px;
        border: 2px solid #ffff;
        background-color: #4eb4ff;
        border-radius: 50%;
        margin: 0;
      }
    }
  }

  &__percents {
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    color: #070b12;

    &-blue {
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
      color: #4eb4ff;
    }

    &-red {
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
      color: #f26065;
    }

    &-grey {
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
      color: #777679;
    }

    &-arrow {
      margin: 0px 3px -1.5px 0px;
    }

    &-text {
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
      color: #070b12;

      &-bold {
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        color: #070b12;

        &-grey {
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          color: #3d3d3d;
        }

        &-domain {
          font-weight: 500;
          font-size: 10px;
          line-height: 16px;
          color: #4eb4ff;
        }
      }

      &-place {
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        color: #070b12;
        text-decoration: underline;
      }
    }
  }
}

.ui-chart {
  position: relative;
}

.ui-chart-tooltip {
  pointer-events: none;
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.recharts-dot {
  opacity: 1;
}

.ui-chart-tooltip-content {
  width: 240px;
  height: 115px;
  box-shadow: 0 0px 5px 0.1px rgba(196, 196, 196, 0.5);
  background-color: #b97c7c;
  border-left: 5px solid #9066b8;
  padding: 15px 24px;
  border-radius: 12px;
  color: black;
}

.tooltip-heading {
  font-size: 12px;
  font-weight: bold;
  color: black;
}
.qty,
.date {
  display: flex;
  padding: 9px 0;
  color: black;
  font-size: 14px;
  justify-content: space-between;
}

.ui-chart-tooltip-content:after,
.ui-chart-tooltip-content:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ui-chart-tooltip-content:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width: 10px;
  margin-left: -10px;
}
