.loading {
  background-color: grey;
}

.create-new-team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 700px;

  &__success-modal {
    font-size: 14px;
    line-height: 18px;
    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
    &__button {
      margin-top: 20px;
    }
  }
  &__error {
    color: #f26065;
    font-size: 1.4rem;
    margin-bottom: -22px;
  }

  &__avatar {
    margin: 0 auto;
  }

  &__btn {
    margin: auto;
    width: 376px;
  }

  &__input {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    &.error {
      margin-bottom: 25px;
    }
  }

  &__added-users {
    margin-top: 30px;
    max-height: 350px;
    overflow: auto;
    &__title {
      font-size: 10px;
      line-height: 12px;
      color: #777679;
      margin-bottom: 8px;
    }
    &__user {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 20px;
      height: 45px;
      &__name {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #414042;
      }
      &__email {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #777679;
      }
    }
  }
}
