.chart-canvas-tooltip {
    position: absolute;
    cursor: pointer;

    &__content-wrapper {
        width: auto;

        & .sound-wave-tooltip__arrow-icon {
            display: none;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
    }

    &__row {
        display: flex;
        gap: 4px;
    }

    &__label {
        color: $gray-300;
    }

    &__value {
        color: #537BFF;
    }
}
