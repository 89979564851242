$modal-animation-duration: 0.4s;

.rename-account {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    width: 80%;
  }

  &-input {
    margin: 10px 0px 20px;
    width: 75%;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600; // TODO: change if needed
    width: 85%;

    &-save {
      width: 100%;
      height: 32px;
      border-radius: 4px;
      background-color: $text-high-emp;
      color: #fff;
      border: none;
      cursor: pointer;

      &:hover {
        filter: brightness(1.1);
      }
    }

    &-cancel {
      width: 100%;
      height: 32px;
      border-radius: 4px;
      background-color: #fff;
      color: $text-high-emp;
      margin-top: 5px;
      border: none;
      cursor: pointer;

      &:hover {
        filter: brightness(0.98);
      }
    }
  }

  &__status {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.4s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;

    &-img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      animation: scaleInIcon 0.4s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
    }

    &-bold-text {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: $text-high-emp;
      margin: 15px 0px 9px;
    }

    &-text {
      text-align: center;
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;
      color: $text-med-emp;
    }

    @keyframes scaleInIcon {
      0% {
        transform: scale(0);
      }

      100% {
        transform: scale(1);
      }
    }
  }
}
