.engs-no-data {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 85vh;
  color: $text-high-emp;
  background-image: url('./assets/engs-no-data-bg.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  animation: $fadein;
  cursor: default;

  &-title {
    width: max-content;
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0px;
    text-align: center;
    @include userSelectDisable;
  }

  &-subtitle {
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 300;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: center;
    white-space: pre-line;
    @include userSelectDisable;
  }

  &-first-step {
    margin-bottom: 24px;
    min-width: 900px;
    max-width: 900px;
    min-height: 232px;
    max-height: 232px;
    background-image: url('./assets/step1-1920.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @include userSelectDisable;
  }

  &-second-step {
    margin-bottom: 32px;
    min-width: 900px;
    max-width: 900px;
    min-height: 320px;
    max-height: 320px;
    background-image: url('./assets/step2-1920.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @include userSelectDisable;
  }

  &-footer {
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    white-space: nowrap;
    @include userSelectDisable;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1660px) {
  .engs-no-data {
    &-first-step {
      margin-bottom: 24px;
      min-width: 900px;
      max-width: 900px;
      min-height: 232px;
      max-height: 232px;
      background-image: url('./assets/step1-1660.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      @include userSelectDisable;
    }

    &-second-step {
      margin-bottom: 32px;
      min-width: 900px;
      max-width: 900px;
      min-height: 316px;
      max-height: 316px;
      background-image: url('./assets/step2-1660.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      @include userSelectDisable;
    }
  }
}

@media screen and (max-width: 1280px) {
  .engs-no-data {
    &-title {
      width: max-content;
      margin-bottom: 24px;
      font-size: 28px;
      font-weight: 500;
      line-height: 42px;
      letter-spacing: 0px;
      text-align: center;
      @include userSelectDisable;
    }

    &-subtitle {
      margin-bottom: 24px;
      font-size: 14px;
      font-weight: 300;
      line-height: 12px;
      letter-spacing: 0px;
      text-align: center;
      white-space: pre-line;
      @include userSelectDisable;
    }
    &-first-step {
      margin-bottom: 16px;
      min-width: 900px;
      max-width: 900px;
      min-height: 179px;
      max-height: 179px;
      background-image: url('./assets/step1-1280.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      @include userSelectDisable;
    }

    &-second-step {
      margin-bottom: 16px;
      min-width: 900px;
      max-width: 900px;
      min-height: 239px;
      max-height: 239px;
      background-image: url('./assets/step2-1280.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      @include userSelectDisable;
    }
  }
}
