.sound-wave-image-cropper {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__cropper-wrapper {
        height: 256px;
        width: 100%;
        position: relative;
    }

    &__cropper-container[class*="reactEasyCrop"] {
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }

    &__cropper-area[class*="reactEasyCrop"] {
        border: none;
        color: rgba(100, 111, 135, 0.80);
    }

    &__cropper-area--rect[class*="reactEasyCrop"] {
        border-radius: 16px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        text-align: center;
    }

    &__slider-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__slider {
        flex-grow: 1;
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        margin: 0;
        border-radius: 4px;
        height: 8px;
        cursor: pointer;

        &::-webkit-slider-runnable-track {
            height: 8px;
            background-color: $gray-100;
            border: none;
            border-radius: 4px;
        }

        &::-moz-range-track {
            height: 8px;
            background-color: $gray-100;
            border: none;
            border-radius: 4px;
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 16px;
            height: 16px;
            background-color: #537BFF;
            border: 1px solid #E3E7ED;
            border-radius: 50%;
            margin-top: -4px;
        }

        &::-moz-range-thumb {
            appearance: none;
            width: 16px;
            height: 16px;
            background-color: #537BFF;
            border: 1px solid #E3E7ED;
            border-radius: 50%;
            margin-top: -4px;
            box-sizing: border-box;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        appearance: none;
        border: none;
        background-color: transparent;
        padding: 0;
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

    &__button-icon {
        width: 16px;
        height: 16px;
    }

    &__empty-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        padding: 16px;
    }

    &__image-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 128px;
        height: 128px;
        background-color: $gray-100;
        border-radius: 50%;

        &--rect {
            border-radius: 16px;
        }
    }

    &__image-placeholder-icon {
        width: 64px;
        height: 64px;
    }

    &__dropdown-wrapper {
        display: flex;
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 1;
    }

    &__dropdown-button {
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border: none;
        border-radius: 50%;
        padding: 0;
        background-color: rgba(241, 244, 248, 0.30);
        cursor: pointer;
    }

    &__dropdown-button-icon {
        width: 24px;
        height: 24px;
    }

    &__dropdown {
        position: absolute;
        top: calc(100% + 8px);
        right: 0;
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 16px 0;
        width: 232px;
        border-radius: 8px;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 8px 0px rgba(71, 107, 132, 0.15);
        opacity: 0;
        visibility: hidden;
        transition: opacity 300ms ease-in-out, visibility 0s ease-in-out 300ms;

        &--opened {
            opacity: 1;
            visibility: visible;
            transition: opacity 300ms ease-in-out;
        }
    }

    &__dropdown-option {
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        width: 100%;
        padding: 8px 16px;
        border: none;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        background-color: transparent;
        cursor: pointer;

        &:hover {
            background-color: #F8FAFC;
        }
    }

    &__dropdown-option-icon {
        width: 16px;
        height: 16px;
    }
}
