.submenu {
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  align-self: flex-start;
  width: 0px;
  height: 100vh;
  overflow: hidden;
  opacity: 0;
  color: $white;
  background-color: #18222F;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  animation: menu-slide-in 400ms 100ms ease-out forwards;
  @include userSelectDisable;

  & ~ .App__scrollable {
    animation: page-content-animation 400ms 100ms ease-out forwards;
  }

  &__header {
    display: flex;
    flex-direction: column;

    &-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      padding: 0 0 20px 16px;
      border-bottom: 1px solid $gray-400;
      transform: scale(0);
      opacity: 0;
      animation: scale-submenu-item 800ms 100ms linear forwards;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-top: 32px;

    &__link {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      padding: 4px 16px;
      cursor: pointer;
      border-radius: 4px;
      transition: background-color 0.2s linear;
      transform: scale(0);
      opacity: 0;
      animation: scale-submenu-item 800ms 100ms linear forwards;

      &.disabled {
        @include userSelectDisable;
        pointer-events: none;

        .submenu__body__link-label {
          opacity: 0.5;
        }
      }

      &:hover {
        background-color: $gray-300;

        .team-submenu__body__link-chevron {
          opacity: 1;
        }
      }

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        transition: all 0.2s linear;
      }

      &-label {
        display: flex;
        flex-direction: row;

        &.error {
          &::after {
            content: '';
            display: flex;
            flex-direction: row;
            align-self: flex-start;
            margin-left: 2px;
            margin-top: 2px;
            min-width: 8px;
            max-width: 8px;
            min-height: 8px;
            max-height: 8px;
            border-radius: 8px;
            background-color: $error;
            animation: scaleInIcon 0.2s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
          }
        }
      }

      &-chevron {
        margin-left: auto;
        transition: all 0.15s linear;
        opacity: 0;
      }
    }
  }

  @keyframes page-content-animation {
    0% {
      width: 100%;
    }
    100% {
      width: calc(100% - 280px);
    }
  }

  @keyframes menu-slide-in {
    0% {
      width: 0px;
      opacity: 0.2;
      transform: translateX(-45%) scale(0.2, 1);
    }
    70% {
      opacity: 0.7;
      transform: translateX(0);
    }
    100% {
      opacity: 1;
      width: 208px;
      padding: 48px 8px;
      transform: translateX(0);
    }
  }

  @keyframes scale-submenu-item {
    0%,
    65% {
      opacity: 0;
      transform: scale(1, 0);
    }
    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
}
