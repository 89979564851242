.add-user {
  transition: all 0.6s;
  z-index: 1;
  display: flex;
  flex-direction: column;

  &__avatar {
    margin: 0 auto;
  }
  &__form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    &-block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 6px;
      &__input {
        margin: 1.3rem 0rem;
        width: 260px;
        display: block;
        &.error {
          margin-bottom: 18px;
          &-email {
            margin-bottom: 15px;
          }
        }
        &-last {
          margin: 1.3rem 0rem 2.2rem;
          &.error {
            margin-bottom: 50px;
          }
        }
      }
      &-avatar__upload__clip {
        margin: 0px !important;
      }
    }
    &__btn {
      width: 346px;
      margin: auto;
      align-self: center;
    }
  }
}
