.meeting-right-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 0;

    &__header {
        padding: 16px 24px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        border-bottom: 1px solid #E3E7ED;
        box-shadow: 0px 4px 16px 0px rgba(85, 106, 142, 0.12);
    }

    &__tabs-wrapper {
        display: flex;
        padding: 8px;
        gap: 8px;
        border: 1px solid #E3E7ED;
        border-radius: 8px;

        & > * {
            flex-basis: 50%;
            max-width: calc(50% - 4px);
        }
    }

    &__tab {
        appearance: none;
        border: none;
        border-radius: 4px;
        padding: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #FFFFFF;
        color: $black-new-design;
        font-family: inherit;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        cursor: pointer;
        transition: color 150ms ease-in-out, background-color 150ms ease-in-out;

        &:hover:not(:disabled) {
            background-color: $gray-100;
        }

        &:disabled {
            color: $gray-200;
            cursor: default;
        }

        &--active {
            color: #FFFFFF;
            background-color: $black-new-design;
            cursor: default;

            &:hover:not(:disabled) {
                background-color: $black-new-design;
            }

            &:disabled {
                color: $gray-200;
                background-color: $gray-100;
            }
        }
    }

    &__scrollbar {
        height: calc(100% - 81px);
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 24px;
        gap: 24px;
    }
}
