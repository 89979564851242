.remove-thread {
  position: relative;
  width: 100%;

  .hidden {
    opacity: 0;
    transition: none;
    pointer-events: none;
    @include userSelectDisable;
  }

  &-svg {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-tooltip {
    display: none;

    &.open {
      font-family: 'Open Sans';
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: absolute;
      height: 25px;
      width: max-content;
      left: calc(50% - 5px);
      top: 50%;
      transform: translate(-50%, -50px);
      border-radius: 4px;
      padding: 6px 10px 6px 10px;
      color: rgba(255, 255, 255, 0.9);
      background: $second-background;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      z-index: 15;
    }
  }
}
