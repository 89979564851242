.delete-user-modal {
  animation: animFadeIn 0.4s linear;
  font-size: 14px;
  line-height: 18.2px;
  &__bold {
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
  }
  &__btn {
    height: 50px;
    margin-top: 2rem;
  }
  &__btn-cancel {
    margin-top: 2rem;
  }
  &.deleted {
    animation: animDeleted 0.3s linear;
  }
  @keyframes animFadeIn {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes animDeleted {
    0% {
      opacity: 0.1;
      transform: scale(0.97);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}
