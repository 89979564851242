@import './components/EmailListItem/email-list-item';
@import './components/ReplyEmail/reply-email';

.emails-list {
  height: 100%;
  display: block;
  padding: 0;
  overflow: hidden;
  border-bottom-left-radius: inherit;
  border-right: 1px solid #e3e7ed;
  position: relative;

  &__scrollable {
    height: 100%;
  }

  & .simplebar-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
  }

  &__reply-section {
    width: 100%;
    min-height: 56px;
    padding: 0px 24px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    background: #ffffff;
    border-right: 1px solid #e3e7ed;
    border-bottom: 1px solid #e3e7ed;
    box-shadow: 0px 0px 12px 1px rgba(71, 107, 132, 0.15);
    animation: fadeIn 0.6s linear;

    &.not-connected {
      align-items: center;
      justify-content: space-between;
    }

    &__textarea {
      width: 100%;
      position: relative;

      &-plus-icon {
        position: absolute;
        cursor: pointer;
        top: 21px;
        left: 0;
        transform: translate(0, -50%);

        & path {
          transition: all 0.15s linear;
        }

        &:hover:not(.disabled) {
          & path {
            fill: #537bff;
          }
        }
      }

      &-control {
        padding: 12px 12px 10px 14px;
        border-radius: 8px;
        width: calc(100% - 16px);
        min-height: 41px;
        height: 41px;
        max-height: 300px;
        border: 1px solid transparent;
        text-align: left;
        color: #070b12;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;

        &:focus {
          border: 1px solid transparent;
        }

        &::placeholder {
          color: #94a3b8;
          padding-top: 0px;
        }
      }
    }

    &-send-icon {
      cursor: pointer;
      margin-bottom: 15px;
      // transition: all 0.2s linear;
      & path {
        transition: all 0.15s linear;
      }

      &.active {
        & path {
          fill: #537bff;
        }
      }

      &.disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.15;
      }

      &:hover:not(.disabled) {
        & path {
          fill: #446fff;
        }
      }
    }

    &-plus-icon {
      cursor: pointer;
      margin-bottom: 15px;

      &.disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.15;
      }
    }

    &__not-connected {
      display: flex;
      height: 32px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      color: #ad6e00;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      background: #fffcf5;

      &-icon {
        cursor: default;
      }

      &-text {
        cursor: default;
      }

      &-link {
        color: #537bff;
        transition: all 0.2s linear;

        &:hover {
          cursor: pointer;
          color: #0e37bd;
          text-decoration: underline;
        }
      }
    }
  }
}
