.permissions-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 32px;
    background-color: #10B981;
    position: sticky;
    top: 73px;
    z-index: 102;
    gap: 8px;
    color: #FFFFFF;
    overflow: hidden;
    animation: permissionsBannerAnimation 300ms ease-in-out;

    &__content-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__icon {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
    }

    &__text {
        font-size: 14px;
        line-height: 24px;

        &--bold {
            font-weight: 600;
        }

        &--nowrap {
            white-space: nowrap;
        }
    }

    &__dismiss-button {
        color: #FFFFFF;

        &:hover:not(:disabled) {
            background-color: rgba(255, 255, 255, 0.1);
        }

        &:active:not(:disabled) {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }

    &--closing {
        animation: permissionsBannerClosingAnimation 300ms ease-in-out;
    }

    @keyframes permissionsBannerAnimation {
        0% {
            transform: translateY(-100%);
            margin-bottom: -72px;
        }
        100% {
            transform: translateY(0);
            margin-bottom: 0;
        }
    }

    @keyframes permissionsBannerClosingAnimation {
        0% {
            transform: translateY(0);
            margin-bottom: 0;
        }
        100% {
            transform: translateY(-100%);
            margin-bottom: -72px;
        }
    }
}
