.contact-item {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    max-width: 100%;
    position: relative;
    z-index: 1;
    transition: z-index 0s ease-in-out 150ms;

    &__avatar {
        border-color: #E3E7ED;
    }

    &__text-wrapper {
        display: flex;
        align-items: center;
        flex-grow: 1;
        max-width: calc(100% - 32px);
        padding: 2px 4px;
        border-radius: 4px;
        gap: 4px;

        & > span:first-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &--editable {
            background-color: #F8FAFC;
            flex-grow: unset;
        }
    }
    
    &__text {
        font-size: 12px;
        line-height: 20px;
        color: $black-new-design;

        &--gray {
            color: $gray-300;
        }

        &--highlighted {
            background-color: rgb(83, 123, 255, 0.2);
        }
    }

    &__separator {
        width: 3px;
        min-width: 3px;
        height: 3px;
        margin: 0 4px;
        border-radius: 50%;
        background-color: $gray-300;

        & + span {
            min-width: max-content;
        }
    }

    &__button {
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        padding: 0;
        border: none;
        background-color: transparent;
        color: $gray-300;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        margin-left: auto;

        &:hover:not(:disabled) {
            cursor: pointer;
        }
    }

    &__button-icon {
        width: 16px;
        height: 16px;
    }

    &__info-dropdown {
        position: absolute;
        top: calc(100% - 2px);
        left: 50%;
        transform: translateX(-50%);
        width: 224px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        border-radius: 8px;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 24px 0px rgba(110, 134, 169, 0.22);
        visibility: hidden;
        opacity: 0;
        transition: opacity 150ms ease-in-out, visibility 0s ease-in-out 150ms;
        cursor: auto;
    }

    &__contact-details {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    &__avatar-wrapper {
        & > .sound-wave-avatar {
            width: 36px;
            height: 36px;
            min-width: 36px;
            min-height: 36px;
        }
    }

    &__contact-details-text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__contact-details-text {
        font-size: 10px;
        line-height: 16px;
        font-weight: 500;
        text-align: left;
        color: $black-new-design;

        &--gray {
            color: $gray-300;
        }

        &--title {
            font-size: 12px;
        }
    }

    &__associated-relationships-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &:hover {
        z-index: 2;

        .contact-item__info-dropdown {
            visibility: visible;
            opacity: 1;
            transition: opacity 150ms ease-in-out 500ms, visibility 0s ease-in-out 500ms;
        }
    }
}
