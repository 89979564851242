.my-activity {
  display: flex;
  flex-direction: column;

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 14px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $dark-grey-hover;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }
}
