.email-list-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
  border: 1px solid #e3e7ed;
  border-radius: 16px;
  background-color: #ffffff;
  margin: 0 24px;
  transition: border-color 150ms ease-in-out;

  &.with-btm-margin {
    margin-bottom: 54px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header-content {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__icons-wrapper {
    display: flex;
    align-items: center;

    & > :first-child {
      margin-right: -8px;
    }
  }

  &__envelope-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  &__envelope-icon-inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
    border: 1px solid #FFFFFF;
    width: 22px;
    min-width: 22px;
    height: 22px;
  }

  &__envelope-icon {
    width: 16px;
    height: 16px;
  }

  &__sender-info-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__sender-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  &__text {
    color: $gray-200;
    font-size: 12px;
    line-height: 20px;
    transition: color 150ms ease-in-out;

    &--darker {
      color: $gray-300;
    }

    &--bold {
      font-weight: 500;
      line-height: 16px;
    }
  }

  &__indicator {
    width: 16px;
    height: 16px;
    position: relative;
  }

  &__indicator-outer-circle,
  &__indicator-inner-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__indicator-outer-circle {
    width: 13px;
    height: 13px;
    border: 1px solid $gray-200;
    border-radius: 50%;
    transition: border-color 150ms ease-in-out;
  }

  &__indicator-inner-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 150ms ease-in-out;
  }

  &__content {
    font-size: 12px;
    line-height: 20px;
    white-space: pre-wrap;
    color: $gray-400;
    transition: color 150ms ease-in-out;

    & * {
      max-width: 100%;
    }
  }

  &:hover:not(.email-list-item--active, .email-list-item--loading) {
    cursor: pointer;
    border-color: $gray-200;

    .email-list-item__indicator-outer-circle {
      border-color: $gray-300;
    }

    .email-list-item__text {
      color: $gray-300;
    }
  }

  &--active {
    border-color: #10b981;

    .email-list-item__indicator-outer-circle {
      border-color: #10b981;
    }

    .email-list-item__indicator-inner-circle {
      background-color: #10b981;
    }

    .email-list-item__content {
      color: $black-new-design;
    }
  }

  &--unassigned {
    .email-list-item__envelope-icon-wrapper {
      background-color: $color-unassigned;
    }
  }

  &--userSide {
    .email-list-item__envelope-icon-wrapper {
      background-color: $color-user;
    }
  }

  &--prospectSide {
    .email-list-item__envelope-icon-wrapper {
      background-color: $color-prospect;
    }
  }

  &--loading {
    .email-list-item__sender-info-wrapper {
      gap: 4px;
    }

    .email-list-item__indicator-outer-circle {
      border-color: #E3E7ED;
    }
  }
}
