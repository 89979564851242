.confirmation-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;

    &__icon {
        width: 32px;
        height: 32px;
    }

    &__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        color: $black-new-design;
    }

    &__description {
        font-size: 14px;
        line-height: 24px;
        color: $gray-400;
    }

    &__button {
        appearance: none;
        padding: 0;
        margin-top: 8px;
        border: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-decoration: underline;
        color: $gray-300;
        cursor: pointer;

        &--blue {
            color: #537BFF;
        }
    }
}
