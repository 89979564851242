.member-contact {
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: $black-new-design;

  &__header {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__header-avatar-wrapper {
    min-width: 48px;
  }

  &__header-text-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__header-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  &__header-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    min-height: 16px;
    color: $gray-300;
  }

  &__edit-button {
    margin-left: auto;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__item-label {
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    color: $gray-300;
  }

  &__item-value {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  &__associated-accounts {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & .contact-item__text-wrapper {
      padding: 0px 4px;
    }
  }

  &__accounts-amount {
    color: #537BFF;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    padding: 0 4px;
  }
}
