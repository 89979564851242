.sound-wave-modal-header {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    color: $black-new-design;
    border-bottom: 1px solid #E3E7ED;

    &__button {
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;
        padding: 4px;
        width: 24px;
        height: 24px;
        margin-left: auto;
        cursor: pointer;
    }

    &__button-icon {
        width: 16px;
        height: 16px;
    }
}
