.timeline-chart-wrapper {
    display: flex;
    width: 100%;
    animation: dataFadeInAnimation 150ms ease-in-out forwards;

    @keyframes dataFadeInAnimation {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    &__chart {
        position: relative;
        overflow: hidden;
        width: calc(100% - 80px);
        display: flex;
    }
}
