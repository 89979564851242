.event-summary-topics {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;

    &__label {
        display: flex;
        align-items: center;
        border-radius: 4px;
        overflow: hidden;

        & > * {
            display: flex;
            align-items: center;
            min-height: 24px;
            padding: 4px 8px;
        }
    }

    &__label-text {
        color: #FFFFFF;
        background-color: #18222F;
    }

    &__label-value {
        border-left: 1px solid $gray-200;
        color: #E3E7ED;
        background-color: $gray-400;
    }
}
