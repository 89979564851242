.upcoming-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: background 0.2s linear;
  animation: fadeIn 0.4s linear;

  &:hover {
    background: #f1f4f8;

    .upcoming-row__accordion {
      padding: 8px 0;
      margin: 0px 0;
      opacity: 1;
      max-height: max-content;
      // transition: all 0.6s ease-in-out;

      &-overflow {
        height: 40px;
        transition: all 0.6s ease-in-out;
      }

      &-host {
        animation: fadeInLabels 0.6s;
        opacity: 1;
      }

      &-email {
        animation: fadeInLabels 0.6s;
        opacity: 1;
      }

      @keyframes fadeInLabels {
        0%,
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
          display: block;
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
    padding: 8px 16px 8px 16px;

    &__icon-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 25px;

      &-img {
        cursor: default;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      width: calc(100% - 72px);
      cursor: default;

      &__name {
        display: block;
        width: calc(100% - 18px);
        max-width: 100%;

        &-text {
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          color: #070b12;
          text-overflow: ellipsis;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }

      &-time {
        color: #646f87;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 3px;
      }
    }

    &__toggle {
      width: 36px;
      width: 28px;
      margin-right: 4px;
    }
  }

  &__accordion {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    overflow: hidden;
    background: #f8fafc;
    border-radius: 4px 0px 0px 4px;
    transition: all 0.6s ease-in-out;
    padding: 0;
    margin: 0;
    opacity: 0;
    // max-height: 0;

    &.expanded {
      padding: 16px 0;
      margin: 8px 0;
      opacity: 1;
      // max-height: max-content;
    }

    &-overflow {
      height: 0;
      width: 100%;
      padding: 0 16px;
      transition: all 0.6s ease-in-out;
    }

    &-host {
      overflow: hidden;
      height: 20px;
      color: #070b12;
      text-overflow: ellipsis;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      opacity: 0;
      animation: fadeOutLabels 0.3s;
    }

    &-email {
      overflow: hidden;
      height: 20px;
      color: #646f87;
      text-overflow: ellipsis;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      opacity: 0;
      animation: fadeOutLabels 0.3s;
    }

    @keyframes fadeOutLabels {
      0% {
        height: 20px;
        opacity: 0.5;
      }
      60% {
        height: 20px;
      }
      100% {
        opacity: 0;
        height: 0;
        max-height: 0;
      }
    }
  }
}
