.manage-participant-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    background-color: #FFFFFF;
    padding: 8px 16px;
    gap: 8px;

    &__content {
        display: flex;
        align-items: center;
        gap: 4px;
        overflow: hidden;
        white-space: nowrap;
        flex-grow: 0;
        flex-shrink: 1;
        min-width: 94px;

        & > span {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &--options {
            flex-grow: 1;
            flex-shrink: 0;
            justify-content: flex-end;
            max-width: calc(100% - 102px);
        }

        &--unassigned {
            justify-content: flex-end;
            width: calc(50% - 44px);
        }
    }

    &__host-icon {
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;
    }

    &__host-label {
        color: $gray-200;
        font-weight: 400;
        min-width: 26px;
    }

    &__email {
        color: $gray-300;

        &--not-detected {
            color: $gray-200;
        }
    }

    &__warning-icon {
        width: 12px;
        height: 12px;
    }

    &__disclaimer {
        font-size: 10px;
        font-weight: 500;
        color: $gray-300;
    }

    &__dropdown-button {
        appearance: none;
        border: none;
        padding: 0;
        background-color: #FFFFFF;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 4px;
        transition: background-color 150ms ease-in-out;
        cursor: pointer;

        & > svg {
            width: 16px;
            height: 16px;
        }

        &:hover {
            background-color: #F8FAFC;
        }

        &--active {
            background-color: #F1F4F8;

            &:hover {
                background-color: #F1F4F8;
            }
        }
    }

    &__dropdown-content.sound-wave-dropdown__options {
        padding: 8px;
        gap: 8px;
        width: 240px;
    }

    &__dropdown-option.sound-wave-dropdown__option {
        padding: 10px 8px;
        border-radius: 8px;
        
        &:not(button) {
            margin: 0;
            padding: 10px 8px 0;
            background-color: #FFFFFF;
            cursor: default;

            &:hover {
                background-color: #FFFFFF;
            }

            & .sound-wave-dropdown__dropdown-icon {
                display: none;
            }

            & .sound-wave-dropdown__suboptions-wrapper {
                margin-top: 14px;
                padding: 0;
                gap: 0;
            }
        }
    }

    &__dropdown-suboption.sound-wave-dropdown__option {
        padding: 10px 8px;
        border-radius: 8px;
        font-weight: 400;

        &:disabled {
            color: #646F87;
        }
    }

    &--unassigned {
        border: 1px solid #E3E7ED;
        border-radius: 8px;
        padding: 8px;
    }
}
