.open-thread-row {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: $secondary-color;
  cursor: pointer;

  &:hover {
    color: $secondary-color-hover;
  }
}
