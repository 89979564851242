.company {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #e3e7ed;
  border-radius: 8px;
  background-color: #ffffff;

  &__header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #e3e7ed;
    padding: 8px 24px;

    &__left-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      animation: $fadein;
      max-width: calc(100% - 50px);

      &-avatar {
        width: 44px;
        height: 44px;
        min-width: 44px;
        min-height: 44px;
        border-radius: 8px;
      }

      &__info {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        max-width: calc(100% - 60px);

        & > * {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-name {
          color: #070b12;
          font-size: 14px;
          font-weight: 400;
          line-height: 28px;
        }

        &-description {
          color: #646f87;
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;
          height: 24px;
        }
      }
    }

    &__right-section {
      display: flex;
      flex-direction: row;
      animation: $fadein;

      &-crm-button {
        display: flex;
        padding: 8px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        color: #070b12;
        color: #ffffff;
        background: #ff6325;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        border: none;
        outline: 1px solid #e3e7ed;
      }

      &-edit-button {
        display: flex;
        padding: 8px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        color: #070b12;
        margin-left: 18px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        border: none;
        outline: 1px solid #e3e7ed;
        background-color: #ffffff;
        transition: all 0.15s ease-in-out;

        &:hover:not(:disabled) {
          cursor: pointer;
          background-color: #f8fafc;
        }

        &:active:not(:disabled) {
          cursor: pointer;
          background-color: $gray-100;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    flex-direction: column;
    padding: 16px 24px;
    width: 100%;

    &-divider {
      display: flex;
      width: 1px;
      height: 100%px;
      background-color: #e3e7ed;
      margin: 16px 0;
    }
  }

  &__link {
    text-decoration: none;
    color: $black-new-design;

    &:hover {
      color: $black-new-design;
    }
  }
}
