.event-header-title {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    min-height: 32px;

    &__label {
        display: flex;
        align-items: center;

        &--hidden {
            display: none;
        }
    }

    &__content {
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
        padding: 3px 4px;
        border: 1px solid transparent;
        border-radius: 4px;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;

        &--hidden {
            opacity: 0;
            visibility: hidden;
        }

        &--input {
            position: absolute;
            left: 0;
            top: 0;
            appearance: none;
            border-color: #537BFF;
            background-color: #F5F7FF;
        }
    }

    &--editable {
        cursor: pointer;
    }
}
