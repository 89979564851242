.deals-table-skeleton {
  margin-top: 15px;
  width: 100%;
  padding-left: 1.5%;

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 55px;
    margin-top: 5px;

    @media screen and (max-width: 1366px) {
      margin-top: 2px;
    }

    &__left-section {
      width: 45%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &-first {
        width: 16px;
        height: 16px;
        margin: 0px 5px;
      }

      &-second {
        width: 20%;
        margin: 0px 5%;
      }

      &-third {
        width: 15%;
        margin: 0px 5%;
      }
    }

    &__central-section {
      width: 25%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &-fourth {
        width: 55%;
      }

      &-fifth {
        width: 35%;
        margin-left: 11%;
      }
    }

    &__right-section {
      width: 40%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-left: 5%;

      &-sixth {
        width: 20%;
      }

      &-seventh {
        width: 35%;
        margin: 0px 10%;
      }

      &-eighth {
        width: 10%;
        margin: 0px 5px;
      }
    }

    &:first-child {
      margin-bottom: 8px;
    }
  }

  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
  .ant-skeleton.ant-skeleton-active
    .ant-skeleton-content
    .ant-skeleton-paragraph
    > li {
    animation-duration: 1.5s;
    background: transparent;
    background-size: 100% 100%;

    &::after {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 25%,
        rgba(124, 144, 202, 0.24),
        rgba(255, 255, 255, 0) 63%
      );
      background-size: 100% 100%;
    }
  }
}
