@import './components/Toggle/toggle';

.app {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 2px 8px rgba(36, 45, 81, 0.2);
    border-radius: 10px;
    overflow: hidden;

    &__body {
        display: flex;
        gap: 12px;
        padding: 16px;
    }

    &__image {
        width: 196px;
        min-width: 196px;
        height: 133px;
        overflow: hidden;
        border-radius: 4px;

        & * {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        flex-grow: 1;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__buttons-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    &__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        color: $text-high-emp;
    }

    &__separator {
        height: 1px;
        width: 100%;
        border-radius: 1px;
        background-color: #EDF0F4;
    }

    &__text {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: $text-med-emp;
    }

    &__link {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        line-height: 24px;
        text-decoration: underline;
        color: #537BFF;
        transition: color 150ms ease-in-out;
        margin-top: auto;
        width: fit-content;

        & svg {
            width: 16px;
            height: 16px;

            & path {
                transition: fill 150ms ease-in-out;
            }
        }

        &:hover {
            color: #325CE6;
            text-decoration: underline;

            & svg path {
                fill: #325CE6;
            }
        }

        &:focus {
            text-decoration: underline;
        }
    }

    &__tooltip {
        width: fit-content;
    }

    &__icon {
        width: 24px;
        height: 24px;
    }

    &__button {
        appearance: none;
        display: inline-flex;
        align-items: center;
        gap: 4px;
        padding: 2px 7px;
        border: 1px solid $text-high-emp;
        border-radius: 4px;
        background-color: $text-high-emp;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        transition: color 150ms ease-in-out, background-color 150ms ease-in-out, border-color 150ms ease-in-out;
        width: fit-content;
        cursor: pointer;

        & svg {
            width: 16px;
            height: 16px;
        }

        &:disabled {
            cursor: default;
        }

        &:hover:not(:disabled) {
            background-color: #453e4f;
            border-color: #453e4f;
        }

        &--active {
            background-color: #10B981;
            border-color: #10B981;

            &:hover:not(:disabled) {
                background-color: #10B981;
                border-color: #10B981;
            }
        }

        &--outlined {
            color: $text-high-emp;
            background-color: transparent;

            &:hover:not(:disabled) {
                color: #453e4f;
                background-color: $main-background;
            }

            &:active:not(:disabled) {
                color: #453e4f;
                background-color: $tertiary-background;
            }
        }

        &--m {
            font-size: 14px;
            line-height: 20px;
            padding: 5px 11px;
        }
    }
}
