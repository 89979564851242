@import './components/EventHeaderTitle/event-frame-header-title';
@import './components/ManageParticipantsModal/manage-participants-modal';
@import './components/EventFrameHeaderSkeleton/event-frame-header-skeleton';

.event-frame-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  transition: z-index 0s ease-in-out 300ms;
  z-index: 1;
  background-color: #f8fafc;
  gap: 24px;
  animation: $fadein;

  &__left {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 50%;
    max-width: 50%;

    &__event-details {
      display: flex;
      flex-direction: column;
      width: calc(100% - 130px);

      &__top {
        display: flex;
        flex-direction: row;
        width: 100%;
        color: #070b12;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;

        & > span {
          display: block;
          justify-content: flex-start;
          align-items: center;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 50%;
        }

        &-separator {
          font-size: 16px;
          display: flex;
          align-items: center;
          font-weight: 500;
          color: $gray-200;
          margin: 0 8px;
        }

        & > div.event-frame-header-title {
          max-width: 50%;
        }
      }

      &__bottom {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-height: 28px;
        color: #070b12;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        gap: 4px;

        &-separator {
          font-size: 14px;
          display: flex;
          align-items: center;
          font-weight: 500;
          color: $gray-200;
          margin: 0 2px;
        }

        &-date,
        &-time,
        &-duration {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 3px;
          width: max-content;
          max-width: calc(30% - 3px);

          &-icon {
            cursor: default;
            min-width: 20px;
            width: 20px;
          }
        }

        &-value {
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 100%;
          cursor: default;
        }
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    width: 50%;
    max-width: 50%;
    gap: 16px;
    overflow: hidden;

    &__content {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 32px);
      border-radius: 1000px;
      border: 1px solid #e3e7ed;
      background: #f8fafc;
      height: 64px;
      padding: 16px;

      &__people {
        display: flex;
        flex-direction: row;
        width: calc(100% - 52px);
        gap: 16px;
      }

      &__host {
        display: flex;
        align-items: center;
        gap: 4px;

        & > .sound-wave-tooltip {
          cursor: pointer;
        }

        &-name {
          display: block;
          max-width: calc(100% - 63px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #070b12;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        }

        &-text {
          max-width: 81px;
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #646f87;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }
      }

      &__participants {
        width: 40%;

        &__avatars-group {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-right: 8px;

          &-counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 12px;
            white-space: nowrap;
            color: #070b12;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            gap: 4px;

            &-text {
              color: #646f87;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 28px;
            }

            &__tooltip {
              color: #646f87;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 28px;

              &-content {
                width: auto;
              }
            }
          }

          & > .sound-wave-avatar {
            margin-right: -6px;
          }
          & > .sound-wave-tooltip {
            margin-right: -6px;
            cursor: pointer;
          }
        }
        &-number {
          color: #070b12;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        }
        &-text {
          color: #646f87;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }
      }

      &__three-dot-menu {
        width: 40px;
        cursor: pointer;
        position: relative;

        &--disabled {
          cursor: default;
          pointer-events: none;
          @include userSelectDisable;
        }

        &__button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 1000px;
          border: 1px solid #e3e7ed;
          background: #fff;
        }

        &-icon {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(-20%, 20%);
          width: 12px;
          height: 12px;
          padding: 3px;
          background: #f0384a;
          border-radius: 50%;
        }
      }
    }
  }

  &__left-icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }

  &__right-icon {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }

  &__account-button-wrapper {
    position: relative;
    width: 59px;
    height: 28px;
  }

  &__account-button {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    appearance: none;
    border: none;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    color: $black-new-design;
    background-color: transparent;
    padding: 0 8px;
    transition: background-color 300ms ease-in-out, color 150ms ease-in-out;
    cursor: pointer;

    &:hover {
      color: #537bff;
      text-decoration: underline;
    }
  }

  &__account-button-tooltip {
    & svg path {
      transition: fill 150ms ease-in-out;
    }

    &.sound-wave-tooltip--opened svg path {
      fill: $gray-300;
    }
  }

  &__account-button-tooltip-content.sound-wave-tooltip__content-wrapper {
    width: auto;
  }

  &__account-logo {
    &.sound-wave-avatar {
      background-color: #f1f4f8;
      border-radius: 12px;
      min-height: 56px;
      min-width: 56px;
      max-width: 56px;
      max-height: 56px;
      border-width: 1px;
      border-color: #e3e7ed;
    }

    .sound-wave-avatar__placeholder-icon {
      width: 24px;
      height: 24px;
    }

    &.avatar-src-availbale {
      width: 100%;
      height: 100%;
    }
  }

  &__disclaimer {
    position: absolute;
    top: calc(100% + 16px);
    left: -8px;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    width: 384px;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    transition: opacity 300ms ease-in-out, visibility 0s ease-in-out 300ms;
  }

  &__disclaimer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 8px;
  }

  &__disclaimer-icon {
    width: 32px;
    height: 32px;
  }

  &__disclaimer-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    color: $black-new-design;
  }

  &__disclaimer-associate-button {
    appearance: none;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 14px;
    line-height: 24px;
    color: #537bff;
    text-decoration: underline;
    transition: color 150ms ease-in-out;
    cursor: pointer;

    &:hover {
      color: #2244b3;
    }
  }

  &__disclaimer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(7, 11, 18, 0.22);
    z-index: 99;
    visibility: hidden;
    opacity: 0;
    transition: opacity 300ms ease-in-out, visibility 0s ease-in-out 300ms;
  }

  &--with-disclaimer {
    transition: none;
    z-index: 103;

    & .event-frame-header__account-button {
      z-index: 100;
      background-color: #ffffff;
    }

    & .event-frame-header__disclaimer {
      visibility: visible;
      opacity: 1;
      transition: opacity 300ms ease-in-out;
      animation: disclaimerAnimation 300ms ease-in-out;
    }

    & .event-frame-header__disclaimer-overlay {
      visibility: visible;
      opacity: 1;
      transition: opacity 300ms ease-in-out;
      animation: disclaimerAnimation 300ms ease-in-out;
    }

    @keyframes disclaimerAnimation {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
}
