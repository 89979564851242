.sound-wave-modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-top: 1px solid #E3E7ED;

    &__button {
        position: relative;

        &--loading {
            & > span {
                visibility: hidden;
            }
        }

        &--ml-auto {
            margin-left: auto;
        }
    }

    &__button-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 16px;
        height: 16px;
        animation: spinAnimation 1s linear infinite;

        @keyframes spinAnimation {
            0% {
                transform: translate(-50%, -50%) rotate(0deg);
            }
            100% {
                transform: translate(-50%, -50%) rotate(360deg);
            }
        }
    }
}
