.navigation-tabs {
    display: flex;
    align-items: center;
    gap: 32px;

    &__item {
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: transparent;
        padding: 8px 0;
        border: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: $gray-300;
        transition: color 150ms ease-in-out;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: calc(100% + 12px);
            left: 0;
            width: 100%;
            background-color: transparent;
            height: 4px;
            transition: background-color 150ms ease-in-out;
        }

        &:hover:not(:disabled) {
            color: #18222F;
        }

        &:disabled {
            color: #18222F;
            cursor: default;

            &::after {
                background-color: #18222F;
            }    
        }
    }
}
