.table {
  z-index: -1;

  &__controls {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;

    &-seats__left {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 140px;
      border-radius: 6px;
      background-color: $tertiary-background;
      padding: 6px 11px;
      margin-left: 28px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      cursor: default;

      &-message__link {
        display: inline;
        color: #394577;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;

        &:hover {
          color: #6c81df;
          cursor: pointer;
        }
      }

      &-tooltip__icon {
        position: relative;
        padding-left: 5px;
        height: 15px;
        width: 15px;
        cursor: pointer;

        &-tooltip {
          position: absolute;
          background-color: white;
          color: #414042;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          display: inline;

          &.open {
            top: 20px;
            left: 5px;
            text-align: center;
            padding: 8px 12px;
            width: max-content;
            max-width: 240px;
            transition: 0.01s cubic-bezier(0.1, -0.93, 0.57, 0.1);
            z-index: 5;
          }

          &.closed {
            display: none;
          }

          &-link {
            display: inline;
            color: #394577;
            border-bottom: 1px solid #5969ab;
            &:hover {
              color: #6c81df;
              border-bottom: 1px solid #6c81df;
            }
          }
        }
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-left: 25px;
    &:first-child {
      margin: 0;
    }

    &__icon {
      border-radius: 50%;
      transition: all 0.4s;
      cursor: pointer;
      &:hover {
        background-color: $tertiary-background-hover;
      }
      &.disabled {
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }

    &-red {
      border-radius: 50%;
      transition: all 0.4s;
      cursor: pointer;
      &:hover {
        background-color: #ffdfe0;
      }
      &.disabled {
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }

  &__emails-analyzed {
    min-width: 80px;
  }

  &__column-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    &.centered {
      justify-content: center;
    }
  }
}

.options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  padding: 3px;
  position: relative;
  transition: all 0.15s linear;

  &:hover {
    background-color: $tertiary-background-hover;
    opacity: 1;
  }

  &__name {
    position: relative;
    left: -10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;

    &__avatar {
      min-width: 20px;
      min-height: 20px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 8px;
      cursor: default;
    }

    &-dot {
      min-width: 4px;
      min-height: 4px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
      background-color: #d8e0ff;
      cursor: pointer;

      &__superAdmin {
        background-color: #465eb5;
      }

      &__owner {
        background-color: #5f80f5;
      }

      &__dealmaker {
        background-color: transparent;
      }
    }
  }

  &__dot {
    background-color: $grey;
    min-width: 3px;
    min-height: 3px;
    width: 3px;
    height: 3px;
    margin: 1.5px 0;
    border-radius: 50%;

    &:hover {
      opacity: 1;
    }
  }

  &__popup {
    position: absolute;
    top: 32px;
    right: 0px;
    z-index: 1000;
  }

  &__red {
    color: $red-primary;

    &-disabled {
      color: darken($color: $red-primary, $amount: 35);
    }
  }

  &__option {
    min-width: 150px;
    padding: 12px 20px;
    display: flex;
    flex-direction: row;
    border-radius: 10px;

    &-disabled {
      color: #aaa;
    }
    &:hover {
      background-color: #424242;
      transition: 0.2s;
    }

    &.acc-op {
      background-color: $second-background;
      @include userSelectDisable;
      cursor: default;

      &:hover {
        background-color: $second-background !important;
      }
    }
  }

  &__disabled {
    color: #aaa;
    &:hover {
      color: #aaa;
    }
  }
}

.ant-table-thead > tr > th {
  text-transform: capitalize;
  color: $text-med-emp;
  font-weight: 600; // TODO: change if needed
  font-size: 14px;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.ant-table-tbody > tr {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  height: 50px;
}

.ant-table-tbody > tr > td {
  background: #fff;

  // &:last-child {
  //   div {
  //     &.options {
  //       &__dot {
  //         opacity: 0;
  //       }
  //     }
  //   }
  // }
}
.ant-table-tbody > tr:hover > td {
  background: $tertiary-background !important;

  &:last-child {
    div {
      opacity: 1;
      max-height: unset;
    }
  }
}
.ant-table-tbody > tr:active > td {
  background: #f4f4fe !important;
}

// .ant-table-header {
//   border-bottom: 1px solid #f0f1f9;
// }

.ant-table-container {
  background: #f9f9fc;
}

.ant-table {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  color: $text-high-emp;

  table {
    border-spacing: 0 6px;
    cursor: default;
  }
}

.ant-table-body {
  &::-webkit-scrollbar-track-piece:end {
    margin-bottom: 8px;
  }
  &::-moz-scrollbar-track-piece:end {
    margin-bottom: 8px;
  }
  &::-ms-scrollbar-track-piece:end {
    margin-bottom: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: $text-low-emp;
    border-radius: 4px;
    box-shadow: inset 0px 13px 0px 0px #f9f9fc;
  }
  &::-moz-scrollbar-thumb {
    background: $text-low-emp;
    border-radius: 4px;
    box-shadow: inset 0px 13px 0px 0px #f9f9fc;
  }
  &::-ms-scrollbar-thumb {
    background: $text-low-emp;
    border-radius: 4px;
    box-shadow: inset 0px 13px 0px 0px #f9f9fc;
  }
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  border: 0;
  padding: 5px 2px;
  white-space: nowrap;

  // &:nth-child(4) {
  //   text-align: center;
  //   max-width: 150px;

  //   div {
  //     margin: auto;
  //   }
  // }

  &:nth-child(5) {
    text-align: center;
    max-width: 200px;
  }
}

.ant-table-tbody > tr > td {
  &:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    padding: 13px 20px;
  }

  &:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding: 0.6rem 0.1rem 0.6rem 1rem;
  }
}

.ant-table-tbody > .disabled > td {
  // color: $text-low-emp;
  background-color: $white;
}

.ant-spin-container > ul {
  display: none;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  display: none;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border: 1px solid $text-high-emp;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $text-high-emp;
  border-color: $text-high-emp;
}

.ant-table-tbody > tr.ant-table-row-selected > td,
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: $tertiary-background-hover;
}

.ant-checkbox-inner::after {
  top: 40%;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 4px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0.6) translate(-105%, -55%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

.ant-checkbox-inner::after {
  left: 22%;
  width: 9px;
  height: 7px;
}

.ant-table-body {
  height: 580px;
  background: #f9f9fc;
  // padding-right: 13px;
  // margin-left: 13px;
}

.animated-row {
  animation: rowSlideIn 1.5s;
}

.info-tooltip {
  background-color: #fbfbff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  border-top-left-radius: 0;
  position: absolute;
  padding: 1.1rem 2.8rem;
  color: #000;
  text-transform: capitalize;
  z-index: 1000;
}

.invitation-status {
  height: 26px;
  width: 127px;
  text-transform: uppercase;
  font-family: 'Open Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  color: $text-primary;
  background-color: $tertiary-background;
  color: $text-high-emp;
  border-radius: 3.6rem;
  padding: 0.5rem 0;

  &__tooltip {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 5px;
    left: -225px;
    height: 40px;
    width: 230px;
    padding: 5px;
    border-radius: 5px;
    color: white;
    background-color: $second-background;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    pointer-events: none;
    font-size: 11px;
    text-transform: none;
    line-height: 130%;
    font-weight: 400;
    animation: fadeIn 0.4s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;

    &::before {
      content: '';
      width: 0;
      height: 0px;
      position: absolute;
      left: 100%;
      border-top: 16px solid transparent;
      border-left: 8px solid $second-background;
      border-bottom: 16px solid transparent;
    }
  }
}

@keyframes rowSlideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
