.signal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 12px;
  height: 0;
  cursor: default;
  transition: all 0.5s linear;
  opacity: 0;
  animation: hideSignal 0.5s linear 6.5s forwards;

  @keyframes hideSignal {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &.visible {
    opacity: 1;
    height: 40px;
  }

  &__overflow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  &-type-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    max-height: 24px;
    margin-left: -10px;
    cursor: default;
    background: #44536a;
    border-radius: 50%;
  }

  &-message {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}
