@import './components/Select/select';
@import './components/ImageFileUpload/image-file-upload';

.form-field {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    &__label {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: $black-new-design;
    }

    &__input {
        appearance: none;
        display: inline-flex;
        width: 100%;
        padding: 8px;
        border: 1px solid #E3E7ED;
        border-radius: 4px;
        background-color: #FFFFFF;
        color: $black-new-design;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;

        &:focus {
            outline: none;
            border-color: #537BFF;
        }

        &:disabled {
            background-color: $gray-100;
            color: $gray-200;
        }

        &::placeholder {
            color: $gray-200;
        }
    }

    &__error {
        color: #F0384A;
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
    }

    &__autocomplete-dropdown {
        position: absolute;
        width: 100%;
        left: 0;
        top: calc(100% + 8px);
        display: flex;
        flex-direction: column;
        gap: 12px;
        border-radius: 8px;
        padding: 12px 0;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 24px 0px rgba(110, 134, 169, 0.22);
    }

    &__autocomplete-option {
        appearance: none;
        display: inline-flex;
        align-items: center;
        padding: 4px 16px;
        background-color: transparent;
        border: none;
        cursor: pointer;

        &:hover, &:focus {
            background-color: #F8FAFC;
        }
    }

    &__country-code-select {
        & .sound-wave-select__field {
            justify-content: center;
            gap: 8px;

            & .sound-wave-select__text {
                width: auto;
            }
        }
    }

    &__country-flag-icon {
        min-height: 16px;
        min-width: 16px;
        border-radius: 50%;
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
    }

    &__country-name {
        max-width: calc(100% - 24px);
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--textarea {
        flex-grow: 1;

        .form-field__input {
            resize: none;
            flex-grow: 1;
        }
    }

    &--image {
        width: auto;
    }

    &--disabled {
        .form-field__label {
            color: $gray-300;
        }
    }

    &--invalid {
        .form-field__label {
            color: #F0384A;
        }

        .form-field__input {
            border-color: #F0384A;
            color: #F0384A;
        }
    }

    &--autocomplete {
        position: relative;
        z-index: 1;
    }
}
