@import './components/RangeAnalysis/range-analysis';
@import './components/RangeAnalysisGroup/range-analysis-group';
@import './components/UpperHandAnalysis/upper-hand-analysis';
@import './components/SentimentAnalysis/sentiment-analysis';
@import './components/AnalysisDisclaimer/analysis-disclaimer';

.event-analysis-compare {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 12px;
        border: 1px solid #E3E7ED;
        border-radius: 12px;
        gap: 8px;
        flex-grow: 1;
    }

    &__content-tooltip {
        text-align: center;
    }
}
