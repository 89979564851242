.sample-data {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  width: 565px;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: #4d4558;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.04);
  @include userSelectDisable;
  animation: fadein 0.8s ease;

  &-icon {
    margin-right: 5px;
  }

  &-text {
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
  }
}
