.sound-wave-dropdown {
    display: flex;
    align-items: center;

    &__options {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 232px;
        padding: 16px 0;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 12px 1px rgba(71, 107, 132, 0.15);
        border-radius: 8px;
        z-index: 1000002;
        animation: openAnimation 150ms ease-in-out;

        &--closing {
            animation: closeAnimation 150ms ease-in-out forwards;
        }

        @keyframes openAnimation {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    
        @keyframes closeAnimation {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }
    }

    &__option {
        appearance: none;
        border: none;
        padding: 8px 16px;
        display: inline-flex;
        flex-direction: column;
        background-color: transparent;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: $black-new-design;
        transition: all 150ms ease-in-out;
        cursor: pointer;

        &:disabled {
            cursor: default;
        }

        &:hover:not(:disabled)  {
            background-color: #F8FAFC;
        }

        &:active:not(:disabled) {
            background-color: $gray-100;
        }

        &--expanded {
            border-radius: 4px;
            padding: 8px;
            margin: 0 8px;
            background-color: #F8FAFC;

            &:active:not(:disabled) {
                background-color: #F8FAFC;
            }

            .sound-wave-dropdown__suboptions-wrapper {
                margin-top: 12px;
                padding: 16px 0;
            }

            .sound-wave-dropdown__dropdown-icon {
                transform: rotate(180deg);
            }
        }
    }

    &__option-label {
        display: inline-flex;
        align-items: center;
        width: 100%;

        & > svg {
            width: 16px;
            min-width: 16px;
            height: 16px;
            margin-left: 8px;
        }
    }

    &__option-label-text {
        flex-grow: 1;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__option-label-tooltip {
        max-width: 100%;
    }

    &__option-label-tooltip-content {
        width: auto;
        
        & .sound-wave-tooltip__content {
            background-color: #FFFFFF;
        }
    }

    &__dropdown-icon {
        transform: rotate(0deg);

        & > path {
            fill: $black-new-design;
        }
    }

    &__suboptions-wrapper {
        display: inline-flex;
        flex-direction: column;
        gap: 4px;
        margin-top: 0;
        padding: 0;
        width: 100%;
        border-radius: 4px;
        background-color: #FFFFFF;
        overflow: hidden;
        max-height: 0;
        cursor: default;
        transition: margin-top 150ms ease-in-out, 150ms ease-in-out, max-height 150ms ease-in-out;
    }
}
