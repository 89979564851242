.upper-hand-analysis {
    padding: 1px 0;
    display: flex;
    height: 50px;
    align-items: center;
    gap: 32px;

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 50%;

        &:first-child:after {
            position: absolute;
            content: '';
            top: 50%;
            transform: translateY(-50%);
            right: -16px;
            height: 28px;
            width: 1px;
            background-color: #E3E7ED;
        }

        &:first-child {
            .upper-hand-analysis__text {
                margin-right: 8px;
            }
        }

        &:last-child {
            .upper-hand-analysis__text {
                margin-left: 8px;
            }
        }
    }

    &__text {
        font-size: 12px;
        line-height: 20px;
    }

    &__icon {
        display: none;
        width: 16px;
        min-width: 16px;
        height: 16px;
        min-height: 16px;
    }

    &--unresolved {
        gap: 28px;

        .upper-hand-analysis__icon {
            display: block;
        }

        .upper-hand-analysis__item:first-child:after {
            display: none;
        }
    }
}