.analysis-disclaimer {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px;
    border-radius: 8px;
    background-color: #F8FAFC;
    margin-top: 8px;

    &__title {
        font-size: 10px;
        line-height: 16px;
        font-weight: 500;
        color: $gray-400;
    }

    &__icon {
        width: 16px;
        height: 16px;
    }
}
