@import './components/relations/relations';
@import './components/infoBar/animatedInfoBar';
@import './components/circleBar/circleProgressBar';
@import './components/arrowToLoadData/arrow';

.temporary-wrapper {
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 97%;

  .personalQ {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    min-height: 600px;
    margin: auto;
    margin-top: 24px;
    border-radius: 30px;
    align-self: center;
    box-shadow: 0px 0px 0px 25px white;
    background: white;
    cursor: default;

    &-date__select {
      height: 70px;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: -88px;
    }

    &__firstColumn {
      display: flex;
      flex-direction: column;
      min-width: 300px;
      width: 25%;
      margin-right: 10px;

      &-profile {
        display: flex;
        flex-direction: column;
        max-height: 100px;
        animation: $fadein;

        &-name {
          font-size: 24px;
          font-weight: 500;
          line-height: 31px;
        }

        &-company {
          display: flex;
          align-items: center;
          font-family: 'Open Sans';
          font-size: 14px;
          font-weight: 700;
          line-height: 19px;
          text-transform: capitalize;
          color: #29335c;
          font-weight: 600; // TODO: change if needed
          margin: 8px 0px 4px 0px;
        }

        &-companyLogo {
          width: 1.8rem;
          height: 1.8rem;
          border-radius: 50%;
          margin-right: 4px;
        }

        &-since {
          &-text {
            font-size: 14px;
            line-height: 130%;
            color: #777679;
          }

          &-date {
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;
          }
        }
      }
      &-analyze {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #fbfbff;
        padding: 15px;
        margin-top: 10px;
        border-radius: 30px;

        &-label {
          display: grid;
          grid-template-columns: 0.5fr 5fr 1fr;
          color: #777679;
          font-size: 14.5px;
          line-height: 30px;
          min-height: 40px;
          animation: $fadein;
          @include userSelectDisable;

          &-value {
            text-align: end;
            color: #29335c;
            font-weight: 500;
            font-size: 15px;
            margin-right: 5px;
          }
        }

        &-relations {
          font-weight: 500;
          text-align: left;
          align-self: center;
          background-color: #ffffff;
          min-height: 350px;
          max-height: 350px;
          min-width: 267px;
          width: 100%;
          border-radius: 30px;
          margin-top: 15px;
          padding: 20px;
          animation: $fadein;
          &__title {
            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
          }
        }
        &__skeleton-analyze {
          position: relative;
          height: 120px;
        }
      }
      &__skeleton-pers-info {
        position: relative;
        height: 120px;
      }
    }
    &__secondColumn {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #fbfbff;
      min-width: 542px;
      width: 45%;
      border-radius: 30px;
      padding: 20px;

      &-infobars {
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &.loading {
          max-height: 800px;
          display: flex;
        }
        &-header {
          margin-bottom: -20px;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
        }
      }

      &-overallcompetence {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 20px;
        position: relative;

        &-title {
          position: absolute;
          top: 41px;
          left: 36px;
          display: flex;
          flex-direction: row;
          align-items: center;
          min-width: 220px;
          font-size: 10px;
          font-weight: 500;
          line-height: 13px;
          color: #777679;
          animation: $fadein;
          &-img {
            cursor: pointer;
            margin-left: 6px;
            width: 12px;
            height: 12px;
          }

          &-popup {
            position: absolute;
            background-color: white;
            color: #414042;
            padding: 1rem;
            margin-left: 1.3rem;
            border-radius: 20px;
            box-shadow: 0px 0px 20px rgb(218, 218, 218);
            font-weight: 400;
            font-size: 14px;
            line-height: 19.6px;

            &.open {
              top: 14px;
              left: 73px;
              padding: 15px;
              min-width: 200px;
              max-width: 200px;
              border-radius: 15px;
              border-top-left-radius: 3px;
              transition: 0.01s cubic-bezier(0.1, -0.93, 0.57, 0.1);
            }
            &.closed {
              opacity: 0;
              transition: none;
            }
          }
        }
        &-skeleton {
          width: 100%;
          margin-top: 32px;
          &-title {
            margin-left: 10px;
          }
          &-circle {
            margin: 11px 0 15px;
          }
        }

        &-summary {
          background-color: #ffffff;
          margin-top: 10px;
          padding: 20px;
          min-width: 230px;
          width: 100%;
          min-height: 360px;
          border-radius: 30px;

          &-title {
            font-weight: 500;
            font-size: 10px;
            line-height: 13px;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: #394577;
            animation: $fadein;
          }
          &-list {
            position: relative;
            width: 100%;
            padding-left: 22px;
            list-style-image: url(./icons/list_icon.svg);
            animation: $fadein;

            &-item {
              font-size: 14px;
              line-height: 19.6px;
              color: #777679;
              margin-bottom: 12px;
            }

            &-noData {
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0em;
              text-align: left;
              color: #777679;
            }
          }
          &__skeleton-block {
            height: 50px;
            position: relative;
            min-height: 110px;
          }
        }
      }
    }

    &__thirdColumn {
      display: flex;
      flex-direction: column;
      background-color: #fbfbff;
      min-width: 220px;
      width: 20%;
      margin-left: 10px;
      border-radius: 30px;
      padding: 20px;
      position: relative;
      &-date__select {
        position: absolute;
        top: -15%;
        transform: translate(10%, 17%);
      }
      &-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        height: 55px;
        width: 100%;
        animation: $fadein;

        &-title {
          margin-top: 0px;
          display: flex;
          flex-direction: row;

          &__tooltip-section {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &-icon {
              cursor: pointer;
              margin-left: 6px;
              width: 12px;
              height: 12px;
            }

            &-tooltip {
              position: absolute;
              background-color: white;
              color: #414042;
              font-weight: 400;
              font-size: 14px;
              line-height: 19.6px;
              z-index: 101;

              &.open {
                top: 100%;
                right: 59%;
                padding: 15px;
                min-width: 277px;
                border-radius: 15px;
                border-top-right-radius: 3px;
                transition: 0.01s cubic-bezier(0.1, -0.93, 0.57, 0.1);
                box-shadow: 0px 0px 20px #dadada;
              }

              &.closed {
                opacity: 0;
                visibility: hidden;
                transition: none;
              }

              &-text {
                font-size: 14px;
                font-weight: 400;
                line-height: 19.6px;
                font-weight: 400;

                &-bold {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 19.6px;
                }
              }
            }
          }
        }
        &-coming__soon {
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: center;
          width: 160px;
          height: 20px;
          background-color: #fff5d3;
          border-radius: 10px;
          color: #eba53a;
          font-weight: 500;
          font-size: 10px;
          line-height: 10px;
          margin-bottom: 5px;
          animation: $fadein;
        }
      }
      &-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        animation: $fadein;
        &-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          &-stats {
            width: 47%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            margin-top: 15px;
            padding: 10px 0px;
            border-radius: 16px;
            min-height: 76px;
            min-width: 81px;
            color: #c0ccdf;
            background: white;
            &-label {
              text-transform: uppercase;
              font-size: 11px;
              font-weight: 500;
              line-height: 13px;
            }
            &-value {
              font-size: 20px;
              font-weight: 500;
              line-height: 26px;
            }
          }
        }

        &-schedule {
          width: 100%;
          margin-top: 15px;
          padding: 10px;
          background: white;
          border-radius: 16px;
          &-first {
            height: 209px;
            width: 100%;
            background-image: url(./icons/firstSchedule1920.svg);
            background-repeat: no-repeat;
          }
          &-second {
            height: 180px;
            width: 100%;
            background-image: url(./icons/secondSchedule1920.svg);
            background-repeat: no-repeat;
          }
        }
      }
      &-skeleton__wrapper {
        &-stats {
          display: flex !important;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          margin: 15px 0px 17px;
          &-details {
            min-width: 81px;
            width: 47%;
            height: 76px;
            border-radius: 16px;
            background-color: white;
            padding: 10px;
          }
        }
        &-first__schedule {
          width: 100%;
          height: 229px;
          border-radius: 16px;
          background-color: white;
          padding: 9px 11px;
          &-row {
            display: flex;
            flex-direction: row;
            margin: 15px 0px 12px;
          }
          &-last__child {
            display: flex;
          }
          .ant-skeleton-content .ant-skeleton-title {
            margin-top: 0px;
          }
        }
        &-second__schedule {
          width: 100%;
          height: 200px;
          border-radius: 16px;
          background-color: white;
          padding: 15px 0px 13px 11px;
          margin-top: 15px;
          &-row {
            display: flex;
            flex-direction: row;
            margin: 2px 0px;
            padding-left: 10px;
          }
        }
      }
    }
    @keyframes gradientAnimationIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @media screen and (min-width: ($desktop2000 - 100)) {
      .personalQ__secondColumn-overallcompetence-summary {
        padding: 25px;
      }
    }
    @media screen and (max-width: $desktop1440) {
      &__secondColumn {
        &-infobars {
          width: 50%;
        }
        &-overallcompetence-summary {
          padding: 20px;
          &-list-item {
            line-height: 18.6px;
            margin-bottom: 12px;
          }
        }
      }
    }
    @media screen and (min-width: $desktop1440) {
      &__thirdColumn {
        min-width: 340px;
      }
    }
    @media screen and (max-width: ($desktop1280 + 1)) {
      &__thirdColumn {
        padding: 20px 10px;
        &-body-schedule-first {
          background-image: url(./icons/firstSchedule1280.svg);
        }
        &-body-schedule-second {
          background-image: url(./icons/secondSchedule1280.svg);
        }
      }
    }
    @media screen and (max-width: $mobile) {
      &__firstColumn {
        width: 100%;
        margin-bottom: 10px;
        &__skeleton-pers-info {
          margin-left: 7rem;
        }
        &-profile {
          align-items: flex-start;
        }
        &-analyze {
          padding: 1rem;
          margin-left: 2rem;
          &-relations {
            width: 250px;
          }
        }
      }
      &__secondColumn {
        flex-direction: column;
        min-width: 250px;
        padding: 20px;
        width: 100%;
        margin-bottom: 10px;
        &-infobars {
          width: 100%;
          &-header {
            margin-bottom: 0px;
          }
        }
        &-overallcompetence {
          width: 100%;
          padding-left: 0;
        }
      }
      &__thirdColumn {
        width: 100%;
        padding: 20px;
      }
    }
  }
}
