.teams-table {
  z-index: -1;
  animation: $fadein;
  &__role {
    margin-right: -9px;
  }
  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 1rem 2rem 0rem;

    &-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 15%;
      &__delete-members {
        padding-right: 70px;
      }
    }
  }

  &__icon {
    position: relative;

    &__icon {
      border-radius: 50%;
      transition: all 0.4s;
      cursor: pointer;
      &:hover {
        background-color: #ebeeff;
      }
    }
  }

  &__emails-analyzed {
    min-width: 9rem;
  }
}

.teams-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  padding: 3px;
  position: relative;
  transition: all 0.15s linear;
  cursor: pointer;

  &:hover {
    background-color: #dadfff;
    opacity: 1;
  }

  &.hidden {
    display: none;
  }

  &__member-name {
    position: relative;
  }

  &__dot {
    background-color: $grey;
    min-width: 3px;
    min-height: 3px;
    width: 3px;
    height: 3px;
    margin: 1.5px 0;
    border-radius: 50%;
  }

  &__popup {
    position: absolute;
    top: 31px;
    right: -2px;
    z-index: 1000;

    &.open {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__red {
    color: $red-primary;

    &-disabled {
      color: darken($color: $red-primary, $amount: 35);
    }
  }

  &__option {
    min-width: 160px;
    padding: 12px 20px;
    display: flex;
    flex-direction: row;
    border-radius: 10px;

    &-disabled {
      color: #aaa;
    }

    &:hover {
      background-color: #424242;
      transition: 0.2s;
    }
  }

  &__disabled {
    color: #aaa;

    &:hover {
      color: #aaa;
    }
  }
}
