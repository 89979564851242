.meeting-participant-tooltip {
    &.sound-wave-tooltip--opened {
        & .sound-wave-avatar {
            background-color: #646F87;
            border-color: #646F87;
        }

        & .sound-wave-avatar--prospectSide {
            background-color: #D21961;
            border-color: #D21961;
        }

        & .sound-wave-avatar--userSide {
            background-color: #271BA4;
            border-color: #271BA4;
        }
    }

    &__tooltip {
        width: auto;

        & .sound-wave-tooltip__arrow-icon {
            display: none;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        line-height: 20px;
    }

    &__title {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 4px 0;
        line-height: 16px;
        font-weight: 500;

        & span:not(:first-child) {
            color: $gray-300;
        }
    }

    &__divider {
        width: 4px;
        height: 4px;
        background-color: $gray-300;
        border-radius: 50%;
    }
}
