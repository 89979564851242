.event-pagination {
    display: flex;
    padding: 0 8px;
    justify-content: center;
    align-items: center;
    gap: 16px;

    &__button {
        & > svg {
            width: 12px;
            height: 12px;
            transition: fill 150ms ease-in-out;
        }

        &:disabled {
            & > svg path {
                fill: $gray-200;
            }
        }
    }

    &__event-name {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: $black-new-design;
        max-width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
