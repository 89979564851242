.invoices-list {
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    border: 1px solid #E3E7ED;
    border-radius: 8px;

    &__row {
        display: flex;
        padding: 4px 16px;
        gap: 16px;

        & > * {
            flex-basis: calc(100% / 6);
        }

        &--header {
            padding: 8px 0;
            margin: 0 16px;
            border-bottom: 1px solid #E3E7ED;

            & > .invoices-list__cell {
                font-weight: 500;
                color: $black-new-design;
            }
        }
    }

    &__cell {
        font-size: 14px;
        line-height: 24px;
        color: $gray-300;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__content {
        position: relative;
    }

    &__scrollable {
        padding: 4px 0;
        max-height: calc(100vh - 416px);
    }

    &__empty-content {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 160px;
        color: $gray-300;
    }

    &__link {
        text-decoration: none;
        color: #537BFF;
        transition: color 150ms ease-in-out;

        &:visited {
            color: #537BFF;
        }

        &:hover {
            color: #2244B3;
        }
    }
}
