.auth-param-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  position: relative;

  &-name-input {
    margin-left: 20px;
    min-width: 160px;
    max-width: 160px;
  }

  &-value-input {
    margin-left: 20px;
    min-width: 160px;
    margin-right: 12px;
  }

  form {
    display: flex;
    flex-direction: row;
  }

  &-type {
    width: 20%;
    min-width: max-content;
    margin-right: 12px;

    &-error {
      position: absolute;
      bottom: -16.5px;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0px;
      text-align: left;
      color: $error;
    }
  }

  &-name {
    width: 20%;
    min-width: max-content;
    margin-right: 12px;
  }

  &-value {
    width: calc(60% - 38px);
    min-width: max-content;
    margin-right: 14px;
  }

  &__remove-row-icon {
    min-width: 20px;
    width: 20px;
    min-height: 20px;
    height: 20px;
    cursor: pointer;
    transition: all 0.1s linear;

    &:hover {
      filter: brightness(0.3);
    }
  }
}
