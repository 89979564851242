.add-info {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  &-enter {
    opacity: 0.4;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 0.3s;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 0.2s;
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 103;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &__popup {
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 700px;
    width: 70vw;
    height: 70vh;
    background-color: #dab8b8;
    border-radius: 6px;
    z-index: 1000;

    &__left-section {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 75%;
      background-color: #ffffff;
      padding: 80px 30px;
      border-radius: 4px 0px 0px 4px;

      &-title {
        width: 100%;
        font-size: 24px;
        font-weight: 600; // TODO: change if needed
        line-height: 36px;
        letter-spacing: 0px;
        text-align: left;
        color: #4d4558;
      }

      &__form-section {
        width: 100%;
        cursor: default;

        &-input {
          display: block;

          &:not(:first-child) {
            margin-top: 22px;
          }
        }

        &-select {
          width: 100%;
          margin-top: 22px;
        }
      }

      &__footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &-text {
          font-family: 'Poppins', sans-serif;
          font-size: 10px;
          line-height: 15px;
          letter-spacing: 0px;
          text-align: left;
          color: #8189a1;
        }

        &-button {
          display: flex;
          width: 120px;
          padding: 6px 12px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background: #4d4558;
          color: #ffffff;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          transition: all 0.1s linear;
          cursor: pointer;

          &:hover {
            filter: brightness(1.05);
          }

          &.disabled {
            background: #7d7d7d;
            pointer-events: none;
            opacity: 0.9;
            @include userSelectDisable;

            &:hover {
              filter: none;
            }
          }
        }
      }
    }

    &__right-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      min-width: 30%;
      width: 30%;
      max-width: 400px;
      background: url(./assets/self-sidebar-bg-1920.png);
      background-size: cover;
      background-position: center;
      border-radius: 0 4px 4px 0;

      &-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        transition: all 0.1s linear;
        cursor: pointer;

        &:hover {
          filter: brightness(0.9);
        }
      }
    }
  }
}
