.warn-modal {
  z-index: 1001;
  width: 496px;
  height: 310px;
  @include userSelectDisable;

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;

    &-title {
      color: #4d4558;
      font-size: 20px;
      font-weight: 500;
      line-height: normal;
      text-align: center;
    }

    &-subtitle {
      margin-top: 16px;
      color: #646c83;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
    }

    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 32px;
      padding: 10px 86px;
      border-radius: 8px;
      background-color: #4d4558;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
      transition: all 0.1s linear;

      &:hover {
        background-color: darken($color: #4d4558, $amount: 4);
      }
    }

    &__no-thanks {
      margin-top: 16px;
      display: inline-flex;
      color: #8189a1;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;

      &-link {
        margin-left: 4.5px;
        cursor: pointer;
        text-decoration: underline;
        transition: all 0.1s linear;

        &:hover {
          color: darken(#8189a1, 7);
        }
      }
    }

    &__footer {
      &-checkbox {
        cursor: pointer;
        margin: 0px 15px 0px 0px;
        min-width: 18px;
        min-height: 18px;
      }

      &-text {
        color: #8189a1;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}
