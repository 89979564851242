.chart-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 24%;
  min-height: 345px;
  max-height: 345px;
  padding: 24px;
  color: #4d4558;
  background-color: #ffffff;
  box-shadow: 0px 2px 8px rgba(36, 45, 81, 0.2);
  border-radius: 12px;
  animation: $fadein;

  &:hover {
    z-index: 102 !important;
  }

  &.invisible {
    visibility: hidden;
    transition: none;
  }

  &.team-q-chart {
    width: 33%;
    min-height: 345px;
    max-height: 345px;
    padding: 24px;
    color: #4d4558;
    background-color: #ffffff;
    box-shadow: none;
    border-radius: 0;
    animation: $fadein;
    margin: 0;

    &:first-child {
      border-radius: 12px 0px 0px 12px;
    }

    &:last-child {
      border-radius: 0px 12px 12px 0px;
    }
  }

  &__title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    &__title {
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
    }

    &__tooltip-block {
      display: flex;
      position: relative;
      width: 14px;
      height: 14px;
      cursor: pointer;

      &__tooltip {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, 13px);
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        padding: 10px;
        width: max-content;
        max-width: 167px;
        min-height: 20px;
        border-radius: 12px;
        color: #ffffff;
        background: $second-background;
        box-shadow: 0px 4px 10px rgba(57, 69, 119, 0.25);
        border-radius: 4px;
        z-index: 102;
        animation: tooltip-enter 0.15s ease-in-out;

        &.light {
          top: 50%;
          left: 50%;
          transform: translate(-50%, calc(-100% - 22px));
          border-radius: 8px;
          border: 1px solid #e3e7ed;
          background: #ffffff;
          min-height: 28px;
          max-width: 255px;
          padding: 8px 16px;
          box-shadow: 0px 1px 2px 0px rgba(71, 107, 132, 0.3),
            0px 2px 6px 0px rgba(71, 107, 132, 0.15);
          color: #070b12;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;

          &::after {
            content: url(../../icons/tooltip-arrow-icon.svg);
            position: absolute;
            left: 50%;
            bottom: -16.5px;
            transform: translateX(-50%);
            border: none;
            outline: none;
            z-index: 111;
          }

          &.top-left {
            top: 50%;
            left: 0;
            transform: translate(-92%, calc(-100% - 22px));

            &::after {
              content: url(../../icons/tooltip-arrow-icon.svg);
              position: absolute;
              left: unset;
              right: 0;
              bottom: -16px;
              transform: translateX(-2px);
              border: none;
              outline: none;
              z-index: 111;
            }

            &.talk-time-ratio {
              max-width: 295px;

              &::after {
                transform: translateX(-5px);
              }
            }
          }
        }

        &.closed {
          display: none;
        }
      }

      &-coming-soon {
        position: absolute;
        top: -5px;
        right: -3px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-width: 71px;
        min-height: 20px;
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        background-color: #ffff;
        border-radius: 4px;
      }
    }
  }

  &__subtitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #646c83;
    margin-bottom: 16px;
    padding-bottom: 4px;
    border-bottom: 1px solid #edf0f4;

    &.no-border {
      border: none;
    }
  }

  &__warn-wrap {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 20px;
    min-width: 87px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 5px;
    box-shadow: 0px 4px 10px rgba(57, 69, 119, 0.25);
    border-radius: 4px;
    background-color: #ffffff;
    z-index: 2;

    &__message {
      color: #4d4558;
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
      animation: $fadein;
    }

    &__tooltip {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 14px;
      height: 14px;
      margin-left: 4px;
      cursor: pointer;

      &-icon {
        position: absolute;
        top: 50%;
        left: 50;
        transform: translate(0%, -50%);
        width: 11px;
        height: 11px;
      }

      &-message {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 15px);
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        padding: 8px;
        width: max-content;
        max-width: 156px;
        min-height: 20px;
        border-radius: 12px;
        background-color: #ffffff;
        box-shadow: 0px 1px 2px 0px rgba(71, 107, 132, 0.3),
          0px 2px 6px 0px rgba(71, 107, 132, 0.15);
        color: #070b12;
        box-shadow: 0px 4px 10px rgba(57, 69, 119, 0.25);
        border-radius: 4px;
        z-index: 100;
        animation: tooltip-enter 0.15s ease-in-out;

        &.closed {
          display: none;
        }
      }
    }
  }

  &__empty-wrap {
    position: relative;
    width: 100%;
    height: 100%;

    &__icon {
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%, -73%);
    }
  }

  &__title-tooltip {
    margin-right: 4px;
  }

  .sound-wave-tooltip--opened {
    svg {
      fill: #646C83;

      & path:last-child {
        fill: #FFFFFF;
      }
    }
  }
}
