@import './components/MeetingRecordsList/meeting-records-list';

.meeting-records {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        color: $black-new-design;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
    }
}
