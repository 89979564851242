.profile-team-modal {
  display: flex;
  flex-direction: column;
  min-width: 430px;
  height: 493px;
  background-color: $white;
  padding: 40px 8px 0px 40px;
  overflow: hidden;

  &-shadow-overlay {
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    height: 20px;
    box-shadow: inset 14px -14px 9px 0px rgb(255 255 255 / 62%);
    z-index: 11;
    transition: none;
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 403px;
    height: 403px;
    overflow-x: hidden;
    overflow-y: auto;
    transition: none;
    animation: fadeIn 0.8s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;

    &__member-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-width: 248px;
      height: 70px;
      border: 0;
      padding: 16px 0;
      background-color: inherit;
      border-radius: 10px;

      &__img-section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        background-color: $tertiary-background;
        border-radius: 50%;
        overflow: hidden;

        &-avatar {
          width: 48px;
          height: 48px;
          min-width: 48px;
          min-height: 48px;
          background-color: $tertiary-background;
          border-radius: 50%;
          overflow: hidden;
          z-index: 1;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: calc(100% - 50px);
        height: 50px;
        margin-left: 15px;

        &-name {
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: $text-high-emp;

          &.team-leader {
            position: relative;

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: -10px;
              transform: translate(0, -50%);
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: $secondary-color;
            }
          }
        }

        &-role {
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: max-content;
          max-width: calc(100% - 20px);
          font-size: 14px;
          line-height: 18px;
          color: $grey;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
        }
      }
    }
  }
}
