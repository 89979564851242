.date-picker-header {
    display: flex;
    padding: 4px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    font-size: 12px;
    line-height: 16px;
    color: $black-new-design;

    &__button {
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        width: 24px;
        height: 24px;
        cursor: pointer;

        &:disabled {
            opacity: 0.5;
        }
    }

    &__icon {
        width: 16px;
        height: 16px;
    }
}
