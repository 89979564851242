.meeting-records-list {
    display: flex;
    flex-direction: column;
    border: 1px solid #E3E7ED;
    border-radius: 8px;
    background-color: #FFFFFF;
    font-size: 14px;
    line-height: 24px;
    color: $black-new-design;
    overflow: hidden;

    &__scrollable {
        max-height: calc(100vh - 252px);
    }

    &__empty-content {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: calc(100vh - 252px);
        color: $gray-300;
    }

    &__row {
        display: flex;
        align-items: center;
        transition: box-shadow 150ms ease-in-out;

        &:not(:last-child) {
            border-bottom: 1px solid #E3E7ED;
        }

        &:not(.meeting-records-list__row--header):hover {
            box-shadow: 0px 0px 4px 3px rgba(71, 107, 132, 0.08);
        }

        &--header {
            color: $gray-400;
            border-bottom: 1px solid #E3E7ED;
            box-shadow: 0px 2px 8px 0px rgba(91, 127, 161, 0.10);

            & > .meeting-records-list__cell {
                padding: 16px 24px;
                gap: 6px;
                justify-content: flex-start;
                background-color: #F8FAFC;
                transition: background-color 150ms ease-in-out;
                user-select: none;
                color: $gray-400;
                font-weight: 600;
                cursor: pointer;

                &:hover {
                    background-color: $gray-100;
                }

                &--disabled {
                    cursor: default;

                    &:hover {
                        background-color: #F8FAFC;
                    }
                }
            }
        }
    }

    &__cell {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        flex-grow: 1;
        flex-basis: 0;
        overflow: hidden;

        & > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__button {
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 7px;
        border: 1px solid #E3E7ED;
        border-radius: 8px;
        background-color: #FFFFFF;
        color: $black-new-design;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        cursor: pointer;
        transition:
            background-color 150ms ease-in-out,
            border-color 150ms ease-in-out,
            color 150ms ease-in-out;

        &:hover:not(:disabled) {
            background-color: #F8FAFC;
        }

        &:active:not(:disabled) {
            background-color: $gray-100;
        }

        &:disabled {
            cursor: default;
            background-color: $gray-100;
            border-color: $gray-100;
            color: $gray-300;
        }

        &--in-progress:disabled {
            background-color: #F6FEFA;
            border-color: #F6FEFA;
            color: #10B981;
        }

        &--error:disabled {
            background-color: #FFF5F6;
            border-color: #FFF5F6;
            color: #B40D1E;
        }
    }

    &__source-icon {
        width: 32px;
        min-width: 32px;
        height: 32px;
    }

    &__title {
        appearance: none;
        padding: 0;
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        gap: 12px;
        max-width: 100%;
        cursor: pointer;

        &:disabled {
            cursor: default;
        }

        & > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__account {
        appearance: none;
        display: inline-flex;
        align-items: center;
        gap: 12px;
        padding: 0;
        border: none;
        background-color: transparent;
        font-size: 14px;
        line-height: 24px;
        color: $black-new-design;
        cursor: pointer;

        &:hover {
            .meeting-records-list__edit-icon {
                opacity: 1;
            }
        }

        &:disabled {
            cursor: default;
        }
    }

    &__account-logo {
        border-color: #E3E7ED;
    }

    &__sort-icon {
        width: 12px;
        height: 12px;

        &--desc {
            & > path:last-child {
                fill: $gray-400;
            }
        }

        &--asc {
            & > path:first-child {
                fill: $gray-400;
            }
        }
    }

    &__edit-icon {
        width: 16px;
        height: 16px;
        opacity: 0;
        transition: opacity 150ms ease-in-out;
    }
}
