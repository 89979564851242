.non-paid-modal {
  z-index: 1005;
  width: 496px;
  height: 262px;
  padding: 0px;
  border-radius: 16px;
  border: 1px solid #e0e7f0;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(36, 45, 81, 0.2);
  @include userSelectDisable;

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px 36px;

    &-title {
      color: #4d4558;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      line-height: normal;
    }

    &-message {
      margin-top: 16px;
      color: #646c83;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }

    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 32px;
      padding: 10px 86px;
      border-radius: 8px;
      background-color: #4d4558;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
      transition: all 0.1s linear;

      &:hover {
        background-color: darken($color: #4d4558, $amount: 4);
      }
    }
  }
}
