.relationship-details-header {
    display: flex;
    align-items: center;
    gap: 32px;
    background-color: #FFFFFF;
    border: 1px solid #E3E7ED;
    border-radius: 16px;
    padding: 24px;

    &__accounts-wrapper {
        display: flex;
        flex-grow: 1;
        max-width: calc(50% - 16px);
        gap: 4px;
    }

    &__account {
        display: flex;
        flex-grow: 1;
        max-width: calc(50% - 2px);
        padding: 8px 24px;
        gap: 8px;
        border-radius: 8px;
        background-color: #F8FAFC;
    }

    &__account-logo-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 8px;
        width: 44px;
        min-width: 44px;
        height: 44px;
        border-radius: 8px;
        overflow: hidden;
        background-color: $gray-100;
        position: relative;
    }

    &__account-side {
        position: absolute;
        top: 3px;
        right: 3px;
        width: 10px;
        height: 10px;
        border: 1px solid #FFFFFF;
        border-radius: 50%;
        z-index: 1;

        &--userSide {
            background-color: $color-user;
        }

        &--prospectSide {
            background-color: $color-prospect;
        }
    }

    &__account-logo {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__account-logo-placeholder {
        width: 24px;
        height: 24px;
    }

    &__account-info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-width: calc(100% - 68px);
    }

    &__account-info-item {
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-weight: 500;
    }

    &__account-name {
        font-size: 14px;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__account-text {
        font-size: 12px;
        line-height: 16px;

        &--gray {
            color: $gray-300;
        }

        &--counter {
            margin-left: 12px;
        }
    }

    &__avatar-skeleton {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #E3E7ED;
        background-color: #F8FAFC;
    }

    &__account-participants {
        display: flex;
        align-items: center;

        & > .sound-wave-avatar,
        & > .relationship-details-header__avatar-skeleton {
            margin-right: -8px;
        }

        &--small {
            & > .sound-wave-avatar,
            & > .relationship-details-header__avatar-skeleton {
                margin-right: -4px;
            }

            & > span {
                margin-left: 8px;
            }
        }
    }

    &__cross-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        width: 36px;
        height: 36px;
        background-color: #E3E7ED;
        border: 4px solid #FFFFFF;
        border-radius: 8px;
        margin: auto -20px;
    }

    &__cross-icon { 
        & > path {
            fill: #FFFFFF;
        }
    }

    &__info-wrapper {
        display: flex;
        align-items: center;
        flex-grow: 1;
        max-width: calc(50% - 16px);
        gap: 32px;
    }

    &__info-item {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        gap: 4px;
        position: relative;
        padding: 8px 0;
        font-size: 14px;
        line-height: 24px;

        & > * {
            flex-grow: 1;
        }

        & > *:first-child {
            display: flex;
            align-items: flex-end;
        }

        &:not(:last-child)::after {
            content: '';
            position: absolute;
            top: 50%;
            right: -16px;
            transform: translateY(-50%);
            width: 1px;
            height: 52px;
            background-color: #E3E7ED;
        }

        &--loading {
            gap: 8px;
        }
    }

    &__info-item-title {
        color: $gray-400;
        display: inline-flex;
        align-items: center;
        gap: 4px;
    }

    &__unassigned-contacts {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__crm-connection-icon {
        width: 16px;
        height: 16px;

        &--active > path {
            fill: #10B981;
        }
    }
}
