@import './components/edit-user-info';
@import './components/myProfile/my-profile';
@import './components/myOrgAndTeams/my-org-and-teams';
@import './components/myActivity/my-activity';
@import './components/IntegrationCard/integration-card';

.account {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr auto;
  grid-column-gap: 16px;
  grid-row-gap: 24px;
  color: $black-new-design;

  &__profile {
    position: relative;
    display: flex;
    flex-direction: row;
    min-width: 100%;

    &-avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1.5rem 0rem;

      &__image {
        width: 50px;
        height: 50px;
      }

      &__role {
        margin-top: 1.6rem;
        text-transform: uppercase;
        background-color: $tertiary-background;
        padding: 0.9rem 1.1rem;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 1.5rem;
        color: $text-high-emp;
        font-weight: bold;
      }
    }

    &-content {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 100%;

      &-firstcolumn {
        display: block;
        padding: 0px 20px;
      }
      &-secondcolumn {
        display: block;
        padding: 0px 20px;
      }

      &-thirdColumn {
        margin-left: 0rem;
        background-color: #fff;
        min-width: 407px;
        border-radius: 20px;
        padding: 20px 45px;

        &__title {
          font-weight: 500;
          font-size: 2.4rem;
        }

        &__email {
          color: #777679;
          margin-bottom: 1rem;
          display: flex;
          justify-content: space-between;

          &-bold {
            font-weight: 500;
            color: #19181b;
          }
        }

        &__info {
          margin: 1.2rem 0;
          font-weight: 500;
          display: flex;
          justify-content: space-between;
          color: $text-high-emp;

          &-start {
            font-weight: 400;
            color: $text-med-emp;
          }

          &:last-child {
            margin-top: 1.5rem;
            padding-top: 5rem;
            border-top: 1px solid #f0f1f9;
            margin-bottom: 0;
          }

          &-logo {
            width: 1.8rem;
            height: 1.8rem;
            border-radius: 50%;
          }
        }

        &__org {
          margin: 1.2rem 0;
          font-weight: 500;
          display: flex;
          justify-content: space-between;

          &-start {
            font-weight: 400;
            color: #777679;
          }

          &:last-child {
            display: block;
            margin-top: 1.5rem;
            padding-top: 4rem;
            border-top: 1px solid #f0f1f9;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid #E3E7ED;
    background-color: #FFFFFF;
    animation: fadeIn 0.8s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;

    &:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }

    &:nth-child(2) {
      grid-area: 1 / 2 / 2 / 3;
    }

    &:nth-child(3) {
      grid-area: 1 / 3 / 2 / 4;
    }

    &:nth-child(4) {
      grid-area: 2 / 1 / 3 / 4;
    }

    &--p16 {
      padding: 16px;
    }

    &--no-padding {
      padding: 0;
    }
  }

  &__section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;

    &--with-padding {
      padding: 4px 0;
    }
  }

  &__integrations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row;
    gap: 16px;
  }

  &__google-button {
    font-family: 'Roboto', sans-serif;
    padding: 9px 12px;
    gap: 10px;
    line-height: 20px;
    border-radius: 4px;
    width: fit-content;
    margin: auto auto 0;

    & svg {
      width: 21px;
      height: 20px;
    }
  }
}
