@import './components/FilterSelect/filter-select';
@import './components/DatePicker/date-picker';
@import './components/FilterRange/filter-range';
@import './components/MeetingRecordsFilter/meeting-records-filter';

.meeting-records-filters {
  display: flex;
  align-items: center;
  position: relative;

  &.disabled {
    @include userSelectDisable;
    opacity: 0.4;
    pointer-events: none;
  }

  &__popup {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 305px;
    z-index: 1000;
    right: 0;
    top: calc(100% + 8px);
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(57, 69, 119, 0.25);
    transition: opacity 150ms ease-in-out, visibility 0ms 150ms;

    &--opened {
      opacity: 1;
      visibility: visible;
      transition: opacity 150ms ease-in-out;
    }
  }

  &__popup-header {
    padding: 16px;
    border-bottom: 1px solid #e3e7ed;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  &__popup-content {
    display: flex;
    flex-direction: column;
  }

  &__filters-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 16px;

    &:not(:last-child) {
      border-bottom: 1px solid #e3e7ed;
    }
  }

  &__popup-footer {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    border-top: 1px solid #e3e7ed;
  }

  &__applied-filters-counter {
    position: relative;
    margin-left: 12px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -8px;
      width: 1px;
      height: 16px;
      background-color: #e3e7ed;
    }
  }
}
