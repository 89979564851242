.table-header-account {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;

  &-title {
    color: #646c83;
    font-weight: 600; // TODO: change if needed
    font-size: 14px;
  }

  &__sort-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-left: 10px;
  }
}
