.next-move {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid #a7eca5;
  height: 114px;
  border-radius: 8px;
  padding: 7px;
  color: #4d4558;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    padding-left: 5px;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;

    &-img {
      margin-left: 8px;
      padding-top: 1px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;

    &-message {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 6px;
      font-family: 'Open Sans';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;

      &::before {
        content: '';
        min-width: 8px;
        min-height: 8px;
        width: 8px;
        height: 8px;
        background: #ccd5f6;
        box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        margin-left: 7px;
        margin-right: 12px;
      }
    }

    &__last-email {
      margin-top: 13px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      color: #777777;

      &-link {
        font-family: 'Open Sans';
        font-size: 11px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: -0.01em;
        border-bottom: 0.5px solid rgba(119, 119, 119, 0.5);

        &:hover {
          color: #29335c;
          border-bottom: 0.5px solid #29335c;
        }
      }
    }
  }
  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 9px;
    padding: 0px 15px;
    height: 30px;
    color: #777777;
    border-radius: 4px;
    font-family: 'Open Sans';
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
  }
}
