@import './components/AddNewAccountButton/add-new-account-button';

.add-bot {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-height: 184px;

  &__modal {
    // width: 392px;
    // padding: 30px 0 10px 0;
    // border-radius: 16px;
    border-radius: 0px;

    & > div > div.sound-wave-modal__dialog-content {
      padding: 0;
    }
  }

  &-close-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-27px, 27px) scale(1);
    cursor: pointer;
    opacity: 0.6;

    &:hover {
      transition: all 0.1s linear;
      filter: brightness(0.5);
    }
  }
  &-invisible-control {
    position: absolute;
    transform: translate(-10px, 3px) scale(1);
    width: 25px;
    height: 25px;
    opacity: 0.5;
  }

  &__title {
    width: 100%;
    border-bottom: 1px solid #e3e7ed;
    padding: 0 30px;

    &-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 37px;
      margin: 16px 0px 16px 0px;
      color: #4d4558;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }
  }

  &-button-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: spinAnimation 1s linear infinite;

    @keyframes spinAnimation {
      0% {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      100% {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 32px;
    gap: 16px;

    &-input {
      & > input.error.input__control {
        outline: 1px solid #f44336;
      }

      & > div.input__error {
        color: #f44336;
      }

      &.error {
        & > div.input__label {
          color: #f44336;
        }
      }

      & > div.input__label {
        color: #070b12;

        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }

      & > span.mainBlock__label {
        color: #070b12;

        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }

      & > input {
        padding: 8px !important;
        border-radius: 4px;
        background-color: transparent;
        outline: 1px solid #e3e7ed;
        background-color: #ffffff;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        font-family: inherit;

        &::placeholder {
          color: #95A1B6;
        }

        &:not(.error).no-icon.input__control {
          &:focus {
            outline: 1px solid #537bff;
          }
        }
      }

      & > .input__error {
        position: static;
        transform: none;
        margin-top: 8px;
        line-height: 16px;
      }
    }

    &-account-input {
      margin-top: 12px;

      & > div > input {
        padding: 9px;
        padding-left: 20px;
      }

      & > div > svg {
        top: 10px !important;
        right: 18px !important;
      }
    }

    &__add-account {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid #e3e7ed;
      background: #f8fafc;

      &__inputs {
        display: flex;
        flex-direction: column;
      }

      &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-button {
          width: 100%;
          padding: 10px 15px;
          border-radius: 8px;
          font-size: 14px;
          font-weight: 600; // TODO: change if needed
          line-height: 20px;
          letter-spacing: 0px;
          text-align: center;

          &.cancel {
            border: 1px solid #e3e7ed;
          }
        }
      }

      &-selected-account {
        color: #070b12;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        margin-left: 17px;

        & > span {
          color: #a7acb0;
          font-weight: 400;
        }
      }
    }
  }

  &__success-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 184px;
    width: 100%;
    animation: $fadein;

    &-checkmark {
      cursor: default;
    }

    &-title {
      width: 70%;
      margin-top: 12px;
      color: #070b12;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }

    &-subtitle {
      margin-top: 16px;
      color: #646f87;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &-close-button {
      // position: absolute;
      // bottom: 25px;
      // left: 50%;
      // transform: translate(-50%, 0);
      margin-top: 12px;
      transition: color 0.1s ease-in-out;
      text-decoration: underline;
      color: #646f87;
      cursor: pointer;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;

      &:hover {
        color: darken(#646f87, 20%);
      }
    }
  }

  &__textarea {
    width: 100%;
    position: relative;
    margin-top: 25px;

    &-control {
      padding: 10px 16px;
      border-radius: 8px;
      width: 100%;
      min-height: 120px;
      max-height: 500px;
      color: #4d4558;
      border: 1px solid #cdd5e5;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;

      &:focus {
        border: 1px solid #b3bcce;
      }

      &::placeholder {
        color: #94a3b8;
        padding-top: 2px;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 48px;
    margin-top: 10px;
    border-top: 1px solid #e3e7ed;
    padding: 18px 30px 18px 30px;

    &-button {
      position: relative;
      width: max-content;
      min-height: 42.22px;
      min-width: 75.43px;
      padding: 10px 15px;
      border-radius: 8px;
      font-size: 15px;
      font-weight: 600; // TODO: change if needed
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;

      &.add-acc {
        font-size: 12px;
      }

      &.btn-common:disabled {
        background-color: #f1f4f8;
        color: #95a1b6;
      }

      &.cancel {
        border: 1px solid #e3e7ed;
      }
    }
  }

  &__add-account-button {
    position: relative;

    &--loading {
      span {
        visibility: hidden;
      }
    }
  }
}

.invite-assistant-account {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  & > label > div {
    color: #070b12;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  &-input {
    & > input {
      padding: 8px !important;
      border-radius: 4px;
      background-color: transparent;
      outline: 1px solid #e3e7ed;
      background-color: #ffffff;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      font-family: inherit;

      &::placeholder {
        color: #95A1B6;
      }

      &:not(.error).no-icon.input__control {
        &:focus {
          outline: 1px solid #537bff;
        }
      }
    }
  }

  &-clear-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 9px;
    cursor: pointer;
    z-index: 9;
    transition: opacity 0.2s;
    animation: $fadein;

    &.disabled {
      opacity: 0.4;
      cursor: default;
      pointer-events: none;
    }
  }

  &-loader {
    position: absolute;
    top: 25px;
    right: 2px;
    width: 16px;
    height: 16px;
    z-index: 9;
    animation: spinAnimation 1s linear infinite;

    @keyframes spinAnimation {
      0% {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      100% {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }
  }

  &__popup {
    position: absolute;
    top: 70px;
    display: flex;
    width: 100%;
    max-height: 272px;
    padding: 8px 6px 8px 0px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 4px 24px 0px rgba(110, 134, 169, 0.22);
    transition: opacity 0.2s ease-in-out, visibility 0s ease-in-out 0s;
    z-index: 100;

    &.closed {
      transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out,
        visibility 0s ease-in-out 0.2s;
      transform: scale(0);
      opacity: 0;
      visibility: hidden;
    }

    @keyframes fadeInPopup {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      max-height: 272px;
      overflow-x: hidden;
      overflow-y: auto;
      gap: 4px;

      &__overflow {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        max-height: 272px;
        overflow-x: hidden;
        overflow-y: auto;
        gap: 6px;
      }

      &__row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;
        width: 100%;
        height: 42px;
        background: #ffffff;
        transition: all 0.15s ease-in-out;
        border-radius: 4px;
        padding: 8px 16px;

        &:hover {
          background: #f1f4f8;
          cursor: pointer;
        }

        &.create-acc {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 1px;
            width: calc(100% - 32px);
            background-color: #e3e7ed;
          }

          .invite-assistant-account__popup__body__row__avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;

            svg {
              width: 18px;
              height: 18px;
              min-width: 18px;
            }
          }
        }

        &__avatar {
          position: relative;
          border-radius: 50%;
          border: 1px solid #ffffff;
          background: #f1f4f8;

          & > div > svg {
            width: 16px;
            min-width: 16px;
            height: 16px;
          }

          &-img {
            min-width: 24px;
            min-height: 24px;
            max-width: 24px;
            border-radius: 8px;
            border: 1px solid #fff;
            background: lightgray 50% / cover no-repeat,
              lightgray 50% / cover no-repeat;
            box-shadow: 0px 4px 6px 0px rgba(85, 106, 142, 0.12);

            &.default-icon {
              padding: 6px;
            }
          }
        }

        &-company-name {
          color: #070b12;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;

          &--highlighted {
            color: #070b12;
            font-size: 12px;
            font-style: normal;
            line-height: 16px;
            font-weight: 500;
            background-color: rgb(83, 123, 255, 0.2);
          }
        }

        &__email {
          display: flex;
          flex-direction: row;
          width: max-content;

          &__text {
            display: flex;
            flex-direction: row;
            color: #646f87;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;

            &::before {
              content: '|';
              color: #646f87;
              width: max-content;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              margin-right: 5px;
            }

            &-letter {
              color: #646f87;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;

              &--highlighted {
                display: flex;
                flex-direction: row;
                color: #646f87;
                font-size: 12px;
                font-style: normal;
                line-height: 16px;
                font-weight: 500;
                background-color: rgb(83, 123, 255, 0.2);
              }
            }
          }
        }
      }

      &-title {
        color: #070b12;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }

      &-text {
        color: #646f87;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
      }

      &::-webkit-scrollbar {
        &-thumb {
          background: rgba(149, 161, 182, 0.12);
          border-radius: 4px;
          box-shadow: inset 0px 10px 0px 5px transparent;

          &:hover {
            background: rgba(149, 161, 182, 0.22);
          }
        }

        &-track-piece:end {
          margin-bottom: 8px;
        }

        &-track-piece:start {
          margin-top: 8px;
        }
      }

      &::-moz-scrollbar {
        &-thumb {
          background: rgba(149, 161, 182, 0.12);
          border-radius: 4px;
          box-shadow: inset 0px 10px 0px 5px transparent;

          &:hover {
            background: rgba(149, 161, 182, 0.22);
          }
        }

        &-track-piece:end {
          margin-bottom: 8px;
        }

        &-track-piece:start {
          margin-top: 12px;
        }
      }

      &::-ms-scrollbar {
        &-thumb {
          background: rgba(149, 161, 182, 0.12);
          border-radius: 4px;
          box-shadow: inset 0px 10px 0px 5px transparent;

          &:hover {
            background: rgba(149, 161, 182, 0.22);
          }
        }

        &-track-piece:end {
          margin-bottom: 8px;
        }

        &-track-piece:start {
          margin-top: 12px;
        }
      }
    }

    &__no-results-body {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;

      &-title {
        color: #070b12;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }

      &-text {
        color: #646f87;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}
