.partics-row {
  position: relative;
  width: 100%;
  max-width: 100%;

  &-names {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    width: max-content;
    max-width: calc(100% - 20px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }

  &__tooltip {
    display: none;

    &.open {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 30px;
      background-color: $second-background;
      min-width: 90px;
      padding: 4px 10px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      z-index: 100;
    }

    &__participant {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 32px;

      &-name {
        font-family: 'Open Sans';
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        text-align: center;
        color: #fff;
        margin-left: 10px;
      }

      &-avatar {
        font-size: 12px;
      }
    }
  }
}
