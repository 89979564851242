@import './components/TurnsOverviewTable/turns-overview-table';
@import './components/TurnsOverviewChart/turns-overview-chart';

.distribution-of-td {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: $fadein;

    &__title {
      display: flex;
      gap: 4px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.75;
    }

    &__legend {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 4px 0;
      font-size: 12px;
      line-height: 20px;

      &-item {
        display: flex;
        align-items: center;
        margin-left: 12px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          top: 50%;
          left: -12px;
          transform: translateY(-50%);
          background-color: $color-unassigned;
        }

        &--userSide {
          &::before {
            background-color: $color-user;
          }
        }

        &--prospectSide {
          &::before {
            background-color: $color-prospect;
          }
        }
      }
    }
  }

  &__content {
    width: 100%;
    min-height: 136px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    overflow: hidden;

    &__scrollbar {
      max-height: 560px;
    }
  }
}
