.add-note-modal {
    &__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__character-counter {
        font-size: 10px;
        line-height: 16px;
        font-weight: 500;
        color: $gray-300;

        &--error {
            color: #F0384A;
        }
    }

    & .sound-wave-modal-header {
        padding: 24px;
    }
}

.note-field {
    & textarea {
        height: 216px;
    }
}
