.active-status {
  text-transform: capitalize;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  text-align: center;
  width: 90px;
  font-family: 'Open Sans';
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;

  &__active {
    color: $success;
    background-color: $success-background;
  }

  &__inactive {
    color: $error;
    background-color: $error-background;
  }

  &__sent {
    color: $text-high-emp;
    background-color: $tertiary-background;
  }

  &__expired {
    color: $text-high-emp;
    background-color: $tertiary-background;
  }

  &.centered {
    margin: auto;
  }
}
