.loader {
  animation: loader 1.3s;
}

@keyframes loader {
  0% {
    opacity: 0.5;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
