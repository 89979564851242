.qactivity-chart {
  display: flex;
  justify-content: space-between;
  &__first-column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 8%;
    margin-bottom: 2%;
    margin-top: -1.8%;
  }
  &__second-column {
    display: flex;
    flex-direction: column;
    width: 92%;
    &__x-axis {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }
  }
}

.sentiment-chart {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: -4%;
  &__rows {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 19%;
    width: 100%;
    &-last {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 20px;
      margin-left: 45%;
    }
  }
}

.outgoing-chart {
  display: flex;
  justify-content: space-between;
  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

.selling-zone-chart {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  align-self: center;
  height: 100%;
  width: 90%;
  margin: 4px 2% 4px 12%;
  &__column {
    display: flex;
    flex-direction: column;
  }
}

.top-domains-chart {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  align-self: center;
  height: 63%;
  width: 98%;
  margin-top: 4px;
  &__column {
    display: flex;
    flex-direction: column;
  }
}

.talk-time-ratio-chart-s {
  display: flex;
  justify-content: space-between;
  margin-top: 26px;

  &__first-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 0%;
    margin-top: -3%;
  }

  &__second-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &__x-axis {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-top: 13px;
    }
  }
}

.meeting-count-chart-s {
  display: flex;
  justify-content: space-between;
  margin-top: 26px;

  &__first-column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 8%;
    margin-bottom: 2%;
    margin-top: -1.8%;
  }

  &__second-column {
    display: flex;
    flex-direction: column;
    width: 92%;

    &__x-axis {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }
  }
}

@media screen and (min-width: $desktop1440) {
  .outgoing-chart {
    margin-top: 16px;
  }
}
