@import './components/SignalsListItem/signals-list-item';

.meeting-signals-list {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        padding: 8px 16px;
        background-color: #F8FAFC;
        box-shadow: 0px 4px 8px 0px rgba(71, 107, 132, 0.15);
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        border-bottom: 1px solid #E3E7ED;
    }

    &__title {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    &__scrollbar {
        max-height: 232px;
    }
}