@import './membersModal/team-members-modal';

.team-profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 280px;
  height: max-content;
  max-height: 168px;
  background-color: $white;
  animation: fadeIn 0.4s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e2ee;

  &-header {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 7px 0;
  }

  &__body {
    display: flex;
    flex-direction: column;

    &__avatar-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 37px;
      transition: all 0.25s;

      &-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: $grey-hover;
        cursor: default;
      }

      &__icons {
        position: relative;
        cursor: pointer;

        &-first {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 50%;
          left: -65px;
          transform: translate(0, -50%);
          z-index: 1;
        }

        &-second {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 50%;
          left: -50px;
          transform: translate(0, -50%);
          z-index: 2;
        }

        &-third {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 50%;
          left: -35px;
          transform: translate(0, -50%);
          z-index: 3;
        }

        &-fourth {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 50%;
          left: -20px;
          transform: translate(0, -50%);
          z-index: 5;
        }

        &-number {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          position: absolute;
          min-width: 20px;
          min-height: 20px;
          width: 20px;
          height: 20px;
          top: 50%;
          left: -20px;
          transform: translate(0, -50%);
          z-index: 4;
          background-color: $text-high-emp;
          color: #fff;
          border-radius: 50%;
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: center;
          box-shadow: 0px 0px 0.5px 0.5px $white;
          filter: drop-shadow(0px 0px 0.5px rgb(244, 244, 244));
        }
      }
    }
  }

  &__footer-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 37px;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    color: $secondary-color;
    cursor: pointer;

    &:hover {
      color: $secondary-color-hover;
    }
  }
}
