@import './components/AuthParamsRow/auth-params-row';
@import './components/Spinner/spinner';

.wk-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background: #f8f9fb;
  box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.04);
  animation: fadeIn 0.5s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;
  padding: 24px 24px;

  @media screen and (max-width: 1660px) {
    flex-direction: column;
  }

  &.closed {
    display: none;
  }

  &__events {
    display: flex;
    flex-direction: column;
    width: max-content;
    height: 279px;
    padding: 24px;
    background: #9fc6e30f;
    border-radius: 12px;

    &-title {
      font-size: 14px;
      font-weight: 600; // TODO: change if needed
      line-height: 20px;
      color: $text-high-emp;

      &::after {
        content: '*';
        display: inline-block;
        color: $error;
      }
    }

    &-subtitle {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #8189a1;
    }

    &__row {
      display: flex;
      flex-direction: row;
      width: 100%;
      min-width: max-content;
      align-items: center;
      margin: 12px 0 0;

      &-checkbox {
        margin-right: 10px;
      }

      &-text {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: $text-high-emp;
      }

      &__tooltip {
        position: relative;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        border-radius: 50%;
        transform: scale(1);
        cursor: pointer;

        &-icon {
          min-width: 12px;
          max-width: 12px;
          min-height: 12px;
          max-height: 12px;
          transform: scale(1.1);
          border-radius: 50%;
        }

        &-text {
          position: absolute;
          display: none;
          width: 170px;
          height: 65px;
          padding: 8px;
          border-radius: 4px;
          background-color: $second-background;
          color: $white;
          right: 0;
          transform: translate(0px, -47px);
          font-size: 10px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: left;
          animation: tooltip-enter 0.15s ease-in-out;
        }

        &:hover {
          background-color: #4d4558;

          .wk-form__events__row__tooltip-text {
            display: block;
          }

          .wk-form__events__row__tooltip-icon {
            filter: invert(101%) sepia(293%) saturate(25%) brightness(219%)
              contrast(519%);
            transform: scale(1.3);
            transition: all 0.05s linear;
          }
        }
      }
    }

    &-error {
      margin-top: 10px;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0px;
      text-align: left;
      color: $error;
    }
  }

  &__settings {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    margin-left: 24px;

    @media screen and (max-width: 1660px) {
      margin-left: 0;
    }

    form {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      font-weight: 600; // TODO: change if needed
      line-height: 20px;
      color: $text-high-emp;

      &-err-status {
        margin-left: 8px;
        background-color: #fac7c9;
        border-radius: 4px;
        padding: 1px 6px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
      }
    }

    &-subtitle {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #8189a1;
      margin-top: 5px;
    }

    &-url-input {
      margin: 20px 0px 5px;
      width: 100%;
    }

    &__encrypt {
      position: relative;
      display: flex;
      flex-direction: row;
      margin: 20px 0px 5px;

      &-checkbox {
        margin: 26.3px 15px 0px 0px;
        min-width: 18px;
        min-height: 18px;
      }

      &-input {
        width: 100%;
      }

      &__controls {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 84px;
        top: 25px;
        right: 9px;

        &-loader {
          display: flex;
          align-self: center;
          justify-self: center;
          min-width: 20px;
          max-width: 20px;
          min-height: 20px;
          max-height: 20px;
          border-radius: 50%;
          background: conic-gradient(
            from 169.11deg at 50% 50.12%,
            rgba(139, 76, 201, 0) -77.29deg,
            #8b4cc999 0.69deg,
            rgba(139, 76, 201, 0) 282.71deg,
            #8b4cc985 360.69deg
          );
          transform: matrix(-1, 0, 0, 1, 0, 0);
          animation: rotation 1s linear infinite;

          @keyframes rotation {
            0% {
              transform: rotate(0deg) scale(0.8) matrix(-1, 0, 0, 1, 0, 0);
            }

            100% {
              transform: rotate(360deg) scale(1) matrix(-1, 0, 0, 1, 0, 0);
            }
          }
        }

        &-spinner {
          display: flex;
          align-self: center;
          justify-self: center;
          width: 18px;
          height: 16px;
          margin-top: 2px;
        }

        img:not(.wh-spinner-png) {
          cursor: pointer;
          transition: all 0.2s linear;
          animation: fadeIn 0.5s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;
          @include userSelectDisable;

          &:hover {
            filter: brightness(0.3);
          }

          &:active {
            transition: all 0.05 linear;
            transform: scale(0.8);
          }
        }

        &__control {
          position: relative;
          display: flex;
          max-width: 20px;
          max-height: 20px;
          align-items: center;
          justify-content: center;

          &.disabled {
            pointer-events: none;
            @include userSelectDisable;
            cursor: default;
          }

          &:hover:not(.disabled) {
            .wk-form__settings__encrypt__controls__control-tooltip {
              display: block;
            }
          }

          &-icon {
            cursor: pointer;
            transition: all 0.2s linear;
            animation: fadeIn 0.5s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;

            &:hover {
              filter: brightness(0.3);
            }

            &:active {
              transition: all 0.05 linear;
              transform: scale(0.8);
            }

            &.disabled {
              pointer-events: none;

              &:hover {
                filter: none;
              }

              &:active {
                transition: none;
                transform: none;
              }
            }
          }

          &-tooltip {
            display: none;
            position: absolute;
            width: max-content;
            padding: 6px 8px;
            border-radius: 4px;
            background-color: $second-background;
            color: $white;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -47px);
            font-size: 10px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left;
            animation: tooltip-enter 0.15s ease-in-out;

            &.open {
              display: block;
              animation: tooltipChangeAnimation 0.2s linear;
            }

            @keyframes tooltipChangeAnimation {
              0% {
                transform: translate(-50%, -47px) scale(0.1);
                opacity: 0.4;
              }

              70% {
                transform: translate(-50%, -47px) scale(1.02);
              }

              100% {
                transform: translate(-50%, -47px) scale(1);
                opacity: 1;
              }
            }
          }
        }
      }
    }

    &__auth-param {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;

      &-checkbox {
        margin: 0px 15px 0px 0px;
        min-width: 18px;
        min-height: 18px;
      }

      &__label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        @include userSelectDisable;

        &-text {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          color: $text-high-emp;
        }

        &-chevron {
          width: 16px;
          height: 16px;
          margin-left: 7px;
          transform: rotateX(180deg);
          transition: all 0.1s linear;

          &.revert {
            transform: rotateX(0deg);
          }
        }
      }

      &__table-title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-top: 20px;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        color: $text-high-emp;

        &-type {
          min-width: 160px;
          max-width: 160px;
        }

        &-name {
          margin-left: 4px;
          min-width: 160px;
          max-width: 160px;
        }

        &-value {
          margin-left: 20px;
          min-width: 160px;
          width: 100%;
        }
      }
    }

    &__footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      margin-top: 12px;

      &-add-row-btn {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: $primary-color;
        cursor: pointer;
        transition: all 0.1s;
        animation: fadeIn 0.5s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;
        @include userSelectDisable;

        &:hover {
          color: darken($primary-color, 5);
        }

        &.disabled {
          color: #dbdbdb;
          pointer-events: none;
        }
      }

      &__save-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        width: 120px;
        height: 32px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: $white;
        background-color: $text-high-emp;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        transition: color 0.2s linear;
        @include userSelectDisable;
        animation: buttonSave 0.3s linear;

        &-spinner {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }

        @keyframes buttonSave {
          0% {
            opacity: 0.2;
            transform: scale(0.9);
          }

          100% {
            opacity: 1;
            transform: scale(1);
          }
        }

        &:hover {
          background-color: darken($text-high-emp, 3);
        }

        &:active {
          background-color: darken($text-high-emp, 6);
        }

        &:disabled {
          transition: all 0.2s linear;
          cursor: default;
          background-color: #dbdbdb;
          pointer-events: none;
          @include userSelectDisable;
        }

        &.loading {
          width: 120px;
          height: 32px;
          background-color: transparent;
          background: linear-gradient(
            103.85deg,
            #381289 23.68%,
            #a635c6 75.19%,
            #c591d3 100%
          );
          pointer-events: none;
          @include userSelectDisable;
          animation: buttonChange 0.3s linear;

          @keyframes buttonChange {
            0% {
              opacity: 0.3;
              transform: scale(0.95);
            }

            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}

// TODO: Remove when new design is ready
.crm-body {
  display: flex;
  flex-direction: column;

  &__action {
    width: 100%;
    height: 100%;
    animation: fadeIn 0.4s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;
  }

  &__status {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.4s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;

    &-img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      animation: scaleInIcon 0.4s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
    }

    &-bold-text {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: $text-high-emp;
      margin: 15px 0px 9px;
    }

    &-text {
      text-align: center;
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;
      color: $text-med-emp;
    }

    &__scrollable {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 30px;
      width: 100%;
      overflow: hidden;
      outline: 1px solid $secondary-shade25;
      background-color: $main-background;
      border-radius: 8px;
      transition: all 0.2s linear;

      &-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: 30px;
        padding: 0px 16px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: $text-high-emp;

        &::after {
          content: '>';
          margin: 0 3px 0 7px;
          display: flex;
          justify-self: flex-end;
          width: max-content;
          transform: rotate(90deg);
          font-size: 20px;
          transition: all 0.2s linear;
        }
      }

      &__title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: 30px;
        padding: 0px 16px;

        &-text {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          color: $text-high-emp;
        }

        &-chevron {
          margin: 0 3px 0 7px;
          display: flex;
          justify-self: flex-end;
          width: max-content;
          transform: rotate(90deg);
          font-size: 20px;
          transition: all 0.2s linear;
          cursor: pointer;
        }
      }

      &__body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: black;
        padding: 0px 0px 0px 16px;
        white-space: pre-line;
        transition: all 0.2s linear;

        &-row {
          margin-top: 6px;
        }
      }

      &.open {
        height: 188px;

        .crm-body__status__scrollable-title::after {
          transform: rotate(90deg) rotateY(180deg);
        }

        .crm-body__status__scrollable__title-chevron {
          transform: rotate(90deg) rotateY(180deg);
        }

        .crm-body__status__scrollable__body {
          height: 150px;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
    }

    @keyframes scaleInIcon {
      0% {
        transform: scale(0);
      }

      100% {
        transform: scale(1);
      }
    }
  }

  &-button {
    margin: auto;
    width: 100px;
    padding: 10px 15px;
    margin-top: 25px;
  }

  &__button-section {
    display: flex;
    flex-direction: row;

    &-submit {
      margin: auto;
      width: 100px;
      padding: 10px 15px;
      margin-top: 25px;
    }

    &__copy {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-end;
      min-width: 24px;
      max-width: 24px;
      min-height: 24px;
      max-height: 24px;
      margin-left: 11px;
      border-radius: 4px;
      background-color: #4d4558;
      transition: all 0.1s linear;
      cursor: pointer;

      &:hover {
        background-color: darken(#4d4558, 5);

        .crm-body__button-section__copy-tooltip {
          display: block;
        }
      }

      &:active {
        transition: all 0.05 linear;
        transform: scale(0.95);
      }

      &-icon {
        cursor: pointer;
        transition: all 0.2s linear;
        animation: fadeIn 0.5s cubic-bezier(0.27, 0.5, 0.75, 1) 0s;
      }

      &-tooltip {
        display: none;
        position: absolute;
        width: max-content;
        padding: 6px 8px;
        border-radius: 4px;
        background-color: $second-background;
        color: $white;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -47px);
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        animation: tooltip-enter 0.15s ease-in-out;

        &.copied-shown {
          display: block;
          animation: tooltipChangedAnimation 0.2s linear;
        }

        @keyframes tooltipChangedAnimation {
          0% {
            transform: translate(-50%, -47px) scale(0.1);
            opacity: 0.4;
          }

          70% {
            transform: translate(-50%, -47px) scale(1.02);
          }

          100% {
            transform: translate(-50%, -47px) scale(1);
            opacity: 1;
          }
        }
      }
    }
  }
}

.crm-modal {
  width: 404px;
  min-width: 404px;
  transition: all 0.1s linear;
}