.nav-blocker {
  &__modal {
    width: 408px;
    border-radius: 16px;

    &-body {
      display: flex;
      flex-direction: column;
      width: 100%;

      &-text {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        color: $text-high-emp;
      }

      &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        margin-top: 24px;

        &-confirm {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 120px;
          height: 24px;
          color: $text-high-emp;
          background-color: $white;
          border: 1px solid $text-high-emp;
          border-radius: 4px;
          transition: all 0.2s linear;
          cursor: pointer;
          margin: 0 6px;

          &:hover {
            background-color: #f5f5f5;
          }
        }

        &-cancel {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 120px;
          height: 24px;
          color: white;
          background-color: $text-high-emp;
          border-radius: 4px;
          transition: all 0.2s linear;
          cursor: pointer;
          margin: 0 6px;

          &:hover {
            background-color: darken($text-high-emp, 5);
            color: #f5f5f5;
          }
        }
      }
    }
  }
}
