.team-member {
    display: flex;
    align-items: center;
    padding: 4px;
    gap: 8px;
    border-radius: 4px;
    background-color: #FFFFFF;
    transition: background-color 150ms ease-in-out;

    &__left-section {
        display: flex;
        align-items: center;
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;

        & > span {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__right-section {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    &__checkbox {
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        min-width: 16px;
        height: 16px;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;

        & > svg {
            width: 16px;
            height: 16px;
        }
    }

    &__avatar {
        transition: opacity 150ms ease-in-out;
    }

    &__text {
        font-size: 14px;
        line-height: 24px;
        color: $black-new-design;
        transition: color 150ms ease-in-out;

        &--gray-300 {
            color: $gray-300;
        }

        &--gray-200 {
            color: $gray-200;
        }

        &--bold {
            font-weight: 500;
        }
    }

    &__separator {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        min-width: 16px;

        &::after {
            content: '';
            display: block;
            border-radius: 50%;
            background-color: $gray-300;
            width: 4px;
            height: 4px;
            border-radius: 50%;
        }
    }

    &__events-count {
        display: flex;
        align-items: center;
        gap: 8px;

        & > svg {
            width: 16px;
            height: 16px;
        }
    }

    &__dropdown-button {
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        min-width: 24px;
        height: 24px;
        padding: 4px;
        border: none;
        background-color: transparent;
        border-radius: 50%;
        transition: background-color 150ms ease-in-out;
        cursor: pointer;

        & > svg {
            width: 16px;
            height: 16px;
        }

        &:hover:not(.team-member__dropdown-button--active) {
            background-color: #F8FAFC;
        }

        &:active:not(.team-member__dropdown-button--active) {
            background-color: $gray-100;
        }

        &--active {
            background-color: $gray-100;
        }
    }

    &__changes-button {
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        background-color: transparent;
        border: none;
        padding: 0;
        font-size: 12px;
        line-height: 20px;
        color: #537BFF;
        white-space: nowrap;
        cursor: pointer;

        & > svg {
            width: 12px;
            height: 12px;
        }
    }

    &__deactivate-icon {
        & > path {
            fill: #F0384A;
        }
    }

    &--inactive {
        background-color: #F8FAFC;

        .team-member__avatar {
            opacity: 0.5;
        }

        .team-member__text {
            color: $gray-200;

            &--gray-300 {
                color: $gray-200;
            }
        }
    }

    &--changes-view {
        background-color: #FFFFFF;

        .team-member__avatar {
            opacity: 1;
        }
    }
}