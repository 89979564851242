.graph-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: $text-med-emp;
  background-color: $white;
  padding: 40px 10px 10px 40px;
  animation: $fadein;

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    cursor: default;

    &-label {
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      color: $text-high-emp;
    }

    &__tooltip {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 14px;
      height: 14px;
      margin-left: 1px;

      &-icon {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__subtitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 22px;
    width: 79.5%;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;

    &__sz-legend {
      width: max-content;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      color: $text-high-emp;

      &-below {
        display: flex;
        align-items: center;

        &::before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 2px;
          margin-right: 4px;
          background-color: #646c83;
        }
      }

      &-in {
        display: flex;
        align-items: center;
        margin: 0px 16px;

        &::before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 2px;
          margin-right: 4px;
          background-color: #57c888;
        }
      }
      &-above {
        display: flex;
        align-items: center;

        &::before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 2px;
          margin-right: 4px;
          background-color: #acacb7;
        }
      }
    }
  }

  &-based-on {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    height: 16px;
  }

  &__body {
    width: 100%;
    height: calc(100% - 80px);
  }

  &__tooltip {
    margin-left: 3px;
  }

  .sound-wave-tooltip--opened {
    svg {
      fill: #223754;

      & path:last-child {
        fill: #FFFFFF;
      }
    }
  }
}
