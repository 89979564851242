.chart-axis {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    position: relative;
    z-index: 1;
    padding: 8px 0;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.6;
    width: 80px;
    min-width: 80px;
    box-shadow: 0px 4px 8px 0px rgba(71, 107, 132, 0.15);

    & > * {
        width: 100%;
    }

    &--with-expand-button {
        padding-bottom: 4px;

        & .chart-axis__participants {
            padding-bottom: 0;
        }
    }

    &__time-label {
        padding-bottom: 8px;
        border-bottom: 1px solid #E3E7ED;
    }

    &__topics-label {
        padding: 8px 0 24px 0;
    }

    &__turns-label {
        padding: 8px 0;
    }

    &__participants {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 20px 8px 10px 8px;
        align-items: center;
    }

    &__expand-button {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        appearance: none;
        padding: 4px 0;
        border: none;
        width: 100%;
        color: #537BFF;
        background-color: transparent;
        transition: color 150ms ease-in-out;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        cursor: pointer;

        & svg {
            width: 12px;
            height: 12px;

            & path {
                transition: fill 150ms ease-in-out;
                fill: #537BFF;
            }
        }

        &:hover {
            color: #2244B3;

            & svg path{
                fill: #2244B3;
            }
        }
    }

    &__participant-tooltip {
        width: 100%;
    }

    &__participant {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 4px;
    }

    &__participant-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__speech-rate {
        display: flex;
        justify-content: flex-end;
        position: relative;
        padding: 20px 16px 36px 16px;
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
    }

    &__speech-rate-label {
        display: inline-flex;
        position: absolute;
        top: 102px;
        left: -36px;
        transform: rotate(-90deg);
        align-items: center;
        gap: 4px;
        color: $gray-200;
        width: max-content;
    }

    &__speech-rate-tooltip {
        .chart-axis__speech-rate-tooltip-icon {
            &:first-child {
                display: block;
            }

            &:last-child {
                display: none;
            }
        }

        &.sound-wave-tooltip--opened {
            .chart-axis__speech-rate-tooltip-icon {
                &:first-child {
                    display: none;
                }
    
                &:last-child {
                    display: block;
                }
            }
        }
    }

    &__speech-rate-tooltip-icon {
        width: 16px;
        height: 16px;
        transform: rotate(-90deg);
    }

    &__speech-rate-tooltip-content {
        width: auto;

        & .sound-wave-tooltip__content {
            line-height: 16px;
            font-weight: 500;
        }

        & .sound-wave-tooltip__arrow-icon {
            display: none;
        }
    }

    &__speech-rate-items {
        display: flex;
        flex-direction: column;
        gap: 24px;
        text-align: right;
    }
}
